<template>
    <hr class="my-4" />

    <div class="w-full text-center mt-4">ИТОГОВЫЙ РЕЗУЛЬТАТ VK 2023</div>

    <div v-if="today?.data" class="grid grid-cols-4 gap-4 mt-4">
        <div class="col-span-4 bg-white flex justify-center items-center flex-col rounded p-4">
            <span class="title">ГОДОВОЙ БЮДЖЕТ</span>

            <span class="num text-4xl md:text-6xl">{{ parseInt(today.data.spent + cabs[2000000000].spent).toLocaleString() }} <sup>₽</sup></span>

            <div class="w-full grid grid-cols-3 gap-8 text-xs my-4">
                <div class="flex justify-center items-center gap-2">
                    <span class="text-slate-400">CPM: </span> {{ parseInt(today.data.spent / (today.data.impressions / 1000)).toLocaleString() }} ₽
                </div>
                <div class="flex justify-center items-center gap-2">
                    <span class="text-slate-400">CPC: </span> {{ parseInt(today.data.spent / today.data.clicks).toLocaleString() }} ₽
                </div>
                <div class="flex justify-center items-center gap-2">
                    <span class="text-slate-400">CTR: </span> {{ ((today.data.clicks * 100) / today.data.impressions).toFixed(2) }}%
                </div>
            </div>

            <div v-if="today.graf" class="w-full"><LineGraf :graf="today.graf" h="h-28" /></div>
        </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
        <div v-for="cabinet in cabs" :key="cabinet" class="w-full gap-4 bg-white flex justify-center items-center flex-col rounded p-4">
            <div class="text-lg">{{ cabinet.label }}</div>
            <div class="num text-4xl md:text-2xl">{{ parseInt(cabinet.spent).toLocaleString() }} <sup>₽</sup></div>

            <div v-if="cabinet.label != 'МП'" class="w-full grid grid-cols-2 gap-8 text-xs">
                <div class="flex justify-center items-center gap-2"><IconEye :size="3" /> {{ parseInt(cabinet.impressions).toLocaleString() }}</div>

                <div class="flex justify-center items-center gap-2"><IconClick :size="3" /> {{ parseInt(cabinet.clicks).toLocaleString() }}</div>
            </div>

            <div v-if="cabinet.label != 'МП'" class="w-full grid grid-cols-3 gap-8 text-xs">
                <div class="flex justify-center items-center gap-2">
                    <span class="text-slate-400">CPM: </span> {{ parseInt(cabinet.spent / (cabinet.impressions / 1000)).toLocaleString() }} ₽
                </div>
                <div class="flex justify-center items-center gap-2">
                    <span class="text-slate-400">CPC: </span> {{ parseInt(cabinet.spent / cabinet.clicks).toLocaleString() }} ₽
                </div>
                <div class="flex justify-center items-center gap-2">
                    <span class="text-slate-400">CTR: </span> {{ ((cabinet.clicks * 100) / cabinet.impressions).toFixed(2) }}%
                </div>
            </div>
        </div>
    </div>

    <div class="bg-white flex justify-center items-center flex-col rounded p-8 mt-4">
        <div class="flex justify-between items-center gap-4 w-full">
            <div class="flex justify-center gap-4">
                <div class="flex justify-end items-center gap-4">
                    <div @click="radiobtn = 'impressions'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'impressions' }">
                        ПРОСМОТРЫ
                    </div>
                    <div @click="radiobtn = 'clicks'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'clicks' }">КЛИКИ</div>
                    <div @click="radiobtn = 'spent'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'spent' }">ОТКРУТ</div>
                    <div @click="radiobtn = 'cpm'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'cpm' }">CPM</div>
                    <div @click="radiobtn = 'cpc'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'cpc' }">CPC</div>
                </div>
            </div>
        </div>

        <div v-if="cpmcpc" class="w-full col-span-12"><MultiLineGraf :graf="cpmcpc" /></div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div class="bg-white flex justify-center items-center flex-col rounded p-8">
            <span class="title">ТОП 10 ПРОЕКТОВ VK</span>
            <div v-for="item in projects2023" :key="item" class="w-full grid grid-cols-3 gap-4 mt-4 border-b border-slate-100">
                <div class="col-span-2">{{ item.name }}</div>
                <div class="text-right tabular-nums">{{ parseInt(item.spent).toLocaleString() }}</div>
            </div>
        </div>

        <div class="bg-white flex justify-center items-center flex-col rounded p-8">
            <span class="title">ТОП 10 КЛИЕНТОВ МП</span>

            <div v-for="item in mp.slice(0, 10)" :key="item" class="w-full grid grid-cols-3 gap-4 mt-4 border-b border-slate-100">
                <div class="col-span-2">{{ item.name }}</div>
                <div class="text-right tabular-nums">{{ parseInt(item.spent).toLocaleString() }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import MultiLineGraf from "../components/Graf/MultiLineGraf.vue";

export default {
    name: "MainStat2023",

    components: { MultiLineGraf },

    data() {
        return {
            moment: moment,

            cabinets: [
                { id: 15210968, label: "ВКР", color: "rgb(244, 67, 54)" },
                { id: 1900014679, label: "ВИТ", color: "rgb(233, 30, 99)" },
                { id: 1900000062, label: "СТ2", color: "rgb(156, 39, 176)" },
                { id: 1900014266, label: "ТМ", color: "rgb(63, 81, 181)" },
                { id: 1900015316, label: "СТ4", color: "rgb(3, 169, 244)" },
                { id: 1900014874, label: "СТ3", color: "rgb(0, 150, 136)" },
            ],

            mp: [
                { name: "Кофейни - 3", spent: 4469893 },
                { name: "Геометриум - 1", spent: 4395477 },
                { name: "Школа Отношений - 3", spent: 2728115 },
                { name: "Цветков Руководительница - 1", spent: 2343139 },
                { name: "Кофейни - 4", spent: 2049284 },
                { name: "Хохлов Отношения - 2", spent: 1935078 },
                { name: "Школа отношений - 2", spent: 1821262 },
                { name: "Христосенко Соцсети - 1", spent: 1556533 },
                { name: "Темченко ЛФП - 1", spent: 1458063 },
                { name: "3ДКлуб Моделинг - 1", spent: 1393260 },
                { name: "Каллиграфия - 1", spent: 1024412 },
                { name: "Харр 3Д - 1", spent: 969959 },
                { name: "Васькин Цветы - 1", spent: 901057 },
                { name: "Ахмадуллин Детское развитие - 1", spent: 900999 },
                { name: "Кофейни ТВ - 2", spent: 822124 },
                { name: "3Дклуб Основной - 1", spent: 671625 },
                { name: "Жукова Волосы - 1", spent: 589692 },
                { name: "3Дклуб Основной - 2", spent: 496130 },
                { name: "Тимофеева Умничка - 1", spent: 459335 },
                { name: "Качагин Коуч - 1", spent: 459308 },
                { name: "Пряничная Академия - 1", spent: 441646 },
                { name: "Академия Макерс - 2", spent: 424379 },
                { name: "Пузат Доход - 2", spent: 365999 },
                { name: "Темченко ММ - 1", spent: 307983 },
                { name: "Васькин Цветы - 2", spent: 261546 },
                { name: "Гальваника - 1", spent: 224768 },
                { name: "Самойлов Психология - 1", spent: 220972 },
                { name: "Касумов Строительство - 1", spent: 217567 },
                { name: "Цветков Коммуникации - 1", spent: 214006 },
                { name: "Клевцова Детская Нутрициология - 2", spent: 209997 },
                { name: "Дьяков Продвижение - 1", spent: 199786 },
                { name: "Гандапас - 1", spent: 189442 },
                { name: "Медведев Фото - 1", spent: 186907 },
                { name: "Кофейни Лайфхакер - 1", spent: 139575 },
                { name: "АИ Нейросети - 1", spent: 132191 },
                { name: "Ментальная арифметика - 1", spent: 130587 },
                { name: "Кофейни Хохоро - 1", spent: 118873 },
                { name: "Пузат Доход - 3", spent: 109949 },
                { name: "Пузат Сайты - 1", spent: 109713 },
                { name: "Пузат Сайты - 2", spent: 89899 },
                { name: "Скорочтение - 3", spent: 74978 },
                { name: "Ударцева Фин.грамотность дети - 2", spent: 64854 },
                { name: "Халилов Маркетинг - 1", spent: 57313 },
                { name: "Темченко МДК - 1", spent: 49659 },
                { name: "Медведев Фото - 2", spent: 42914 },
                { name: "Кузьмин Все Свое - 1", spent: 40808 },
                { name: "Кузьмин Все Свое - 2", spent: 38905 },
                { name: "Павловская Ландшафт - 2", spent: 29744 },
                { name: "Павловская Ландшафт - 1", spent: 29725 },
                { name: "Сысков Астроколесо - 1", spent: 28593 },
                { name: "Цветков Госзакупки - 1", spent: 14422 },
                { name: "Гадельшин Сайты - 1", spent: 2621 },
            ],

            view: "none",
            currentUser: { id: 0, name: "Таргетолог" },
            currentChannel: { id: 0, name: "Канал" },
            radiobtn: "spent",
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "select", "stats", "projects2023", "users"]),

        today() {
            if (!this.stats || this.stats.length < 1) return null;

            // let today = moment().format("YYYY-MM-DD");
            // let end = moment(this.stats[0].date).format("YYYY-MM-DD");

            let result = { impressions: 0, clicks: 0, regs: 0, spent: 0 };
            let graf = [];

            this.stats.forEach((stat) => {
                if (stat.type == 1) {
                    if (stat.date >= this.s.start && stat.date <= this.s.end) {
                        result.impressions += stat.impressions;
                        result.clicks += stat.clicks;
                        result.regs += stat.regs;
                        result.spent += stat.spent / 100;
                        graf.push({ time: stat.date, value: stat.spent / 100 });
                    }
                }
            });

            graf.reverse();

            return { data: result, graf: graf };
        },

        cabs() {
            if (!this.stats || this.stats.length < 1) return null;

            let result = {
                15210968: { id: 15210968, label: "ВКР", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                1900014679: { id: 1900014679, label: "ВИТ", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                1900000062: { id: 1900000062, label: "СТ2", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                1900014266: { id: 1900014266, label: "ТМ", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                1900015316: { id: 1900015316, label: "СТ4", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                1900014874: { id: 1900014874, label: "СТ3", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                2000000000: { id: 2000000000, label: "МП", impressions: 0, clicks: 0, regs: 0, spent: 0 },
            };

            let stats = this.stats.filter((item) => item.type == 3);

            this.cabinets.forEach((cabinet) => {
                stats.forEach((stat) => {
                    if (cabinet.id == stat.object_id)
                        if (stat.date >= this.s.start && stat.date <= this.s.end) {
                            result[cabinet.id].impressions += stat.impressions;
                            result[cabinet.id].clicks += stat.clicks;
                            result[cabinet.id].regs += stat.regs;
                            result[cabinet.id].spent += stat.spent / 100;
                        }
                });
            });

            const mpSpent = this.mp.reduce((totalSpent, stat) => totalSpent + stat.spent, 0);
            result[2000000000] = { id: 2000000000, label: "МП", impressions: 0, clicks: 0, regs: 0, spent: mpSpent };

            return result;
        },

        // CPM & CPC для каждого кабинета помесячно
        cpmcpc() {
            if (!this.stats || this.stats.length < 1) return null;

            let r = [];

            let stats = this.stats.filter((item) => item.type == 3);

            let srt = moment("2023-01-01").format("YYYY-MM");
            let end = moment("2023-12-31").format("YYYY-MM");

            this.cabinets.forEach((cabinet) => {
                let cab = { id: cabinet.id, label: cabinet.label, dates: [] };
                while (srt <= end) {
                    let d = { time: moment(srt).format("YYYY-MM-DD"), impressions: 0, clicks: 0, regs: 0, spent: 0 };
                    stats.forEach((stat) => {
                        if (cabinet.id == stat.object_id)
                            if (stat.date.slice(0, 7) == srt) {
                                d.impressions += stat.impressions;
                                d.clicks += stat.clicks;
                                d.regs += stat.regs;
                                d.spent += stat.spent / 100;
                            }
                    });

                    d.cpm = 0;
                    d.cpc = 0;
                    if (d.impressions > 0) d.cpm = parseInt(d.spent / (d.impressions / 1000));
                    if (d.clicks > 0) d.cpc = parseInt(d.spent / d.clicks) ?? 0;

                    // для графика
                    if (this.radiobtn == "impressions") d.value = d.impressions;
                    if (this.radiobtn == "clicks") d.value = d.clicks;
                    if (this.radiobtn == "spent") d.value = d.spent;
                    if (this.radiobtn == "cpc") d.value = d.cpc;
                    if (this.radiobtn == "cpm") d.value = d.cpm;

                    cab.dates.push(d);
                    srt = moment(srt).add(1, "month").format("YYYY-MM");
                }
                r.push(cab);
                srt = moment("2023-01-01").format("YYYY-MM");
            });

            return r;
        },
    },

    methods: {
        ...mapActions(["getAllStats", "setPopup", "closePopup", "setSettings", "setPeriod", "setTargetolog"]),
    },

    mounted() {
        this.getAllStats();
        this.setPeriod({ start: "2023-01-01", end: "2023-12-31" });
    },
};
</script>
