<template>
    <div class="fixed top-4 left-1/2 -translate-x-1/2 z-50 flex justify-between items-center gap-4">
        <!-- <input type="date" class="input h-8 text-sm bg-white" />
        <input type="date" class="input h-8 text-sm bg-white" />
        <select class="input h-8 text-sm bg-white"></select> -->
        <div class="relative flex justify-end items-center group p-1 text-xs text-slate-400 cursor-pointer" @click="group = !group">
            Группа
            <input
                type="checkbox"
                :checked="group"
                class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
            />
            <span
                class="w-8 h-5 flex items-center flex-shrink-0 ml-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-teal-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
            ></span>
        </div>
    </div>

    <template v-if="!s.request">
        <div class="grid grid-cols-6 gap-4">
            <div class="rounded px-4 mb-4 col-span-2">
                <h2 class="flex justify-center items-center gap-2 text-lg text-amber-400 leading-5 mb-4"><IconStar :size="10" /> ТОП</h2>

                <div class="flex justify-center text-center gap-10 mb-4 border-y border-slate-200 p-2 cursor-pointer">
                    <span @click="top = 'spent'" :class="{ '!text-amber-400': top == 'spent' }">по откруту</span>
                    <span @click="top = 'perclick'" :class="{ '!text-amber-400': top == 'perclick' }">по цене клика</span>
                    <span @click="top = 'perlead'" :class="{ '!text-amber-400': top == 'perlead' }">по цене лида</span>
                </div>

                <div v-if="top == 'spent'" class="grid grid-cols-3 items-center gap-4 mb-10">
                    <template v-for="project in topProjects?.projects" :key="project">
                        <div class="flex flex-col items-center bg-amber-400 text-white rounded-lg shadow-sm p-2">
                            <div class="flex items-center h-8 text-xs text-white text-center leading-4 mb-1">{{ project?.name }}</div>

                            <div class="flex justify-center items-center gap-2 text-white">
                                <span class="font-latobold text-xl">{{ parseInt(project?.spent).toLocaleString() }}</span>
                            </div>
                        </div>
                    </template>
                </div>

                <div v-if="top == 'perclick'" class="grid grid-cols-3 items-center gap-4 mb-10">
                    <template v-for="project in topProjects?.clicks" :key="project">
                        <div class="flex flex-col items-center bg-amber-400 text-white rounded-lg shadow-sm p-2">
                            <div class="flex items-center h-8 text-xs text-white text-center leading-4 mb-1">{{ project?.name }}</div>

                            <div class="flex justify-center items-center gap-2 text-white">
                                <span class="font-latobold text-xl">{{ parseInt(project?.perclick).toLocaleString() }}</span>
                            </div>
                        </div>
                    </template>
                </div>

                <div v-if="top == 'perlead'" class="grid grid-cols-3 items-center gap-4 mb-10">
                    <template v-for="project in topProjects?.leads" :key="project">
                        <div class="flex flex-col items-center bg-amber-400 text-white rounded-lg shadow-sm p-2">
                            <div class="flex items-center h-8 text-xs text-white text-center leading-4 mb-1">{{ project?.name }}</div>

                            <div class="flex justify-center items-center gap-2 text-white">
                                <span v-if="!isFinite(project?.perlead)" class="leading-7 text-4xl">∞</span>
                                <span v-else-if="!isNaN(project?.perlead) && project?.perlead >= 0" class="font-latobold text-xl">
                                    {{ parseInt(project?.perlead).toLocaleString() }}
                                </span>
                                <span v-else class="font-latobold text-xl">0</span>
                            </div>
                        </div>
                    </template>
                </div>

                <!--
                <div class="grid grid-cols-4 items-center gap-4 mb-10">
                    <h2 class="flex items-center gap-2 text-lg text-amber-400 leading-5 mb-4"><IconStar :size="10" /> ТОП 3 <br />по откруту</h2>
                    <template v-for="project in topProjects?.projects" :key="project">
                        <div class="flex flex-col items-center bg-amber-400 text-white rounded-lg shadow-sm p-2">
                            <div class="flex items-center h-8 text-xs text-white text-center leading-4 mb-1">{{ project?.name }}</div>

                            <div class="flex justify-center items-center gap-2 text-white">
                                <span class="font-latobold text-xl">
                                    {{ parseInt(project?.spent).toLocaleString() }}
                                </span>
                            </div>
                        </div>
                    </template>
                </div>

                <div class="grid grid-cols-4 items-center gap-4 mb-10">
                    <h2 class="flex items-center gap-2 text-lg text-amber-400 leading-5 mb-4"><IconStar :size="10" /> ТОП 3 <br />по цене клика</h2>
                    <template v-for="project in topProjects?.clicks" :key="project">
                        <div class="flex flex-col items-center bg-amber-400 text-white rounded-lg shadow-sm p-2">
                            <div class="flex items-center h-8 text-xs text-white text-center leading-4 mb-1">{{ project?.name }}</div>

                            <div class="flex justify-center items-center gap-2 text-white">
                                <span class="font-latobold text-xl">{{ parseInt(project?.perclick).toLocaleString() }}</span>
                            </div>
                        </div>
                    </template>
                </div> -->
                <!-- <span><IVkMoney /></span> -->

                <!-- <div class="grid grid-cols-4 items-center gap-4 mb-10">
                    <h2 class="flex items-center gap-2 text-lg text-amber-400 leading-5 mb-4"><IconStar :size="10" /> ТОП 3 <br />по цене лида</h2>
                    <template v-for="project in topProjects?.leads" :key="project">
                        <div class="flex flex-col items-center bg-amber-400 text-white rounded-lg shadow-sm p-2">
                            <div class="flex items-center h-8 text-xs text-white text-center leading-4 mb-1">{{ project?.name }}</div>

                            <div class="flex justify-center items-center gap-2 text-white">
                                <span class="font-latobold text-xl">{{ parseInt(project?.perlead).toLocaleString() }}</span>
                            </div>
                        </div>
                    </template>
                </div> -->
            </div>

            <div class="col-span-4">
                <!-- <div class="flex justify-between items-center bg-white rounded p-4 mb-4 gap-4">
                    <input type="date" class="input" />

                    <input type="date" class="input" />

                    <select name="" id="" class="input"></select>

                    <div class="relative flex justify-end items-center group p-1 text-xs text-slate-400 cursor-pointer" @click="group = !group">
                        Группа
                        <input
                            type="checkbox"
                            :checked="group"
                            class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
                        />
                        <span
                            class="w-8 h-5 flex items-center flex-shrink-0 ml-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-teal-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
                        ></span>
                    </div>
                </div> -->

                <div v-if="total?.graf" class="w-full"><LineGraf :graf="total?.graf" /></div>

                <div class="grid grid-cols-7 items-center gap-4 bg-white rounded p-4 mb-4">
                    <div class="flex flex-col items-center text-lg font-bold text-sky-800">
                        {{ parseInt(total?.statistics.days).toLocaleString() }}
                        <div class="text-xs text-slate-400">ДНИ</div>
                    </div>
                    <div class="flex flex-col items-center text-lg font-bold text-sky-800">
                        {{ parseFloat(total?.statistics.spent).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
                        <div class="text-xs text-slate-400">ПОТРАЧЕНО</div>
                    </div>

                    <div class="flex flex-col items-center text-lg font-bold text-sky-800">
                        {{ parseInt(total?.statistics.impressions).toLocaleString() }}
                        <div class="text-xs text-slate-400">ОХВАТ</div>
                    </div>

                    <div
                        class="flex flex-col items-center text-lg font-bold text-sky-800"
                        :class="{ '!text-slate-300': total?.statistics.clicks == 0 }"
                    >
                        {{ parseInt(total?.statistics.clicks).toLocaleString() }}
                        <div class="text-xs text-slate-400">КЛИКИ</div>
                    </div>

                    <div class="flex flex-col items-center text-lg font-bold text-sky-800">
                        <div v-if="total?.statistics.regs > 0" class="flex flex-col items-center text-lg font-bold text-sky-800">
                            {{ parseInt(total?.statistics.regs).toLocaleString() }}
                        </div>
                        <div v-else class="text-slate-300">0</div>
                        <div class="text-xs text-slate-400">ЛИДЫ</div>
                    </div>

                    <div class="flex flex-col items-center text-lg font-bold text-sky-800">
                        <div
                            v-if="total?.statistics.spent > 0 && total?.statistics.clicks > 0"
                            class="text-lg font-bold text-sky-800 tabular-nums text-right"
                        >
                            {{
                                parseFloat(total?.statistics.spent / total?.statistics.clicks).toLocaleString(undefined, { maximumFractionDigits: 2 })
                            }}
                            <!-- undefined, { maximumFractionDigits: 2 } -->
                        </div>
                        <div v-else class="text-slate-300">0</div>
                        <div class="text-xs text-slate-400">ЗА КЛИК</div>
                    </div>

                    <div class="flex flex-col items-center text-lg font-bold text-sky-800">
                        <div
                            v-if="total?.statistics.spent > 0 && total?.statistics.regs > 0"
                            class="text-lg font-bold text-sky-800 tabular-nums text-right"
                        >
                            {{ parseFloat(total?.statistics.spent / total?.statistics.regs).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
                            <!-- undefined, { maximumFractionDigits: 2 } -->
                        </div>
                        <div v-else class="text-slate-300">0</div>
                        <div class="text-xs text-slate-400">ЗА ЛИДА</div>
                    </div>
                </div>

                <div class="rounded overflow-hidden">
                    <div class="bg-white grid grid-cols-8 items-center text-xs text-slate-400 uppercase">
                        <div class="col-span-2"></div>
                        <div
                            class="h-6 py-2 px-4 text-right"
                            :class="{ '!bg-sky-50': hover == 'spent' }"
                            @mouseover="hover = 'spent'"
                            @mouseleave="hover = ''"
                        >
                            ПОТРАЧЕНО
                        </div>
                        <div
                            class="h-6 py-2 px-4 text-right"
                            :class="{ '!bg-sky-50': hover == 'impressions' }"
                            @mouseover="hover = 'impressions'"
                            @mouseleave="hover = ''"
                        >
                            ОХВАТ
                        </div>
                        <div
                            class="h-6 py-2 px-4 text-right"
                            :class="{ '!bg-sky-50': hover == 'clicks' }"
                            @mouseover="hover = 'clicks'"
                            @mouseleave="hover = ''"
                        >
                            КЛИКИ
                        </div>
                        <div
                            class="h-6 py-2 px-4 text-right"
                            :class="{ '!bg-sky-50': hover == 'regs' }"
                            @mouseover="hover = 'regs'"
                            @mouseleave="hover = ''"
                        >
                            ЛИДЫ
                        </div>
                        <div
                            class="h-6 py-2 px-4 text-right"
                            :class="{ '!bg-sky-50': hover == 'perclick' }"
                            @mouseover="hover = 'perclick'"
                            @mouseleave="hover = ''"
                        >
                            ЗА КЛИК
                        </div>
                        <div
                            class="h-6 py-2 px-4 text-right"
                            :class="{ '!bg-sky-50': hover == 'perlead' }"
                            @mouseover="hover = 'perlead'"
                            @mouseleave="hover = ''"
                        >
                            ЗА ЛИДА
                        </div>
                    </div>

                    <div class="h-[calc(100vh-300px)] overflow-y-scroll">
                        <div class="h-fit">
                            <template v-for="stat in stats" :key="stat">
                                <div class="mb-4">
                                    <!-- /50 backdrop-blur -->

                                    <div class="grid grid-cols-8 items-center h-12 sticky top-0 bg-white overflow-hidden">
                                        <div class="flex flex-col w-20 leading-4 mt-2 col-span-2 px-4">
                                            <span class="text-2xl">{{ moment(stat.date).format("DD") }}</span>
                                            <span class="text-[8px]">{{ select.monthMin[moment(stat.date).format("M")] }}</span>
                                        </div>

                                        <div
                                            class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                            :class="{ '!text-slate-300': stat.spent == 0, '!bg-sky-50': hover == 'spent' }"
                                            @mouseover="hover = 'spent'"
                                            @mouseleave="hover = ''"
                                        >
                                            {{ parseFloat(stat.spent).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
                                        </div>

                                        <div
                                            class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                            :class="{
                                                '!text-slate-300': stat.impressions == 0,
                                                '!bg-sky-50': hover == 'impressions',
                                            }"
                                            @mouseover="hover = 'impressions'"
                                            @mouseleave="hover = ''"
                                        >
                                            {{ parseInt(stat.impressions).toLocaleString() }}
                                        </div>

                                        <div
                                            class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                            :class="{ '!text-slate-300': stat.clicks == 0, '!bg-sky-50': hover == 'clicks' }"
                                            @mouseover="hover = 'clicks'"
                                            @mouseleave="hover = ''"
                                        >
                                            {{ parseInt(stat.clicks).toLocaleString() }}
                                        </div>

                                        <div
                                            class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                            :class="{ '!bg-sky-50': hover == 'regs' }"
                                            @mouseover="hover = 'regs'"
                                            @mouseleave="hover = ''"
                                        >
                                            <div v-if="stat.regs">{{ parseInt(stat.regs).toLocaleString() }}</div>
                                            <div v-else class="text-slate-300 text-right">0</div>
                                        </div>

                                        <div
                                            class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                            :class="{ '!bg-sky-50': hover == 'perclick' }"
                                            @mouseover="hover = 'perclick'"
                                            @mouseleave="hover = ''"
                                        >
                                            <div v-if="stat.spent > 0 && stat.clicks > 0">
                                                {{ parseFloat(stat.spent / stat.clicks).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
                                            </div>
                                            <div v-else class="text-slate-300 text-right">0</div>
                                        </div>

                                        <div
                                            class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                            :class="{ '!bg-sky-50': hover == 'perlead' }"
                                            @mouseover="hover = 'perlead'"
                                            @mouseleave="hover = ''"
                                        >
                                            <div v-if="stat.spent > 0 && stat.regs > 0">
                                                {{ parseFloat(stat.spent / stat.regs).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
                                            </div>
                                            <div v-else class="text-slate-300 text-right">0</div>
                                        </div>
                                    </div>

                                    <template v-if="!group">
                                        <template v-for="item in stat.statistics" :key="item">
                                            <template v-if="item.impressions > 0 || item.clicks > 0 || item.spent > 0">
                                                <div
                                                    class="h-12 bg-white overflow-hidden grid grid-cols-8 items-center text-right border-t border-slate-200 hover:bg-sky-50 hover:!text-sky-600"
                                                >
                                                    <div class="col-span-2 text-left px-4">
                                                        <div class="text-sm font-bold text-slate-500">{{ item.project }}</div>
                                                        <div class="text-xs text-slate-700">{{ item.offer }}</div>
                                                    </div>

                                                    <div
                                                        class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                                        :class="{
                                                            '!text-slate-300': item.spent == 0,
                                                            '!bg-sky-50': hover == 'spent',
                                                        }"
                                                        @mouseover="hover = 'spent'"
                                                        @mouseleave="hover = ''"
                                                    >
                                                        {{ parseFloat(item.spent).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
                                                    </div>

                                                    <div
                                                        class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                                        :class="{ '!text-slate-300': item.impressions == 0, '!bg-sky-50': hover == 'impressions' }"
                                                        @mouseover="hover = 'impressions'"
                                                        @mouseleave="hover = ''"
                                                    >
                                                        {{ parseInt(item.impressions).toLocaleString() }}
                                                    </div>

                                                    <div
                                                        class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                                        :class="{ '!text-slate-300': item.clicks == 0, '!bg-sky-50': hover == 'clicks' }"
                                                        @mouseover="hover = 'clicks'"
                                                        @mouseleave="hover = ''"
                                                    >
                                                        {{ parseInt(item.clicks).toLocaleString() }}
                                                    </div>

                                                    <div
                                                        class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                                        :class="{ '!bg-sky-50': hover == 'regs' }"
                                                        @mouseover="hover = 'regs'"
                                                        @mouseleave="hover = ''"
                                                    >
                                                        <div v-if="item.regs">{{ parseInt(item.regs).toLocaleString() }}</div>
                                                        <div v-else class="text-slate-300 text-right">0</div>
                                                    </div>

                                                    <div
                                                        class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                                        :class="{ '!bg-sky-50': hover == 'perclick' }"
                                                        @mouseover="hover = 'perclick'"
                                                        @mouseleave="hover = ''"
                                                    >
                                                        <div v-if="item.spent > 0 && item.clicks > 0">
                                                            {{
                                                                parseFloat(item.spent / item.clicks).toLocaleString(undefined, {
                                                                    maximumFractionDigits: 2,
                                                                })
                                                            }}
                                                        </div>
                                                        <div v-else class="text-slate-300 text-right">0</div>
                                                    </div>

                                                    <div
                                                        class="flex justify-end items-center h-12 font-bold text-sky-800 tabular-nums px-4"
                                                        :class="{ '!bg-sky-50': hover == 'perlead' }"
                                                        @mouseover="hover = 'perlead'"
                                                        @mouseleave="hover = ''"
                                                    >
                                                        <div v-if="item.spent > 0 && item.regs > 0">
                                                            {{
                                                                parseFloat(item.spent / item.regs).toLocaleString(undefined, {
                                                                    maximumFractionDigits: 2,
                                                                })
                                                            }}
                                                        </div>
                                                        <div v-else class="text-slate-300 text-right">0</div>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-sky-400"><IconLoader :size="30" /></div>

    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "MyReport",

    data() {
        return {
            moment: moment,
            group: false,
            hover: "",
            top: "spent",
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "select", "users", "stats"]),

        // list() {
        //     if (!this.stats || this.stats.length < 1) return null;

        //     let str = moment(this.stats[this.stats.length - 1].date).format("YYYY-MM-DD");
        //     let end = moment(this.stats[0].date).format("YYYY-MM-DD");

        //     let data = [];
        //     let graf = [];

        //     let total_days = 0;
        //     let total_impressions = 0;
        //     let total_clicks = 0;
        //     let total_regs = 0;
        //     let total_spent = 0;

        //     while (str <= end) {
        //         let items = [];
        //         let impressions = 0;
        //         let clicks = 0;
        //         let regs = 0;
        //         let spent = 0;
        //         this.stats.forEach((stat) => {
        //             if (stat.date == end) {
        //                 if (!this.group) {
        //                     if (items.length <= 0) items = [];
        //                     items.push({
        //                         id: stat.client_id,
        //                         project: stat.project,
        //                         offer: stat.offer,
        //                         offer_id: stat.offer_id,
        //                         impressions: stat.impressions ?? 0,
        //                         clicks: stat.clicks ?? 0,
        //                         regs: stat.regs ?? 0,
        //                         spent: stat.spent ?? 0,
        //                     });
        //                 }

        //                 impressions += parseInt(stat.impressions) || 0;
        //                 clicks += parseInt(stat.clicks) || 0;
        //                 regs += parseInt(stat.regs) || 0;
        //                 spent += parseFloat(stat.spent) || 0;

        //                 total_impressions += parseInt(stat.impressions) || 0;
        //                 total_clicks += parseInt(stat.clicks) || 0;
        //                 if (parseInt(stat.regs) > 0) total_regs += parseInt(stat.regs) || 0;
        //                 total_spent += parseFloat(stat.spent) || 0;
        //             }
        //         });

        //         if (spent > 0) total_days++;

        //         data.push({ date: end, items: items, impressions: impressions, clicks: clicks, regs: regs, spent: spent });

        //         graf.push({ time: end, value: parseFloat(spent) }); // график

        //         end = moment(end).subtract(1, "day").format("YYYY-MM-DD");
        //     }

        //     // let data = this.stats.filter((stat) => parseInt(stat.targetolog_id) == this.profile.id);

        //     return {
        //         graf: graf.reverse(),
        //         total: { days: total_days, impressions: total_impressions, clicks: total_clicks, regs: total_regs, spent: total_spent },
        //     };
        // },

        topProjects() {
            if (!this.stats || this.stats.length < 1) return null;

            let projects = [];
            this.stats.forEach((date) => {
                date.statistics.forEach((stat) => {
                    if (!projects[stat.project_id])
                        projects[stat.project_id] = { id: stat.project_id, name: stat.project, spent: 0, clicks: 0, regs: 0 };
                    projects[stat.project_id].spent += parseFloat(stat.spent);
                    projects[stat.project_id].clicks += parseInt(stat.clicks);
                    projects[stat.project_id].regs += parseInt(stat.regs);
                });
            });

            let clicks = projects.map((item) => ({ id: item.id, name: item.name, perclick: parseFloat(item.spent / item.clicks) }));
            let leads = projects.map((item) => ({ id: item.id, name: item.name, perlead: parseFloat(item.spent / item.regs) }));

            projects = projects.filter((item) => parseInt(item.id) > 0);
            clicks = clicks.filter((item) => parseInt(item.id) > 0 && item.perclick >= 0);
            leads = leads.filter((item) => parseInt(item.id) > 0 && item.perlead >= 0);

            projects.sort((a, b) => parseFloat(b.spent) - parseFloat(a.spent));
            clicks.sort((a, b) => parseFloat(a.perclick) - parseFloat(b.perclick));
            leads.sort((a, b) => parseFloat(a.perlead) - parseFloat(b.perlead));

            return { projects: projects, clicks: clicks, leads: leads };
        },

        total() {
            if (!this.stats || this.stats.length < 1) return null;

            let graf = [];

            let days = 0;
            let impressions = 0;
            let clicks = 0;
            let regs = 0;
            let spent = 0;

            this.stats.forEach((date) => {
                let graf_spent = 0;
                days++;
                date.statistics.forEach((stat) => {
                    impressions += parseInt(stat.impressions);
                    clicks += parseInt(stat.clicks);
                    regs += parseInt(stat.regs);
                    spent += parseFloat(stat.spent);
                    graf_spent += parseFloat(stat.spent);
                });
                graf.push({ time: moment(date.date).format("YYYY-MM-DD"), value: parseFloat(graf_spent) }); // график
            });

            let statistics = {
                days: days,
                impressions: impressions,
                clicks: clicks,
                regs: regs,
                spent: spent.toFixed(2),
            };

            return { statistics: statistics, graf: graf.reverse() };
        },
    },

    methods: {
        ...mapActions(["getTargetologReport"]),
    },

    mounted() {
        this.getTargetologReport();
    },
};
</script>
