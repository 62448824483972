<template>
    <div class="h-60 w-full" ref="newChartOrder"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: "MultiLineGraf",

    props: {
        graf: {
            type: Array,
        },
    },

    watch: {
        graf(newValue) {
            if (newValue) {
                if (this.root) this.root.dispose();
                this.showGraf(newValue);
            }
        },
    },

    methods: {
        showGraf() {
            // console.log("graf", this.graf);

            let root = am5.Root.new(this.$refs.newChartOrder);

            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: true,
                    panY: true,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    pinchZoomX: true,
                })
            );

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, { behavior: "none" }));
            cursor.lineY.set("visible", false);

            let xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    groupData: false,
                    baseInterval: {
                        timeUnit: "month",
                        count: 1,
                    },
                    renderer: am5xy.AxisRendererX.new(root, {}),
                    tooltip: am5.Tooltip.new(root, {}),
                })
            );

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    // forceHidden: true,
                    renderer: am5xy.AxisRendererY.new(root, {}),
                })
            );

            this.graf.forEach((item) => {
                let series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        name: item.label,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value",
                        valueXField: "time",
                        // legendValueText: "{valueY}",
                        tooltip: am5.Tooltip.new(root, {
                            pointerOrientation: "horizontal",
                            labelText: item.label + " {valueY}",
                        }),
                    })
                );

                series.strokes.template.setAll({ strokeWidth: 2 });

                series.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "yyyy-MM-dd",
                    dateFields: ["time"],
                });

                series.data.setAll(item.dates);

                // series.bullets.push(function () {
                //     var circle = am5.Circle.new(root, {
                //         radius: 4,
                //         fill: root.interfaceColors.get("background"),
                //         stroke: series.get("fill"),
                //         strokeWidth: 2,
                //     });

                //     return am5.Bullet.new(root, {
                //         sprite: circle,
                //     });
                // });

                series.appear();
            });

            // Легенда
            var legend = chart.rightAxesContainer.children.push(
                am5.Legend.new(root, {
                    width: 50,
                    paddingLeft: 10,
                    height: am5.percent(100),
                })
            );

            // When legend item container is hovered, dim all the series except the hovered one
            legend.itemContainers.template.events.on("pointerover", function (e) {
                var itemContainer = e.target;

                // As series list is data of a legend, dataContext is series
                var series = itemContainer.dataItem.dataContext;

                chart.series.each(function (chartSeries) {
                    if (chartSeries != series) {
                        chartSeries.strokes.template.setAll({
                            strokeOpacity: 0.15,
                            stroke: am5.color(0x000000),
                        });
                    }
                });
            });

            // When legend item container is unhovered, make all series as they are
            legend.itemContainers.template.events.on("pointerout", function (e) {
                let itemContainer = e.target;
                itemContainer.dataItem.dataContext;

                chart.series.each(function (chartSeries) {
                    chartSeries.strokes.template.setAll({
                        strokeOpacity: 1,
                        stroke: chartSeries.get("fill"),
                    });
                });
            });

            legend.itemContainers.template.set("width", am5.p50);
            legend.valueLabels.template.setAll({
                align: "end",
                textAlign: "right",
            });

            legend.data.setAll(chart.series.values);

            // Animate on load
            chart.appear(1000, 100);

            this.root = root;
        },
    },
    mounted() {
        this.showGraf(this.graf);
    },

    beforeUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    },
};
</script>

<style scoped></style>
