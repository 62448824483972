<template>
    <div class="fixed z-50 w-full h-12" :class="{ dark: s.mode == 'dark' }">
        <nav-menu></nav-menu>
    </div>
    <div class="min-h-screen bg-slate-100 p-4 pt-16" :class="{ 'dark !bg-sky-900': s.mode == 'dark' }">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavMenu from "./components/Menu.vue";

export default {
    name: "App",

    components: {
        NavMenu,
    },

    computed: {
        ...mapGetters(["s", "alert"]),
    },

    watch: {
        alert() {
            if (this.alert?.type == "success") {
                this.$toast.success(this.alert.msg);
            }
            if (this.alert?.type == "error") {
                this.$toast.error(this.alert.msg);
            }
            if (this.alert?.type == "warning") {
                this.$toast.warning(this.alert.msg);
            }
            if (this.alert?.type == "info") {
                this.$toast.info(this.alert.msg);
            }
        },
    },

    methods: {
        ...mapActions(["getProfile", "loadSettings"]),

        currentRoute() {
            return this.$route.path;
        },
    },

    mounted() {
        // console.log("App", Math.floor(Date.now() / 1000));
        this.getProfile();
        this.loadSettings();
    },
};
</script>
