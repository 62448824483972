<template>
    <template v-if="!s.request">
        <div class="bg-white rounded p-4 mb-4">
            <div class="flex justify-between items-center gap-4">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    КЛИЕНТЫ <span class="count">{{ clients?.length }}</span>
                </div>
                <div class="btn btn-green cursor-pointer gap-2" @click="setPopup('addClient')"><IcoBig name="plus" :size="6" /> ДОБАВИТЬ</div>
            </div>
        </div>

        <template v-for="project in projects" :key="project">
            <template v-if="project.id && project.id > 0">
                <div class="bg-white rounded mt-8">
                    <div class="flex items-center h-12 px-4 gap-4 text-lg text-sky-800 border-b border-slate-200">
                        <span class="text-xs">{{ project.id }}</span> {{ project.name }}
                    </div>
                    <template v-for="client in project.clients" :key="client.id">
                        <div class="flex items-center h-12 px-4 gap-4 text-md text-slate-500 border-b border-slate-200">
                            <span class="text-xs">{{ client.id }}</span>
                            {{ client.name }}
                            <div class="w-12 rounded-r-full rounded-tl-full bg-sky-600 py-1 px-3 text-center text-md text-white">
                                {{ client.label }}
                            </div>
                        </div>
                        <!-- <template v-for="campaign in client.campaigns" :key="campaign.id">
                            <template v-if="!campaign.targetolog_id || campaign.targetolog_id == profile.id">
                                <div
                                    class="h-12 overflow-hidden grid grid-cols-9 items-center gap-4 px-4 border-b border-slate-200 text-xs hover:bg-sky-50/50 hover:!text-sky-700"
                                >
                                    <div class="flex items-center gap-4 col-span-6">
                                        <a
                                            v-if="campaign.channel_id == 1"
                                            class="text-2xl text-sky-600 font-bold text-right tabular-nums cursor-pointer"
                                            target="_blank"
                                            :href="'https://vk.com/ads?act=office&union_id=' + campaign.id"
                                        >
                                            <IVk />
                                        </a>
                                        <a
                                            v-if="campaign.channel_id == 2"
                                            class="text-2xl text-sky-600 font-bold text-right tabular-nums cursor-pointer p-1"
                                            target="_blank"
                                            href="https://ads.vk.com/hq/dashboard"
                                        >
                                            <IVkAds :size="6" />
                                        </a>
                                        <div>
                                            <span class="text-xs text-slate-400">{{ campaign.id }}</span>
                                            <div class="text-[13px]">{{ campaign.name }}</div>
                                        </div>
                                    </div>

                                    <div class="text-[13px]">{{ campaign.ads_active }} / {{ campaign.ads_all }}</div>

                                    <div>
                                        <div class="flex items-center gap-2" v-if="campaign.status == 1">
                                            <span class="circle bg-teal-500"></span> Активна
                                        </div>
                                        <div class="flex items-center gap-2" v-if="campaign.status == 0">
                                            <span class="circle bg-pink-500"></span> Выключенa
                                        </div>
                                    </div>

                                    <div class="text-slate-400 hover:text-red-500 cursor-pointer" @click="unLinkMyClient(campaign.id)">
                                        <IconClose :size="4" />
                                    </div>
                                </div>
                            </template>
                        </template> -->
                    </template>
                </div>
            </template>
        </template>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-sky-400"><IconLoader :size="30" /></div>

    <template v-if="s.popup == 'addClient'"><AddClient /></template>
    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "MyClients",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "select", "profile", "clients"]),

        projects() {
            if (!this.clients || this.clients.length < 1) return null;

            var startTime = performance.now();

            const grouped = this.clients.reduce((result, item) => {
                const PROJECT = item.project_id;

                if (!result[PROJECT]) {
                    result[PROJECT] = { id: item.project_id, name: item.project, clients: [] };
                }

                result[PROJECT].clients.push(item);
                return result;
            }, {});

            var endTime = performance.now();
            console.log("time new", endTime - startTime + "ms");

            return grouped;
        },
    },

    methods: {
        ...mapActions(["getTargetologClients", "setPopup", "closePopup", "clearSettings", "unLinkMyClient", "filterClients"]),
    },

    mounted() {
        this.getTargetologClients();
    },
};
</script>
