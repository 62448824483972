<template>
    <div v-if="profile?.id" class="container rounded mx-auto lg:flex gap-4">
        <div class="w-full lg:w-1/3 p-4 bg-white">
            <div class="flex text-base text-zinc-800 font-latobold flex-col items-center">
                <div class="w-48 h-48 my-5 mx-auto rounded-full bg-black overflow-hidden relative">
                    <span class="w-full h-full group">
                        <input
                            type="file"
                            id="avatar"
                            class="absolute invisible opacity-0"
                            multiple
                            @change="uploadImg({ e: $event, type: 'profile', id: profile.user_id })"
                        />
                        <label
                            for="avatar"
                            class="w-1/2 h-1/2 flex items-center justify-center rounded-full absolute -top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 group-hover:bg-black group-hover:opacity-50 group-hover:top-1/2"
                        >
                            <svg class="fill-white w-5 h-5">
                                <use xlink:href="@/assets/i.svg#ico-edit"></use>
                            </svg>
                        </label>
                        <img
                            v-if="profile?.picture"
                            class="w-full h-full object-cover"
                            :src="'https://api.timekraken.ru/uploads/users/' + profile?.picture"
                        />
                        <img v-else class="w-full h-full object-cover" src="https://api.timekraken.ru/uploads/users/u0.png" />
                    </span>
                </div>

                {{ profile?.name }} {{ profile?.secondname }}
            </div>
            <!-- <div class="flex">
                <div @click="setMode('dark')" class="text-white text-center bg-cyan-900 font-bold rounded-full w-8 h-8 p-1 cursor-pointer">B</div>
                <div @click="setMode('lite')" class="text-slate-500 text-center bg-cyan-50 font-bold rounded-full w-8 h-8 p-1 cursor-pointer">L</div>
            </div> -->

            <div class="flex">
                <!-- <a class="mx-auto mt-4 v-btn bg-teal-500 hover:bg-teal-400 transition-colors duration-150" @click="editProfile">
                        <svg class="fill-white w-16px h-16px mr-3">
                            <use xlink:href="@/assets/i.svg#ico-edit"></use>
                        </svg>
                        <span class="v-btn_text">РЕДАКТИРОВАТЬ</span>
                    </a> -->
                <a
                    class="mx-auto mt-4 v-btn py-2 px-4 cursor-pointer rounded bg-rose-500 hover:bg-rose-400 transition-colors duration-150"
                    @click="logout()"
                >
                    <span class="text-white">ВЫХОД</span>
                </a>
            </div>
        </div>

        <div class="w-full lg:w-2/3 bg-white flex flex-col">
            <template v-if="['An', 'Or', 'Bs'].includes(profile.role)">
                <h2 class="p-4 text-center border-b border-slate-200 text-sm text-slate-500">ПОЛЬЗОВАТЕЛИ</h2>

                <div class="p-4">
                    <div
                        v-for="user in users"
                        :key="user.id"
                        class="grid grid-cols-10 items-center border-b border-slate-200 p-2"
                        @click="pickUser(user), $router.push('/user')"
                    >
                        <div>
                            <img
                                v-if="user?.picture"
                                class="w-10 h-10 object-cover rounded-full"
                                :src="'https://api.timekraken.ru/uploads/users/mini/' + user?.picture"
                            />
                            <img v-else class="w-10 h-10 object-cover rounded-full" src="https://api.timekraken.ru/uploads/noimg.jpg" />
                        </div>
                        <div class="col-span-4">{{ user.name }} {{ user.secondname }}</div>
                        <div class="col-span-4 text-xs">
                            <div v-if="user.role == 'Or'">Админ</div>
                            <div v-if="user.role == 'Bs'">Старший бюджетного отдела</div>
                            <div v-if="user.role == 'Bo'">Бюджетный отдел</div>
                            <div v-if="user.role == 'Ls'">Старший юрист</div>
                            <div v-if="user.role == 'Lr'">Юрист</div>
                        </div>
                        <div>{{ user.status }}</div>
                    </div>

                    <h2 class="text-center mt-8 mb-4 text-slate-400">СОЗДАТЬ НОВОГО ПОЛЬЗОВАТЕЛЯ</h2>

                    <div class="flex justify-between items-end gap-2">
                        <div class="flex flex-col">
                            <label class="text-xs text-slate-400 mb-1">Имя</label>
                            <input type="text " class="input" v-model="newUser.name" />
                        </div>

                        <div class="flex flex-col">
                            <label class="text-xs text-slate-400 mb-1">Фамилия</label>
                            <input type="text " class="input" v-model="newUser.secondname" />
                        </div>

                        <div class="flex flex-col">
                            <label class="text-xs text-slate-400 mb-1">Логин</label>
                            <input type="text " class="input" v-model="newUser.username" />
                        </div>

                        <div class="flex flex-col">
                            <label class="text-xs text-slate-400 mb-1">Email</label>
                            <input type="text " class="input" v-model="newUser.email" />
                        </div>

                        <div class="flex flex-col">
                            <label class="text-xs text-slate-400 mb-1">Роль</label>
                            <select v-model="newUser.role" class="input">
                                <option value="Bo">Бюджетный отдел</option>
                                <option value="Lr">Юрист</option>
                            </select>
                            <!-- <input type="text " class="input" v-model="newUser.username" /> -->
                        </div>

                        <div
                            @click="createUser(newUser), (newUser = { name: '', secondname: '', username: '', email: '', role: '' })"
                            class="btn btn-green gap-2"
                        >
                            <span class="text-white my-2"><IconPlus :size="5" /></span>
                            <span class="text-white text-xs">СОЗДАТЬ</span>
                        </div>
                    </div>
                </div>
            </template>
            <!-- <h2 class="p-4 text-center border-b border-slate-200 text-sm text-slate-500">АККАУНТЫ</h2> -->
            <!-- <div class="p-4"></div> -->
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ProfilePage",

    data() {
        return {
            newUser: { name: "", secondname: "", username: "", email: "", role: "" },
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "users"]),
    },

    methods: {
        ...mapActions(["getProfile", "getUsers", "logout", "uploadImg", "editProfile", "createUser", "pickUser", "setMode"]),
    },

    mounted() {
        this.getProfile();
        this.getUsers();
    },
};
</script>
