<template>
    <div class="flex justify-between items-center w-full h-12 py-2 px-4 backdrop-blur">
        <!-- <a @click="$router.push('/projects')" class="flex justify-between items-center text-xl font-bold text-sky-500 cursor-pointer"> ДОКУМЕНТЫ </a> -->

        <ul class="menu circe flex justify-between items-center text-center text-sm h-12 gap-4">
            <li v-for="item in menu" :key="item" :class="{ activeMenu: s.activeMenu == item.url }">
                <a @click="$router.push(item.url), setActiveMemu(item.url)">{{ item.name }}</a>
            </li>
        </ul>

        <!-- <ul class="menu circe flex justify-between items-center text-center text-sm h-12 gap-4">
            <li :class="{ activeMenu: s.activeMenu == '/cabinets' }" v-if="['Or', 'Bs', 'Bo'].includes(profile?.role)">
                <a @click="$router.push('/cabinets'), setActiveMemu('/cabinets')">Кабинеты</a>
            </li>
            <li :class="{ activeMenu: s.activeMenu == '/projects' }">
                <a @click="$router.push('/projects'), setActiveMemu('/projects')">Проекты</a>
            </li>
            <li :class="{ activeMenu: s.activeMenu == '/clients' }" v-if="['Or', 'Bs', 'Bo'].includes(profile?.role)">
                <a @click="$router.push('/clients'), setActiveMemu('/clients')">Клиенты</a>
            </li>
            <li v-if="['Tg'].includes(profile?.role)">
                <a @click="$router.push('/report')">Отчет</a>
            </li>
            <li :class="{ activeMenu: s.activeMenu == '/archive' }" v-if="['Or', 'Bs'].includes(profile?.role)">
                <a @click="$router.push('/archive'), setActiveMemu('/archive')">Архив</a>
            </li>
        </ul> -->

        <div class="flex items-center gap-4 h-12 font-medium text-sm text-slate-500">
            {{ profile?.name }}
            <a
                class="bg-white w-8 h-8 my-2 rounded-full overflow-hidden relative cursor-pointer"
                @click="$router.push('/profile'), setActiveMemu('/profile')"
            >
                <img
                    v-if="profile?.picture"
                    class="w-full h-full object-cover"
                    :src="'https://api.timekraken.ru/uploads/users/mini/' + profile?.picture"
                />
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "BotMenu",

    data() {
        return {};
    },

    computed: {
        ...mapGetters(["s", "profile", "menu"]),
    },
    methods: {
        ...mapActions(["setActiveMemu"]),
    },
};
</script>
