<template>
    <template v-if="!s.request">
        <div class="bg-white rounded p-4 mb-4">
            <div class="flex justify-between items-center gap-4">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    <span class="count count-amber">{{ erids?.length }}</span>
                    ОБЪЯВЛЕНИЯ "запись в сообществе" БЕЗ ERID
                </div>
            </div>
        </div>

        <template v-if="projects">
            <template v-for="project in projects" :key="project">
                <template v-if="project.id && project.id > 0">
                    <div class="bg-white rounded mt-8">
                        <div class="flex justify-between items-center h-12 px-4 gap-4 text-lg text-sky-800 border-b border-slate-200">
                            <div>
                                <span class="text-xs">{{ project.id }}</span> {{ project.name }}
                            </div>
                            <div @click="closeItem(project.id)" :class="{ 'rotate-180': closeIds.has(project.id) }"><IconAltDown :size="6" /></div>
                        </div>
                        <template v-if="!closeIds.has(project.id)">
                            <template v-for="client in project.clients" :key="client">
                                <div class="flex justify-between items-center h-12 px-4 gap-4 text-md text-slate-500 border-b border-slate-200">
                                    <div>
                                        <span @click="closeItem(client.id)" class="count count-amber mr-4 cursor-pointer">
                                            {{ client.items?.length }}
                                        </span>
                                        <span class="text-xs">{{ client.id }}</span>
                                        {{ client.name }}
                                    </div>
                                    <div @click="closeItem(client.id)" :class="{ 'rotate-180': closeIds.has(client.id) }">
                                        <IconAltDown :size="6" />
                                    </div>
                                </div>
                                <template v-if="!closeIds.has(client.id)">
                                    <template v-for="item in client.items" :key="item.id">
                                        <div
                                            class="h-12 overflow-hidden grid grid-cols-12 items-center gap-4 px-4 border-b border-slate-200 text-[13px] hover:bg-sky-50/50 hover:!text-sky-700"
                                        >
                                            <div>
                                                <a
                                                    v-if="item.url"
                                                    class="w-full rounded-r-full rounded-tl-full bg-amber-400 py-1 px-3 text-center text-md text-white cursor-pointer"
                                                    target="_blank"
                                                    :href="item.url"
                                                >
                                                    смотреть
                                                </a>
                                            </div>

                                            <div class="flex items-center gap-4 col-span-5">
                                                <a
                                                    v-if="item.channel_id == 1"
                                                    class="text-2xl text-sky-600 font-bold text-right cursor-pointer"
                                                    target="_blank"
                                                    :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                                                >
                                                    <IVk />
                                                </a>
                                                <div>
                                                    <span class="text-xs text-slate-400">{{ item.id }}</span>
                                                    <div>{{ item.name }}</div>
                                                </div>
                                            </div>

                                            <div>
                                                <div class="text-teal-500" v-if="item.approved == 2"><IconOk /></div>
                                                <div class="text-red-500" v-if="item.approved == 1"><IconMinus /></div>
                                            </div>

                                            <div>
                                                <div v-if="item.created">{{ moment.unix(item.created).format("DD.MM.YYYY") }}</div>
                                            </div>

                                            <div class="flex justify-end items-center gap-2">
                                                {{ parseInt(item.impressions) }}
                                                <span class="text-slate-400"> <IconEye :size="3" /></span>
                                            </div>
                                            <div class="flex justify-end items-center gap-2">
                                                {{ parseInt(item.clicks) }}
                                                <span class="text-slate-400"><IconClick :size="3" /></span>
                                            </div>
                                            <div class="flex justify-end items-center gap-2">
                                                {{ parseFloat(item.spent / 100) }}
                                                <span class="text-slate-400">₽</span>
                                            </div>

                                            <div>
                                                <div class="flex justify-end items-center gap-2 mr-1" v-if="item.status == 1">
                                                    <span class="circle bg-teal-500"></span>
                                                </div>
                                                <div class="flex justify-end items-center gap-2 mr-1" v-if="item.status == 0">
                                                    <span class="circle bg-pink-500"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </div>
                </template>
            </template>
        </template>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-sky-400"><IconLoader :size="30" /></div>

    <template v-if="s.popup == 'addClient'"><AddClient /></template>
    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "MyErid",

    data() {
        return {
            moment: moment,
            closeIds: new Set(),

            ad_formats: {
                1: "изображение и текст",
                2: "большое изображение",
                3: "эксклюзивный формат",
                4: "продвижение сообществ",
                5: "приложение в новостной ленте",
                6: "мобильное приложение",
                9: "запись в сообществе",
                11: "адаптивный формат",
                12: "истории",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "select", "profile", "erids"]),

        projects() {
            if (!this.erids || this.erids.length < 1) return null;

            var startTime = performance.now();

            const grouped = this.erids.reduce((result, item) => {
                const outerKey = item.project_id;
                const innerKey = item.client_id;

                if (!result[outerKey]) {
                    result[outerKey] = { id: item.project_id, name: item.project, clients: {} };
                }

                if (!result[outerKey].clients[innerKey]) {
                    result[outerKey].clients[innerKey] = {
                        id: item.client_id,
                        name: item.client,
                        counter_type: item.counter_type,
                        counter_id: item.counter_id,
                        goal_id: item.goal_id,
                        items: [],
                    };
                }

                result[outerKey].clients[innerKey].items.push(item);
                return result;
            }, {});

            var endTime = performance.now();
            console.log("time new", endTime - startTime + "ms");

            return grouped;
        },
    },

    methods: {
        ...mapActions(["getTargetologErids", "setPopup", "closePopup", "clearSettings"]),

        closeItem(id) {
            if (this.closeIds.has(id)) {
                this.closeIds.delete(id);
            } else {
                this.closeIds.add(id);
            }
        },
    },

    mounted() {
        this.getTargetologErids();
    },
};
</script>
