import API from "../../services/api";

export default {
    state: {
        ads: [],
        adsF: [],
        ad: {},
    },

    mutations: {
        setAds(state, ads) {
            state.ads = ads;
            state.adsF = ads;
        },
        setAdsF(state, ads) {
            state.adsF = ads;
        },
        setAd(state, ad) {
            localStorage.setItem("ad", JSON.stringify(ad));
            state.ad = ad;
        },
    },

    actions: {
        parseAllClients(ctx) {
            console.log("parseAllClients");

            API.GET("parse/all-clients").then((res) => {
                console.log(res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                // ctx.commit("setCabinets", res.data.cabinets);
                // ctx.commit("setAlert", { msg: "Задача поставлена в очередь", type: "info" });
            });
        },

        parseClients(ctx, cabinet_id) {
            console.log("parseClients", cabinet_id);

            let formData = new FormData();
            formData.append("cabinet_id", cabinet_id);

            API.POST("parse/clients", formData).then((res) => {
                console.log(res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                // ctx.commit("setCabinets", res.data.cabinets);
                // ctx.commit("setAlert", { msg: "Задача поставлена в очередь", type: "info" });
            });
        },

        parseCampaigns(ctx, cabinet_id) {
            let formData = new FormData();
            formData.append("cabinet_id", cabinet_id);

            API.POST("parse/campaigns", formData).then(() => {
                // console.log(res.data);
                // if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                // ctx.commit("setCabinets", res.data.cabinets);
                ctx.commit("setAlert", { msg: "Задача поставлена в очередь", type: "info" });
            });
        },

        parseAds(ctx, cabinet_id) {
            let formData = new FormData();
            formData.append("cabinet_id", cabinet_id);

            API.POST("parse/ads", formData).then(() => {
                // console.log(res.data);
                // if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                // ctx.commit("setCabinets", res.data.cabinets);
                ctx.commit("setAlert", { msg: "Задача поставлена в очередь", type: "info" });
            });
        },

        getAds(ctx) {
            if (!ctx.state.ads || ctx.state.ads?.length < 1) {
                ctx.dispatch("request", true);
                API.GET("ad/list").then((res) => {
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);

                    ctx.commit("setAds", res.data.ads);
                    // ctx.dispatch("filterAds");
                    ctx.dispatch("request", false);
                });
            }
        },

        filterAds(ctx) {
            if (!ctx.state.ads) return null;

            ctx.dispatch("filterList", ctx.state.ads).then((res) => {
                ctx.commit("setAdsF", res);
            });
        },

        pickAd(ctx, id) {
            let ad = ctx.state.ads.filter((item) => parseInt(item.id) == parseInt(id));
            if (!ad || ad[0].id < 1) {
                ctx.commit("setAdsF", null);
                return null;
            }

            ctx.commit("setAd", ad[0]);

            let ads = ctx.state.ads.filter((item) => parseInt(item.ad_id) == parseInt(ad[0].id));
            ctx.commit("setAdsF", ads);
        },

        unPickProject(ctx) {
            ctx.commit("setAd", {});
            ctx.commit("setAdsF", ctx.state.ads);
        },
    },

    getters: {
        ads(state) {
            return state.adsF;
        },
        ad(state) {
            return state.ad;
        },
    },
};
