import API from "../../services/api";

export default {
    state: {
        stats: [],
        statsF: [],
        stat: {},

        projects2023: [],
    },

    mutations: {
        setStats(state, stats) {
            state.stats = stats;
            state.statsF = stats;
        },
        setStatsF(state, stats) {
            state.statsF = stats;
        },
        setStat(state, stat) {
            state.stat = stat;
        },
        setProjects2023(state, projects) {
            state.projects2023 = projects;
        },
    },

    actions: {
        getAllStats(ctx) {
            if (!ctx.state.stats || ctx.state.stats?.length < 1) {
                ctx.dispatch("request", true);
                API.GET("stat/all").then((res) => {
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                    ctx.commit("setStats", res.data.stats);
                    ctx.commit("setProjects2023", res.data.projects2023);

                    ctx.dispatch("request", false);
                });
            }
        },
    },

    getters: {
        stats(state) {
            return state.statsF;
        },
        stat(state) {
            return state.stat;
        },
        projects2023(state) {
            return state.projects2023;
        },
    },
};
