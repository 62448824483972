<template>
    <svg
        class="fill-stroke transition duration-300"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M20 16.2A4.5 4.5 0 0017.5 8h-1.8A7 7 0 104 14.9" />
        <path d="M12 12v9" />
        <path d="M16 16l-4-4-4 4" />
    </svg>
</template>

<script>
export default {
    name: "IconUpload",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
