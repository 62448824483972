import { createRouter, createWebHistory } from "vue-router";
import authService from "./auth";

import MainPage from "@/Main/Main";
import LoginPage from "@/Main/Login";
import ProfilePage from "@/Main/Profile";
import UsersPage from "@/User/Users";

import ArchivePage from "@/Doc/Archive";
import CabinetsPage from "@/Doc/Cabinets";
import ClientsPage from "@/Doc/Clients";
import EridPage from "@/Doc/Erid";
import ProjectPage from "@/Doc/Project";
import ProjectsPage from "@/Doc/Projects";

import OrdMain from "@/ORD/Main";

import MainStat from "@/Stat/MainStat";
import MainStat2023 from "@/Stat/MainStat2023";
import PresentationStat from "@/Stat/PresentationStat";

import AdsPage from "@/Vk/Ads";

import MyReport from "@/Targetolog/MyReport";
import MyClients from "@/Targetolog/MyClients";
import MyAds from "@/Targetolog/MyAds";
import MyErid from "@/Targetolog/MyErid";

const routes = [
    {
        path: "/",
        name: "Main",
        component: MainPage,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
    },
    {
        path: "/profile",
        name: "Profile",
        component: ProfilePage,
    },
    {
        path: "/users",
        name: "Users",
        component: UsersPage,
    },
    {
        path: "/cabinets",
        name: "CabinetsPage",
        component: CabinetsPage,
        meta: { title: "Кабинеты" },
    },

    {
        path: "/projects",
        name: "ProjectsPage",
        component: ProjectsPage,
        meta: { title: "Юридические данные" },
    },
    {
        path: "/project",
        name: "ProjectPage",
        component: ProjectPage,
        meta: { title: "Клиенты в проекте" },
    },
    {
        path: "/clients",
        name: "ClientsPage",
        component: ClientsPage,
        meta: { title: "Клиенты без проекта" },
    },
    {
        path: "/archive",
        name: "ArchivePage",
        component: ArchivePage,
        meta: { title: "Архив" },
    },
    {
        path: "/ads",
        name: "AdsPage",
        component: AdsPage,
        meta: { title: "Реклама" },
    },
    {
        path: "/erid",
        name: "EridPage",
        component: EridPage,
        meta: { title: "Erid" },
    },
    {
        path: "/ord",
        name: "OrdMain",
        component: OrdMain,
        meta: { title: "ОРД" },
    },
    {
        path: "/myreport",
        name: "MyReport",
        component: MyReport,
        meta: { title: "Мой отчет" },
    },
    {
        path: "/myclients",
        name: "MyClients",
        component: MyClients,
        meta: { title: "Мои клиенты" },
    },
    {
        path: "/myads",
        name: "MyAds",
        component: MyAds,
        meta: { title: "Мои объявления" },
    },
    {
        path: "/myerid",
        name: "MyErid",
        component: MyErid,
        meta: { title: "Без ERID" },
    },
    {
        path: "/stat",
        name: "MainStat",
        component: MainStat,
        meta: { title: "Статистика" },
    },
    {
        path: "/stat2023",
        name: "MainStat2023",
        component: MainStat2023,
        meta: { title: "Статистика 2023" },
    },
    {
        path: "/presentation",
        name: "PresentationStat",
        component: PresentationStat,
        meta: { title: "Статистика" },
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "VK";

    if (
        // make sure the user is authenticated
        !authService.isLogged() &&
        // Avoid an infinite redirect
        to.name !== "Login" &&
        to.name !== "Main"
    ) {
        // redirect the user to the login page
        // this.$router.push('/')
        return { name: "Login" };
    }
    window.scrollTo(0, 0);
});

export default router;
