<template>
    <div class="w-1/2 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-200px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-sky-600 text-white">АКТ</h3>

        <div class="flex flex-col p-8 pb-32 text-left h-full overflow-auto">
            <div class="flex">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Договор акта</label>
                    <div>{{ act.contract_serial }}</div>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Номер акта</label>
                    <div>{{ act.serial }}</div>
                </div>
            </div>

            <div class="flex">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Роль заказчика</label>
                    <div v-if="act.client_role == 'advertiser'">Рекламодатель</div>
                    <div v-if="act.client_role == 'agency'">Агентство</div>
                    <div v-if="act.client_role == 'ors'">Рекламная система</div>
                    <div v-if="act.client_role == 'publisher'">Издатель</div>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Роль исполнителя</label>
                    <div v-if="act.contractor_role == 'advertiser'">Рекламодатель</div>
                    <div v-if="act.contractor_role == 'agency'">Агентство</div>
                    <div v-if="act.contractor_role == 'ors'">Рекламная система</div>
                    <div v-if="act.contractor_role == 'publisher'">Издатель</div>
                </div>
            </div>

            <div class="flex">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Заказчик</label>
                    <div>{{ act.agent_name }}</div>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Исполнитель</label>
                    <div>{{ act.contractor_name }}</div>
                </div>
            </div>

            <div class="flex">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Дата выставления</label>
                    <div>{{ moment(act.date).format("DD.MM.YYYY") }}</div>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Сумма (₽)</label>
                    <div>{{ act.amount }}</div>
                </div>
            </div>

            <div class="flex">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Дата начала периода</label>
                    <div>{{ moment(act.date_start).format("DD.MM.YYYY") }}</div>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Дата окончания периода</label>
                    <div>{{ moment(act.date_end).format("DD.MM.YYYY") }}</div>
                </div>
            </div>

            <div v-if="act.deallocation">
                <p class="text-xl my-4">Разаллокация</p>
                <div v-for="item in JSON.parse(act.deallocation)" :key="item" class="flex">
                    <div class="w-1/2 flex flex-col mb-4">
                        <label class="w-full text-xs text-slate-400 mb-1">Изначальный договор с рекламодателем</label>
                        <div v-for="contract in contracts" :key="contract" class="flex">
                            <div v-if="contract.external_id == item.id">{{ contract.serial }}</div>
                        </div>
                    </div>
                    <div class="w-1/2 flex flex-col mb-4">
                        <label class="w-full text-xs text-slate-400 mb-1">Сумма (₽)</label>
                        <div>{{ parseFloat(item.amount) }}</div>
                    </div>
                </div>
            </div>

            <hr />
            <p class="text-left text-sm text-slate-400 my-2">Дополнительно</p>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Статус</label>
                <div v-if="act.status == 1" class="text-slate-400">Создан</div>
                <div v-if="act.status == 2" class="text-slate-400">Проверен</div>
                <div v-if="act.status == 3" class="text-slate-400">Отклонен</div>
                <div v-if="act.status == 7" class="text-slate-400">Отправлен в ОРД</div>
            </div>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Внешний ID</label>
                <div class="text-slate-400">{{ act.external_id }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "ViewAct",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "contracts", "act"]),
    },

    methods: {
        ...mapActions(["verifyAct", "closePopup", "pickAct"]),
    },
};
</script>
