import API from "../../services/api";
import router from "../../services/router";

export default {
    state: {
        cabinets: [],
        cabinetsF: [],
        cabinet: {},

        projects: [],
        projectsF: [],
        project: {},

        clients: [],
        clientsF: [],
        clientsWrong: [],
        client: {},

        archive: [],
        archiveF: [],

        erids: [],
    },

    mutations: {
        setCabinets(state, cabinets) {
            state.cabinets = cabinets;
            state.cabinetsF = cabinets;
        },
        setCabinetsF(state, cabinets) {
            state.cabinetsF = cabinets;
        },
        setCabinet(state, cabinet) {
            localStorage.setItem("cabinet", JSON.stringify(cabinet));
            state.cabinet = cabinet;
        },

        setProjects(state, projects) {
            state.projects = projects;
            state.projectsF = projects;
        },
        setProjectsF(state, projects) {
            state.projectsF = projects;
        },
        setProject(state, project) {
            localStorage.setItem("project", JSON.stringify(project));
            state.project = project;
        },

        setClients(state, clients) {
            state.clients = clients;
            state.clientsF = clients;
        },
        setClientsF(state, clients) {
            state.clientsF = clients;
        },
        setClientsWrong(state, clients) {
            state.clientsWrong = clients;
        },
        setClient(state, client) {
            localStorage.setItem("client", JSON.stringify(client));
            state.client = client;
        },

        setArchiveClients(state, archive) {
            state.archive = archive;
            state.archiveF = archive;
        },
        setArchiveClientsF(state, archive) {
            state.archiveF = archive;
        },

        setErids(state, erids) {
            state.erids = erids;
        },
    },

    actions: {
        getCabinets(ctx) {
            if (!ctx.state.cabinets || ctx.state.cabinets?.length < 1) {
                ctx.dispatch("request", true);
                API.GET("doc/cabinets").then((res) => {
                    console.log("getCabinets", res.data);
                    if (res.data.prohibition) return ctx.dispatch("logout");
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                    ctx.commit("setCabinets", res.data.cabinets);
                    ctx.dispatch("filterCabinets");
                    ctx.dispatch("request", false);
                });
            }
        },

        getLimits(ctx) {
            ctx.dispatch("minirequest", true);
            API.GET("doc/limits").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setCabinets", res.data.cabinets);
                ctx.dispatch("filterCabinets");
                ctx.dispatch("minirequest", false);
            });
        },

        load(ctx) {
            if (!ctx.state.project || isNaN(parseInt(ctx.state.project.id)) || parseInt(ctx.state.project.id) < 1) {
                let project = JSON.parse(localStorage.getItem("project"));
                if (project && project.id > 0) ctx.commit("setProject", project);
            }
            if (!ctx.state.client || isNaN(parseInt(ctx.state.client.id)) || parseInt(ctx.state.client.id) < 1) {
                let client = JSON.parse(localStorage.getItem("client"));
                if (client && client.id > 0) ctx.commit("setClient", client);
            }
        },

        async getProjects(ctx) {
            // console.log("getProjects", Math.floor(Date.now() / 1000) - 1694800000);
            if (!ctx.state.projects || ctx.state.projects?.length < 1) {
                console.log("getProjects скачать");
                ctx.dispatch("request", true);
                await API.GET("doc/projects").then((res) => {
                    console.log("getProjects", res.data);

                    if (res.data.prohibition) return ctx.dispatch("logout");

                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);

                    if (res.data.projects) {
                        ctx.commit("setProjects", res.data.projects);
                        ctx.dispatch("filterProjects");
                    }
                    if (res.data.clients) {
                        ctx.commit("setClients", res.data.clients);
                        ctx.dispatch("filterClients");
                    }

                    ctx.dispatch("getUsers");

                    ctx.dispatch("request", false);
                });
            }
        },

        // проверить что клиент выбран
        isProject(ctx) {
            console.log("isProject");
            if (!ctx.state.project || isNaN(parseInt(ctx.state.project.id)) || parseInt(ctx.state.project.id) < 1) {
                console.log("isProject скачать");
                ctx.dispatch("load");
                if (!ctx.state.project) {
                    router.push("/projects");
                    ctx.dispatch("setActiveMemu", "/projects");
                    return;
                }
                if (isNaN(parseInt(ctx.state.project.id))) {
                    router.push("/projects");
                    ctx.dispatch("setActiveMemu", "/projects");
                    return;
                }
                if (parseInt(ctx.state.project.id) < 1) {
                    router.push("/projects");
                    ctx.dispatch("setActiveMemu", "/projects");
                    return;
                }
                ctx.dispatch("pickProject", ctx.state.project.id);
            }
        },

        getArchive(ctx) {
            if (!ctx.state.archive || ctx.state.archive?.length < 1) {
                ctx.dispatch("request", true);
                API.GET("doc/archive").then((res) => {
                    console.log("getArchive", res.data);
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                    ctx.commit("setArchiveClients", res.data.archive);
                    ctx.dispatch("request", false);
                });
            }
        },

        getErids(ctx) {
            ctx.dispatch("request", true);
            API.GET("doc/erids").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setErids", res.data.erids);
                ctx.dispatch("request", false);
            });
        },

        checkErids(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);
            API.POST("doc/check-erids", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },

        getClientsWrong(ctx) {
            ctx.dispatch("request", true);
            API.GET("doc/clients-wrong").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setClientsWrong", res.data.clients);
                ctx.dispatch("request", false);
            });
        },

        filterProjects(ctx) {
            if (!ctx.state.projects) return null;

            ctx.dispatch("filterList", ctx.state.projects).then((res) => {
                ctx.commit("setProjectsF", res);
            });

            // if (!ctx.state.projects) {
            //     return null;
            // }
            // let list = ctx.state.projects;
            // if (s.findme != "" && s.findme != undefined) {
            //     list = list.filter((item) => {
            //         let id = null;
            //         let name = null;
            //         let client = null;
            //         let code = null;
            //         id = item.id.toString().indexOf(s.findme.toLowerCase()) !== -1;
            //         if (item.name) {
            //             name = item.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
            //         }
            //         if (item.cname) {
            //             client = item.cname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
            //         }
            //         if (item.code) {
            //             code = item.code.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
            //         }
            //         if (id || name || client || code) {
            //             return true;
            //         }
            //         return false;
            //     });
            // }

            // ctx.commit("setProjectsF", list);
            // ctx.dispatch("setSettings", s);
        },

        filterCabinets(ctx) {
            if (!ctx.state.cabinets) return null;

            ctx.dispatch("filterList", ctx.state.cabinets).then((res) => {
                ctx.commit("setCabinetsF", res);
            });
        },

        filterClients(ctx) {
            if (!ctx.state.clients) return null;

            ctx.dispatch("filterList", ctx.state.clients).then((res) => {
                ctx.commit("setClientsF", res);
            });
        },

        pickProject(ctx, id) {
            let project = ctx.state.projects.filter((item) => parseInt(item.id) == parseInt(id));
            if (!project || project == "" || project[0].id < 1) {
                ctx.commit("setClientsF", null);
                router.push("/projects");
                ctx.dispatch("setActiveMemu", "/projects");
                return;
            }

            ctx.commit("setProject", project[0]);

            let clients = ctx.state.clients.filter((item) => parseInt(item.project_id) == parseInt(project[0].id));
            ctx.commit("setClientsF", clients);
        },

        unPickProject(ctx) {
            ctx.commit("setProject", {});
            ctx.commit("setClientsF", ctx.state.clients);
        },

        editProject(ctx, project) {
            ctx.commit("setProject", project);
            ctx.dispatch("setPopup", "editProject");
        },

        viewProject(ctx, project) {
            ctx.commit("setProject", project);
            ctx.dispatch("setPopup", "viewProject");
        },

        createProject(ctx) {
            API.GET("doc/create-project").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setProjects", res.data.projects);
                let project = ctx.state.projects.filter((item) => parseInt(item.id) == parseInt(res.data.project.id));
                ctx.commit("setProject", project[0]); // чтобы данные проекта менялись в списке проектов
                ctx.dispatch("setPopup", "editProject");
                ctx.dispatch("request", false);
            });
        },

        saveProject(ctx, data) {
            if (!data.id) return null;

            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("name", data.name);
            formData.append("faqid", data.faqid);
            formData.append("date", data.date);
            formData.append("client_type", data.client_type);
            formData.append("client_name", data.client_name);
            formData.append("client_short", data.client_short);
            formData.append("address", data.address);
            formData.append("inn", data.inn);
            formData.append("ogrn", data.ogrn);
            formData.append("email", data.email);
            formData.append("phone", data.phone);
            formData.append("phone2", data.phone2);
            formData.append("phone3", data.phone3);

            formData.append("contact_name", data.contact_name);
            formData.append("contact_tl", data.contact_tl);
            formData.append("contact_phone", data.contact_phone);
            formData.append("contact_email", data.contact_email);
            formData.append("okved", data.okved);
            formData.append("contract_number", data.contract_number);
            formData.append("contract_date", data.contract_date);
            formData.append("contract_type", data.contract_type);
            formData.append("contract_object", data.contract_object);
            formData.append("with_vat", data.with_vat);

            formData.append("subagent_type", data.subagent_type);
            formData.append("subagent_name", data.subagent_name);
            formData.append("subagent_inn", data.subagent_inn);
            formData.append("subagent_phone", data.subagent_phone);

            API.POST("doc/update-project", formData).then((res) => {
                console.log("getProjects", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setProjects", res.data.projects);
                ctx.commit("setProject", res.data.project);
                ctx.commit("setClients", res.data.clients);
            });
        },

        sendClientInfo(ctx, client) {
            if (!client.id) return null;

            console.log("sendClientInfo", client);

            let formData = new FormData();
            formData.append("id", client.id);
            formData.append("project_id", client.project_id);

            API.POST("doc/send-client-info", formData).then((res) => {
                console.log("sendClientInfo", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setClients", res.data.clients);

                let clientsF = ctx.state.clients.filter((item) => parseInt(item.project_id) == parseInt(client.project_id));
                ctx.commit("setClientsF", clientsF);
            });
        },

        setSubagent(ctx, client) {
            client.subagent = !client.subagent;

            let formData = new FormData();
            formData.append("id", client.id);
            if (client.subagent) formData.append("subagent", client.subagent);

            API.POST("doc/set-subagent", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setClient", res.data.client);
            });
        },

        // перепроверить все проекты
        checkAll(ctx) {
            API.GET("doc/check").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setProjects", res.data.projects);
                ctx.commit("setClients", res.data.clients);
            });
        },

        addToProject(ctx, data) {
            console.log("addToProject", data);

            let formData = new FormData();
            formData.append("project_id", data.project_id);
            formData.append("ids", data.ids);

            API.POST("doc/add-to-project-clients", formData).then((res) => {
                console.log("addToProject", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setProjects", res.data.projects);
                ctx.commit("setClients", res.data.clients);
                ctx.commit("setCheckedIds", []);
            });
        },

        unProjectClient(ctx, ids) {
            let formData = new FormData();
            formData.append("ids", ids);

            API.POST("doc/un-project-client", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setProjects", res.data.projects);
                ctx.commit("setClients", res.data.clients);
                ctx.commit("setCheckedIds", []);
            });
        },

        // привязать клиента к таргетологу
        addTargetologClient(ctx, data) {
            ctx.dispatch("request", true);

            let formData = new FormData();
            formData.append("targetolog_id", data.targetolog_id);
            formData.append("ids", data.ids);

            API.POST("doc/add-targetolog-client", formData).then((res) => {
                console.log("addTargetologClient", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setClients", res.data.clients);
                ctx.commit("setCheckedIds", []);
                ctx.dispatch("filterClients");
                ctx.dispatch("request", false);
            });
        },

        // отвязать клиента от таргетолога
        unTargetologClient(ctx, ids) {
            ctx.dispatch("request", true);

            let formData = new FormData();
            formData.append("ids", ids);

            API.POST("doc/un-targetolog-client", formData).then((res) => {
                console.log("unTargetologClient", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setClients", res.data.clients);
                ctx.commit("setCheckedIds", []);
                ctx.dispatch("filterClients");
                ctx.dispatch("request", false);
            });
        },
    },

    getters: {
        cabinets(state) {
            return state.cabinetsF;
        },
        cabinet(state) {
            return state.cabinet;
        },

        projects(state) {
            return state.projectsF;
        },
        project(state) {
            return state.project;
        },

        clients(state) {
            return state.clientsF;
        },
        clientsWrong(state) {
            return state.clientsWrong;
        },
        client(state) {
            return state.client;
        },

        archive(state) {
            return state.archiveF;
        },

        erids(state) {
            return state.erids;
        },
    },
};
