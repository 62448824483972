<template>
    <template v-if="!s.request">
        <div v-if="legend" class="w-96 h-fit fixed z-50 left-1/2 -translate-x-1/2 top-10 p-4 bg-pink-600/80 text-white text-sm text-center rounded">
            <div>ПОДСКАЗКА ОБ ОШИБКЕ</div>

            <div v-if="legend[0] == 1">
                <div v-if="currentClient.client_type">
                    <div v-if="currentClient.client_type == 'person'">Физическое лицо</div>
                    <div v-if="currentClient.client_type == 'individual'">Индивидуальный предприниматель</div>
                    <div v-if="currentClient.client_type == 'legal'">Юридическое лицо</div>
                </div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[1] == 1">
                <div v-if="currentClient.client_name">{{ currentClient.client_name }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[2] == 1">
                <div v-if="currentClient.contract_number">{{ currentClient.contract_number }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[3] == 1">
                <div v-if="currentClient.contract_date">{{ currentClient.contract_date }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[4] == 1">
                <div v-if="currentClient.contract_type">{{ currentClient.contract_type }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[5] == 1">
                <div v-if="currentClient.contract_object">{{ currentClient.contract_object }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[6] == 1">
                <div v-if="currentClient.inn">{{ currentClient.inn }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[7] == 1">
                <div v-if="currentClient.phone">{{ currentClient.phone }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[8] == 1">
                <div v-if="currentClient.subagent_type">
                    <div v-if="currentClient.subagent_type == 'person'">Физическое лицо</div>
                    <div v-if="currentClient.subagent_type == 'individual'">Индивидуальный предприниматель</div>
                    <div v-if="currentClient.subagent_type == 'legal'">Юридическое лицо</div>
                </div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[9] == 1">
                <div v-if="currentClient.subagent_name">{{ currentClient.subagent_name }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[10] == 1">
                <div v-if="currentClient.subagent_inn">{{ currentClient.subagent_inn }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[11] == 1">
                <div v-if="currentClient.subagent_phone">{{ currentClient.subagent_phone }}</div>
                <div v-else>Нет</div>
            </div>
        </div>

        <!-- <div class="flex justify-between items-center h-20 mb-4 gap-4">
            <div class="shrink-0 text-sm text-slate-500 uppercase cursor-pointer">КАБИНЕТЫ</div>
            <input type="text" class="input" placeholder="Поиск проекта" v-model="s.findme" @input="findProject(s)" />
        </div> -->

        <!-- 
            - обновить лимит (отдельно)
            - обновить список клиентов
            - обновить список кампаний
            - обновить список объявлений
            - обновить открут (статистика)
            - "задача в обработке"
            - войти в список (клиентов, кампаний?, объявлений?)
            - ночью проверять клиентов в статусе 0 и кампании в статусе 0 = чтобы выключить неиспользуемые кампани  и объявления 
         -->

        <!-- <div class="flex justify-between items-center my-4 gap-4">
            <div class="shrink-0 text-sm text-slate-500 uppercase">
                КАБИНЕТЫ <span class="count">{{ cabinets?.length }}</span>
            </div>

            <template v-if="['Or', 'Bs'].includes(profile.role)">
                <div
                    v-if="s.minirequest == false"
                    @click="getLimits()"
                    class="h-8 px-3 flex justify-center items-center gap-2 bg-orange-400 rounded cursor-pointer"
                >
                    <span class="text-white my-2"><IconRe :size="4" /></span>
                    <span class="text-white text-xs">ПРОВЕРИТЬ ЛИМИТЫ</span>
                </div>
                <div v-else class="btn btn-green shrink-0 w-48 cursor-pointer gap-2"><IconLoader :size="6" /> ПРОВЕРЯЕМ ...</div>
            </template>
        </div> -->

        <!-- <div class="bg-white rounded mb-8">
            <div class="grid grid-cols-15 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                <div class="col-span-2 px-4 text-sm text-sky-800">VK ADS НОВЫЙ</div>
                <div class="col-span-3">НАЗВАНИЕ</div>

                <div class="col-span-2 text-center">КЛИЕНТЫ</div>
                <div class="col-span-2 text-center">КАМПАНИИ</div>
                <div class="col-span-2 text-center">ОБЪЯВЛЕНИЯ</div>
                <div class="text-center">ERID</div>

                <div class="text-right">ЛИМИТ</div>
                <div class="text-right">МИНУТ</div>
                <div class="text-right">СТАТУС</div>
            </div>

            <template v-for="item in total" :key="item.id">
                <template v-if="item.channel_id == 2">
                    <div
                        class="grid grid-cols-15 items-center gap-4 h-13 border-b border-slate-200 text-sm hover:bg-sky-50/50"
                        :class="{ '!bg-gray-100 py-3': !item.id }"
                    >
                        <div class="flex justify-between items-center gap-4 px-4">
                            <a
                                v-if="item.id"
                                class="text-2xl text-sky-700 font-bold text-right tabular-nums cursor-pointer"
                                target="_blank"
                                href="https://ads.vk.com/hq/dashboard"
                            >
                                <IVkAds :size="6" />
                            </a>
                        </div>

                        <div>{{ item.label }}</div>

                        <div class="col-span-3 p-2 border-r border-slate-200">
                            <div class="text-xs text-slate-400">{{ item.id }}</div>
                            {{ item.name }}
                        </div>

                        <div
                            class="col-span-2 text-center"
                            :class="{ '!opacity-50': !item.id }"
                            v-if="item.clients_active > 0 || item.clients_all > 0"
                        >
                            <span class="text-sky-800 font-bold"> {{ parseInt(item.clients_active).toLocaleString() }} </span>
                            <span class="text-sky-800/50"> / {{ parseInt(item.clients_all).toLocaleString() }}</span>
                        </div>
                        <div class="col-span-2" v-else></div>

                        <div
                            class="col-span-2 text-center"
                            :class="{ '!opacity-50': !item.id }"
                            v-if="item.campaigns_active > 0 || item.campaigns_all > 0"
                        >
                            <span class="text-sky-800 font-bold"> {{ parseInt(item.campaigns_active).toLocaleString() }} </span>
                            <span class="text-sky-800/50"> / {{ parseInt(item.campaigns_all).toLocaleString() }}</span>
                        </div>
                        <div class="col-span-2" v-else></div>

                        <div class="col-span-2 text-center" :class="{ '!opacity-50': !item.id }" v-if="item.ads_active > 0 || item.ads_all > 0">
                            <span class="text-sky-800 font-bold"> {{ parseInt(item.ads_active).toLocaleString() }} </span>
                            <span class="text-sky-800/50"> / {{ parseInt(item.ads_all).toLocaleString() }}</span>
                        </div>
                        <div class="col-span-2" v-else></div>

                        <div
                            v-if="item.erid > 0"
                            class="flex justify-center items-center text-center text-sky-600 font-bold h-full border-r border-slate-200"
                            :class="{ '!opacity-50': !item.id }"
                        >
                            {{ parseInt(item.erid).toLocaleString() }}
                        </div>
                        <div class="h-full border-r border-slate-200" v-else></div>

                        <div v-if="item.limit > 0" class="text-right">{{ parseInt(item.limit).toLocaleString() ?? 0 }}</div>
                        <div class="text-right text-pink-400" v-else-if="item.id">0</div>
                        <div v-else></div>

                        <div v-if="item.refresh > 0" class="text-right text-slate-400">{{ parseInt(item.refresh / 60) }} м.</div>
                        <div class="text-right text-slate-400" v-else-if="item.id">0 м.</div>
                        <div v-else></div>

                        <div>
                            <div class="flex justify-center items-center" v-if="item.status == 1">
                                <span class="circle w-3 h-3 bg-teal-500"></span>
                            </div>
                            <div class="flex justify-center items-center" v-if="item.status == 0"><span class="circle w-3 h-3"></span></div>
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <div class="rounded mb-8">
            <div class="grid grid-cols-8 items-center bg-sky-700/20 text-xs text-sky-800/50 gap-4 p-2 rounded uppercase">
                <div class="grid grid-cols-2 items-center gap-4 col-span-2">
                    <div class="px-4 text-sm text-sky-800">VK СТАРЫЙ</div>
                    <div>НАЗВАНИЕ</div>
                </div>
                <div class="grid grid-cols-4 items-center gap-4 col-span-5">
                    <div class="text-center">КЛИЕНТЫ</div>
                    <div class="text-center">КАМПАНИИ</div>
                    <div class="text-center">ОБЪЯВЛЕНИЯ</div>
                    <div class="text-center">ERID</div>
                </div>
                <div class="grid grid-cols-3 items-center gap-4">
                    <div class="text-right">ЛИМИТ</div>
                    <div class="text-right">МИНУТ</div>
                    <div class="text-right">СТАТУС</div>
                </div>
            </div>

            <template v-for="item in total" :key="item.id">
                <template v-if="item.channel_id == 1 && item.status == 1">
                    <div
                        v-if="item.id"
                        class="grid grid-cols-8 items-center bg-white rounded gap-4 h-14 mt-2 text-sm hover:bg-sky-50/50 shadow-sm"
                        :class="{ '!bg-sky-800/80 !h-10': !item.id }"
                    >
                        <div class="grid grid-cols-4 gap-4 items-center col-span-2">
                            <div class="flex justify-between items-center gap-4 px-4">
                                <a
                                    v-if="item.id"
                                    class="text-2xl text-sky-700 hover:text-sky-600 font-bold cursor-pointer"
                                    target="_blank"
                                    :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                                >
                                    <IVk />
                                </a>
                            </div>

                            <div class="col-span-2 p-2">
                                <div class="text-xs text-slate-400">{{ item.id }}</div>
                                {{ item.name }}
                            </div>

                            <div v-if="item.label" class="rounded-r-full rounded-tl-full bg-sky-700 py-1 px-3 w-fit text-center text-white">
                                {{ item.label }}
                            </div>
                            <div v-else-if="item.id" class="rounded-r-full rounded-tl-full bg-sky-700/20 w-10 h-6"></div>
                            <div v-else></div>
                        </div>

                        <div class="grid grid-cols-4 items-center h-14 border-l border-r border-slate-200 col-span-5">
                            <div class="flex justify-center gap-4 text-center" v-if="item.clients_active > 0 || item.clients_all > 0">
                                <span class="text-sky-800"><IVkUser /> </span>
                                <span class="text-sky-800 font-bold">
                                    {{ parseInt(item.clients_active).toLocaleString() }}
                                </span>
                                <span class="text-sky-800/50"> / {{ parseInt(item.clients_all).toLocaleString() }}</span>
                            </div>
                            <div class="" v-else></div>

                            <div class="text-center" v-if="item.campaigns_active > 0 || item.campaigns_all > 0">
                                <span class="text-sky-800 font-bold">
                                    {{ parseInt(item.campaigns_active).toLocaleString() }}
                                </span>
                                <span class="text-sky-800/50"> / {{ parseInt(item.campaigns_all).toLocaleString() }}</span>
                            </div>
                            <div class="" v-else></div>

                            <div class="text-center" v-if="item.ads_active > 0 || item.ads_all > 0">
                                <span class="text-sky-800 font-bold">
                                    {{ parseInt(item.ads_active).toLocaleString() }}
                                </span>
                                <span class="text-sky-800/50"> / {{ parseInt(item.ads_all).toLocaleString() }}</span>
                            </div>
                            <div class="" v-else></div>

                            <div v-if="item.erid > 0" class="flex justify-center items-center text-center text-sky-800 font-bold">
                                {{ parseInt(item.erid).toLocaleString() }}
                            </div>
                            <div v-else></div>
                        </div>

                        <div class="grid grid-cols-3 items-center h-14">
                            <div v-if="item.limit > 0" class="text-right">{{ parseInt(item.limit).toLocaleString() ?? 0 }}</div>
                            <div class="text-right text-pink-400" v-else-if="item.id">0</div>
                            <div v-else></div>

                            <div v-if="item.refresh > 0" class="text-right text-slate-400">{{ parseInt(item.refresh / 60) }} м.</div>
                            <div class="text-right text-slate-400" v-else-if="item.id">0 м.</div>
                            <div v-else></div>

                            <div>
                                <div class="flex justify-center items-center" v-if="item.status == 1">
                                    <span class="circle w-3 h-3 bg-teal-500"></span>
                                </div>
                                <div class="flex justify-center items-center" v-if="item.status == 0"><span class="circle w-3 h-3"></span></div>
                            </div>
                        </div>
                    </div>

                    <div v-else class="grid grid-cols-8 items-center gap-4 h-10 text-sm">
                        <div class="col-span-2"></div>

                        <div class="grid grid-cols-4 items-center h-10 bg-sky-700/20 rounded-b col-span-5">
                            <div class="flex justify-center gap-4 text-center" v-if="item.clients_active > 0 || item.clients_all > 0">
                                <span class="text-sky-800 font-bold">
                                    {{ parseInt(item.clients_active).toLocaleString() }}
                                </span>
                                <span class="text-sky-800/50"> / {{ parseInt(item.clients_all).toLocaleString() }}</span>
                            </div>

                            <div class="text-center" v-if="item.campaigns_active > 0 || item.campaigns_all > 0">
                                <span class="text-sky-800 font-bold">
                                    {{ parseInt(item.campaigns_active).toLocaleString() }}
                                </span>
                                <span class="text-sky-800/50"> / {{ parseInt(item.campaigns_all).toLocaleString() }}</span>
                            </div>

                            <div class="text-center" v-if="item.ads_active > 0 || item.ads_all > 0">
                                <span class="text-sky-800 font-bold">
                                    {{ parseInt(item.ads_active).toLocaleString() }}
                                </span>
                                <span class="text-sky-800/50"> / {{ parseInt(item.ads_all).toLocaleString() }}</span>
                            </div>

                            <div v-if="item.erid > 0" class="flex justify-center items-center text-center text-sky-800 font-bold">
                                {{ parseInt(item.erid).toLocaleString() }}
                            </div>
                        </div>

                        <div></div>
                    </div>
                </template>
            </template>
        </div> -->

        <div class="grid grid-cols-3 items-start gap-8 mb-8">
            <div v-if="total">
                <div class="grid grid-cols-7 items-center gap-8 mb-8 w-full p-4 bg-white rounded-lg shadow-sm cursor-pointer">
                    <span class="text-sky-800"><IVkUser :size="12" /></span>

                    <div class="flex flex-col items-center col-span-2 cursor-pointer" @click="getClientsWrong()">
                        <span class="text-pink-500 font-latobold text-2xl"> {{ parseInt(total.clients_wrong).toLocaleString() }} </span>
                        <span class="text-xs text-pink-400 uppercase">ЮР.ДАННЫЕ</span>
                    </div>

                    <div class="flex flex-col items-center col-span-2">
                        <span class="text-sky-800 font-latobold text-2xl"> {{ parseInt(total.clients_active).toLocaleString() }} </span>
                        <span class="text-xs text-sky-700 uppercase">АКТИВНЫЕ</span>
                    </div>

                    <div class="flex flex-col items-center col-span-2">
                        <span class="text-slate-400 font-latobold text-2xl"> {{ parseInt(total.clients_all).toLocaleString() }} </span>
                        <span class="text-xs text-slate-400 uppercase">ВСЕГО</span>
                    </div>

                    <!-- <div @click="parseAllClients()" class="h-8 px-3 flex justify-center items-center gap-2 bg-orange-400 rounded cursor-pointer">
                        <span class="text-white my-2"><IconRe :size="4" /></span>
                    </div> -->
                </div>

                <div class="grid grid-cols-7 items-center gap-8 mb-8 w-full p-4 bg-white rounded-lg shadow-sm">
                    <span class="text-sky-800 col-span-3"><IVkCamp :size="12" /></span>

                    <div class="flex flex-col items-center col-span-2">
                        <span class="text-sky-800 font-latobold text-2xl"> {{ parseInt(total.campaigns_active).toLocaleString() }} </span>
                        <span class="text-xs text-sky-700 uppercase">АКТИВНЫЕ</span>
                    </div>

                    <div class="flex flex-col items-center col-span-2">
                        <span class="text-slate-400 font-latobold text-2xl"> {{ parseInt(total.campaigns_all).toLocaleString() }} </span>
                        <span class="text-xs text-slate-400 uppercase">ВСЕГО</span>
                    </div>
                </div>

                <div class="grid grid-cols-7 items-center gap-8 mb-8 w-full p-4 bg-white rounded-lg shadow-sm">
                    <span class="text-sky-800"><IVkAd :size="12" /></span>

                    <div class="flex flex-col items-center col-span-2">
                        <span class="text-pink-500 font-latobold text-2xl">
                            {{ (parseInt(total.ads_active) - parseInt(total.erid)).toLocaleString() }}
                        </span>
                        <span class="text-xs text-pink-400 uppercase">БЕЗ ERID</span>
                    </div>

                    <div class="flex flex-col items-center col-span-2">
                        <span class="text-sky-800 font-latobold text-2xl"> {{ parseInt(total.ads_active).toLocaleString() }} </span>
                        <span class="text-xs text-sky-700 uppercase">АКТИВНЫЕ</span>
                    </div>

                    <div class="flex flex-col items-center col-span-2">
                        <span class="text-slate-400 font-latobold text-2xl"> {{ parseInt(total.ads_all).toLocaleString() }} </span>
                        <span class="text-xs text-slate-400 uppercase">ВСЕГО</span>
                    </div>
                </div>
            </div>

            <div v-if="clientsWrong && clientsWrong.length > 0" class="mb-8 col-span-2">
                <div class="flex justify-between item-center mb-4">
                    <h2 class="text-slate-500">Клиенты с некорретными юр. данными и открутом в этом месяце</h2>
                    <div class="text-slate-500 hover:text-pink-500 cursor-pointer" @click="setClientsWrong(null)"><IconClose /></div>
                </div>
                <div
                    v-for="item in clientsWrong"
                    :key="item.id"
                    class="h-12 overflow-hidden grid grid-cols-14 items-center gap-4 bg-white rounded mb-1 px-4 text-xs hover:bg-cyan-50/50 hover:!text-cyan-700 cursor-pointer"
                    @click="pickProject(item.project_id), $router.push('/project')"
                >
                    <div>
                        <div
                            @mouseover="(legend = item.wrong), (currentClient = item)"
                            @mouseleave="(legend = ''), (currentClient = {})"
                            v-if="item.wrong > 0"
                            class="text-pink-600"
                        >
                            <IconWarning :size="8" />
                        </div>
                    </div>

                    <div class="flex items-center gap-4 col-span-7">
                        <a
                            v-if="item.channel_id == 1"
                            class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer"
                            target="_blank"
                            :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                        >
                            <IVk />
                        </a>
                        <a
                            v-if="item.channel_id == 2"
                            class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer p-1"
                            target="_blank"
                            href="https://ads.vk.com/hq/dashboard"
                        >
                            <IVkAds :size="6" />
                        </a>
                        <div class="text-center">{{ item.label }}</div>

                        <div>
                            <span class="text-xs text-slate-400">{{ item.id }}</span>
                            <div class="text-xs">{{ item.name }}</div>
                        </div>
                    </div>

                    <div class="col-span-2">{{ item.tname }} {{ item.tsecondname }}</div>

                    <div class="col-span-3 cursor-pointer">{{ item.project }}</div>

                    <div>
                        <div class="flex items-center gap-2" v-if="item.status == 1"><span class="circle bg-teal-500"></span></div>
                        <div class="flex items-center gap-2" v-if="item.status == 0"><span class="circle bg-pink-500"></span></div>
                        <div class="flex items-center gap-2" v-if="item.status == 8"><span class="circle"></span></div>
                    </div>
                </div>
            </div>

            <div v-else class="grid grid-cols-2 gap-8 mb-8 col-span-2">
                <div class="grid grid-cols-6 items-center gap-4 col-span-2">
                    <div class="shrink-0 text-sm gap-2 text-slate-500 uppercase col-span-3">
                        КАБИНЕТЫ <span class="count ml-2">{{ cabinets?.length }}</span>
                    </div>

                    <select v-model="s.channel_id" class="bg-white h-9 px-3 py-2 rounded text-sm text-sky-800" @change="filterCabinets()">
                        <template v-for="s_ch in select.channels" :key="s_ch.id">
                            <option v-if="s.channel_id == s_ch.id" selected :value="s_ch.id">{{ s_ch.name }}</option>
                            <option v-else :value="s_ch.id">{{ s_ch.name }}</option>
                        </template>
                    </select>

                    <select v-model="s.status" class="bg-white h-9 px-3 py-2 rounded text-sm text-sky-800" @change="filterCabinets()">
                        <option :selected="s.status == null" :value="null">ВСЕ</option>
                        <option :selected="s.status == 1" :value="1">АКТИВНЫ</option>
                        <option :selected="s.status == 0" :value="0">ВЫКЛЮЧЕНЫ</option>
                    </select>

                    <template v-if="['Or', 'Bs'].includes(profile.role)">
                        <!-- <div
                            v-if="s.minirequest == false"
                            @click="getLimits()"
                            class="h-9 px-3 flex justify-center items-center gap-2 bg-orange-400 rounded cursor-pointer"
                        >
                            <span class="text-white my-2"><IconRe :size="4" /></span>
                            <span class="text-white text-xs">СТАТИСТИКА</span>
                        </div>
                        <div v-else class="btn shrink-0 w-48 cursor-pointer gap-2"><IconLoader :size="6" /> ПРОВЕРЯЕМ ...</div> -->

                        <div
                            v-if="s.minirequest == false"
                            @click="getLimits()"
                            class="h-9 px-3 flex justify-center items-center gap-2 bg-cyan-500 rounded cursor-pointer"
                        >
                            <span class="text-white my-2"><IconRe :size="4" /></span>
                            <span class="text-white text-xs">ЛИМИТЫ</span>
                        </div>
                        <div v-else class="btn shrink-0 w-48 cursor-pointer gap-2"><IconLoader :size="6" /> ПРОВЕРЯЕМ ...</div>
                    </template>
                </div>

                <template v-for="item in cabinets" :key="item.id">
                    <div v-if="item?.clients_active > 0" class="flex flex-col items-center">
                        <div class="flex flex-col items-center relative w-full bg-white rounded-lg shadow-sm">
                            <div class="absolute top-2 right-2 text-sky-800 cursor-pointer" @click="parseClients(item.id)">
                                <IconDownload :size="5" />
                            </div>

                            <a
                                :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                                target="_blank"
                                v-if="item.channel_id == 1 && item.label"
                                class="flex justify-between items-center gap-2 rounded-r-full rounded-tl-full bg-sky-700 py-1 px-3 mt-8 text-center text-white cursor-pointer"
                            >
                                <span class="text-white"> <IVk /> </span>

                                {{ item.label }}
                            </a>

                            <a
                                href="https://ads.vk.com/hq/dashboard"
                                target="_blank"
                                v-if="item.channel_id == 2 && item.label"
                                class="flex justify-between items-center gap-2 rounded-r-full rounded-tl-full bg-sky-700 py-1 px-3 mt-8 text-center text-white cursor-pointer"
                            >
                                <span class="text-white p-1"> <IVkAds :size="7" /> </span>

                                {{ item.label }}
                            </a>

                            <!-- <div class="text-xs text-slate-400 mt-4">{{ item.id }}</div> -->
                            <div class="text-xs text-slate-400 mt-2">{{ item.name }}</div>

                            <div class="w-full grid grid-cols-3 items-center my-4">
                                <div class="flex flex-col items-center text-sky-800">
                                    <span><IVkUser /></span>
                                    <span class="font-latobold text-2xl"> {{ parseInt(item.clients_active).toLocaleString() }} </span>
                                    <span class="flex justify-between gap-4">
                                        <span class="opacity-50 text-sm" :class="{ '!text-pink-500': parseInt(item.clients_wrong) > 0 }">
                                            {{ item.clients_wrong }}
                                        </span>

                                        <span class="opacity-50 text-sm"> {{ parseInt(item.clients_all).toLocaleString() }} </span>
                                    </span>
                                    <!-- <div class="btn-xs mt-2" @click="parseClients(item.id)">СКАЧАТЬ</div> -->
                                </div>

                                <div class="flex flex-col items-center text-sky-800">
                                    <span><IVkCamp /></span>
                                    <span class="font-latobold text-2xl"> {{ parseInt(item.campaigns_active).toLocaleString() }} </span>
                                    <span class="opacity-50 text-sm"> {{ parseInt(item.campaigns_all).toLocaleString() }}</span>
                                    <!-- <div class="btn-xs mt-2" @click="parseCampaigns(item.id)">ОБНОВИТЬ</div> -->
                                </div>

                                <div class="flex flex-col items-center text-sky-800">
                                    <span><IVkAd /></span>
                                    <span class="font-latobold text-2xl"> {{ parseInt(item.ads_active).toLocaleString() }} </span>
                                    <div class="flex justify-between gap-4">
                                        <span
                                            class="opacity-50 text-sm"
                                            :class="{ '!text-pink-500': parseInt(item.ads_active) - parseInt(item.erid) > 0 }"
                                        >
                                            {{ (parseInt(item.ads_active) - parseInt(item.erid)).toLocaleString() }}
                                        </span>
                                        <span class="opacity-50 text-sm">{{ parseInt(item.ads_all).toLocaleString() }} </span>
                                    </div>
                                    <!-- <div class="btn-xs mt-2" @click="parseAds(item.id)">ОБНОВИТЬ</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="flex gap-4 bg-white py-1 px-4 rounded-b shadow-sm">
                            <div v-if="item.limit > 0" class="text-right">{{ parseInt(item.limit).toLocaleString() ?? 0 }}</div>
                            <div class="text-right text-pink-400" v-else-if="item.id">0</div>
                            <div v-else></div>

                            <div v-if="item.refresh > 0" class="text-right text-slate-400">{{ parseInt(item.refresh / 60) }} м.</div>
                            <div class="text-right text-slate-400" v-else-if="item.id">0 м.</div>
                            <div v-else></div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <!-- <div class="bg-white rounded mb-8">
            <div class="grid grid-cols-8 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                <div class="col-span-2 px-4 text-sm text-sky-800">МАРКЕТ ПЛАТФОРМА</div>
                <div>ID</div>
                <div class="col-span-2">НАЗВАНИЕ</div>
                <div>ЛЕЙБЛ</div>
                <div class="ml-4">СТАТУС</div>
            </div>

            <template v-for="item in total" :key="item.id">
                <template v-if="item.channel_id == 3">
                    <div class="grid grid-cols-8 items-center gap-4 p-2 border-b border-slate-200 text-sm hover:bg-sky-50/50">
                        <a
                            class="text-2xl text-sky-700 font-bold text-right tabular-nums cursor-pointer"
                            target="_blank"
                            :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                        >
                            <IVk />
                        </a>

                        <div>{{ item.id }}</div>

                        <div class="col-span-4 text-sm">{{ item.name }}</div>

                        <div>{{ item.label }}</div>

                        <div class="ml-4">
                            <div class="flex items-center gap-2" v-if="item.status == 1"><span class="circle bg-teal-500"></span> Активный</div>
                            <div class="flex items-center gap-2" v-if="item.status == 0"><span class="circle"></span> Выключен</div>
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <div class="bg-white rounded mb-8">
            <div class="grid grid-cols-8 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                <div class="col-span-2 px-4 text-sm text-sky-800">ЯНДЕКС</div>
                <div>ID</div>
                <div class="col-span-2">НАЗВАНИЕ</div>
                <div>ЛЕЙБЛ</div>
                <div class="ml-4">СТАТУС</div>
            </div>

            <template v-for="item in total" :key="item.id">
                <template v-if="item.channel_id == 4">
                    <div class="grid grid-cols-8 items-center gap-4 p-2 border-b border-slate-200 text-sm hover:bg-sky-50/50">
                        <a
                            class="text-2xl text-sky-700 font-bold text-right tabular-nums cursor-pointer"
                            target="_blank"
                            :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                        >
                            <IVk />
                        </a>

                        <div>{{ item.id }}</div>

                        <div class="col-span-4 text-sm">{{ item.name }}</div>

                        <div>{{ item.label }}</div>

                        <div class="ml-4">
                            <div class="flex items-center gap-2" v-if="item.status == 1"><span class="circle bg-teal-500"></span> Активный</div>
                            <div class="flex items-center gap-2" v-if="item.status == 0"><span class="circle"></span> Выключен</div>
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <div class="bg-white rounded mb-8">
            <div class="grid grid-cols-8 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                <div class="col-span-2 px-4 text-sm text-sky-800">TELEGRAM</div>
                <div>ID</div>
                <div class="col-span-2">НАЗВАНИЕ</div>
                <div>ЛЕЙБЛ</div>
                <div class="ml-4">СТАТУС</div>
            </div>

            <template v-for="item in total" :key="item.id">
                <template v-if="item.channel_id == 5">
                    <div class="grid grid-cols-8 items-center gap-4 p-2 border-b border-slate-200 text-sm hover:bg-sky-50/50">
                        <a
                            class="text-2xl text-sky-700 font-bold text-right tabular-nums cursor-pointer"
                            target="_blank"
                            :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                        >
                            <IVk />
                        </a>

                        <div>{{ item.id }}</div>

                        <div class="col-span-4 text-sm">{{ item.name }}</div>

                        <div>{{ item.label }}</div>

                        <div class="ml-4">
                            <div class="flex items-center gap-2" v-if="item.status == 1"><span class="circle bg-teal-500"></span> Активный</div>
                            <div class="flex items-center gap-2" v-if="item.status == 0"><span class="circle"></span> Выключен</div>
                        </div>
                    </div>
                </template>
            </template>
        </div> -->

        <template v-if="s.popup == 'editProject' && project?.id"><EditProject /></template>
        <template v-if="s.popup == 'viewProject' && project?.id"><ViewProject /></template>
        <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-sky-400"><IconLoader :size="30" /></div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "CabinetsPage",

    data() {
        return {
            moment: moment,
            tip: "",
            legend: "",
            currentClient: {},
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "select", "cabinets", "clientsWrong"]),

        total() {
            if (!this.cabinets) return null;

            let data = {
                clients_active: 0,
                clients_all: 0,
                clients_wrong: 0,
                campaigns_active: 0,
                campaigns_all: 0,
                ads_active: 0,
                ads_all: 0,
                erid: 0,
            };

            this.cabinets.forEach((cabinet) => {
                data.clients_active += cabinet.clients_active;
                data.clients_all += cabinet.clients_all;
                data.clients_wrong += cabinet.clients_wrong;
                data.campaigns_active += cabinet.campaigns_active;
                data.campaigns_all += cabinet.campaigns_all;
                data.ads_active += cabinet.ads_active;
                data.ads_all += cabinet.ads_all;
                data.erid += cabinet.erid;
            });

            return data;
        },
    },

    methods: {
        ...mapActions([
            "getCabinets",
            "closePopup",
            "clearSettings",
            "getLimits",
            "parseAllClients",
            "parseClients",
            "parseCampaigns",
            "parseAds",
            "filterCabinets",
            "getClientsWrong",
            "pickProject",
        ]),

        ...mapMutations(["setClientsWrong"]),
    },

    mounted() {
        this.clearSettings();
        this.getCabinets();
    },
};
</script>
