<template>
    <div class="w-1/2 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-200px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-sky-600 text-white">КОНТРАГЕНТ</h3>

        <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Название</label>
                <div>{{ agent.name }}</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Организационно-правовая форма</label>
                <div v-if="agent.type == 'physical'">Физическое лицо</div>
                <div v-if="agent.type == 'juridical'">Юридическое лицо</div>
                <div v-if="agent.type == 'ip'">ИП</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Роли</label>
                <div v-for="r in JSON.parse(agent.roles)" :key="r">{{ roles[r] }}</div>
            </div>

            <div v-if="JSON.parse(agent.roles).includes('ors')" class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Ссылка на рекламную сеть</label>
                <div>{{ agent.url }}</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">ИНН</label>
                <div>{{ agent.inn }}</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Телефон</label>
                <div>{{ agent.phone }}</div>
            </div>

            <hr />
            <p class="text-left text-sm text-slate-400 my-2">Дополнительно</p>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Статус</label>
                <div v-if="agent.status == 1" class="text-slate-400">Создан</div>
                <div v-if="agent.status == 2" class="text-slate-400">Проверен</div>
                <div v-if="agent.status == 3" class="text-slate-400">Отклонен</div>
                <div v-if="agent.status == 7" class="text-slate-400">Отправлен в ОРД</div>
            </div>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Внешний ID</label>
                <div class="text-slate-400">{{ agent.external_id }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "ViewAgent",

    data() {
        return {
            moment: moment,
            roles: {
                advertiser: "Рекламодатель",
                agency: "Агентство",
                ors: "Рекламная система",
                publisher: "Издатель",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "agent"]),
    },

    methods: {
        ...mapActions(["verifyAgent", "closePopup", "pickAgent"]),
    },
};
</script>
