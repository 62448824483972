<template>
    <div class="w-1/2 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-200px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-red-500 text-white">УДАЛИТЬ КОНТРАГЕНТА</h3>

        <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Название</label>
                <div>{{ agent.name }}</div>
            </div>
            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Организационно-правовая форма</label>
                <div v-if="agent.type == 'physical'">Физическое лицо</div>
                <div v-if="agent.type == 'juridical'">Юридическое лицо</div>
                <div v-if="agent.type == 'ip'">ИП</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">ИНН</label>
                <div>{{ agent.inn }}</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Телефон</label>
                <div>{{ agent.phone }}</div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-red text-sm py-2 px-4" @click="deleteAgent(agent.id), closePopup(), pickAgent(null)">Удалить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "DeleteAgent",

    data() {
        return {
            moment: moment,
            roles: {
                advertiser: "Рекламодатель",
                agency: "Агентство",
                ors: "Рекламная система",
                publisher: "Издатель",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "agent"]),
    },

    methods: {
        ...mapActions(["cancelAgent", "closePopup", "pickAgent", "deleteAgent"]),
    },
};
</script>
