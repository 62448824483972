<template>
    <div class="w-5/6 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-200px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-sky-600 text-white">ЗАГРУЗИТЬ АКТЫ</h3>

        <div class="flex flex-col p-8 pb-32 text-left h-full overflow-auto">
            <div class="bg-white rounded">
                <div class="grid grid-cols-12 items-center py-2 px-4 mb-px gap-4 text-slate-400 text-xs">
                    <div>НОМЕР</div>
                    <div>ДОГОВОР</div>
                    <div class="col-span-3">ЗАКАЗЧИК</div>
                    <div class="col-span-3">ИСПОЛНИТЕЛЬ</div>
                    <div>СУММА</div>
                    <div>ДАТА</div>
                    <div>НАЧАЛО</div>
                    <div>ОКОНЧАНИЕ</div>
                </div>
            </div>

            <div v-for="act in file" :key="act" class="bg-white rounded">
                <div class="grid grid-cols-12 items-center h-12 px-4 mb-px gap-4 text-sky-800 text-[13px] bg-white rounded hover:bg-sky-50">
                    <div class="cursor-pointer" @click="pickAct(act), setPopup('viewAct')">{{ act.serial }}</div>

                    <div>{{ act.contract_serial }}</div>

                    <div class="col-span-3">
                        <div class="text-xs">
                            <div v-if="act.client_role == 'advertiser'">Рекламодатель</div>
                            <div v-if="act.client_role == 'agency'">Агентство</div>
                            <div v-if="act.client_role == 'ors'">Рекламная система</div>
                            <div v-if="act.client_role == 'publisher'">Издатель</div>
                        </div>
                        <div class="h-5 overflow-hidden">{{ act.agent_name }}</div>
                    </div>

                    <div class="col-span-3">
                        <div class="text-xs">
                            <div v-if="act.contractor_role == 'advertiser'">Рекламодатель</div>
                            <div v-if="act.contractor_role == 'agency'">Агентство</div>
                            <div v-if="act.contractor_role == 'ors'">Рекламная система</div>
                            <div v-if="act.contractor_role == 'publisher'">Издатель</div>
                        </div>
                        <div class="h-5 overflow-hidden">{{ act.contractor_name }}</div>
                    </div>

                    <div class="text-right">{{ parseFloat(act.amount) }}</div>

                    <div v-if="act.date">{{ act.date }}</div>
                    <div v-else></div>

                    <div v-if="act.date_start">{{ act.date_start }}</div>
                    <div v-else></div>

                    <div v-if="act.date_end">{{ act.date_end }}</div>
                    <div v-else></div>
                </div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-red text-sm py-2 px-4" @click="closePopup()">Отменить</div>
            <div class="btn btn-green text-sm py-2 px-4" @click="saveActs(file), closePopup()">Загрузить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "previewAct",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["file"]),
    },

    methods: {
        ...mapActions(["saveActs", "closePopup"]),
    },
};
</script>
