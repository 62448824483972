<template>
    <template v-if="!s.request">
        <div class="bg-white rounded p-4 mb-4">
            <div class="flex justify-between items-center gap-4">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    ОБЪЯВЛЕНИЯ <span class="count">{{ ads?.length }}</span>
                </div>

                <input type="text" class="input" placeholder="Поиск клиентов" v-model="s.findme" @input="filterClients()" />

                <input type="text" class="input w-20" placeholder="Лейбл" v-model="s.label" @input="filterClients()" />

                <div v-if="s.clear" class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings()">ОЧИСТИТЬ</div>
            </div>
        </div>

        <div class="bg-white rounded">
            <template v-if="ads">
                <RecycleScroller class="h-[calc(100vh-200px)]" :items="ads" :item-size="48" key-field="id" v-slot="{ item }">
                    <div
                        class="h-12 overflow-hidden grid grid-cols-11 items-center gap-4 px-4 border-b border-slate-200 text-xs hover:bg-cyan-50/50 hover:!text-cyan-700"
                    >
                        <div class="flex items-center gap-4 col-span-4">
                            <div>
                                <span class="text-xs text-slate-400">{{ item.client_id }}</span>
                                <div class="text-[13px]">{{ item.client }}</div>
                            </div>
                        </div>

                        <div class="flex items-center gap-4 col-span-3">
                            <div>
                                <span class="text-xs text-slate-400">{{ item.id }}</span>
                                <div class="text-[13px]">{{ item.name }}</div>
                            </div>
                        </div>

                        <div class="text-center">{{ item.label }}</div>

                        <div class="text-center">{{ item.erid }}</div>

                        <div>{{ item.url }}</div>

                        <div>
                            <div class="flex items-center gap-2" v-if="item.status == 1"><span class="circle bg-teal-500"></span> Действующий</div>
                            <div class="flex items-center gap-2" v-if="item.status == 0"><span class="circle bg-pink-500"></span> Выключен</div>
                            <div class="flex items-center gap-2" v-if="item.status == 8"><span class="circle"></span> Архив</div>
                        </div>
                    </div>
                </RecycleScroller>
            </template>
        </div>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-cyan-400"><IconLoader size="30" /></div>

    <template v-if="s.popup == 'addToProject' && checkedIds?.length > 0">
        <div class="w-2/3 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">ДОБАВИТЬ В ПРОЕКТ</h3>

            <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    ВЫБРАНО <span class="count">{{ checkedIds?.length }}</span>
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Введите название проекта</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" @input="find()" v-model="findProject" />
                </div>

                <template v-if="projectList?.length > 0">
                    <div class="grid grid-cols-8 items-center text-left text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                        <div>ID</div>
                        <div class="col-span-5">Название проекта</div>
                        <div class="text-right col-span-2">Клиентов</div>
                    </div>

                    <div
                        v-for="pro in projectList.slice(0, 5)"
                        :key="pro"
                        @click="checkedProject = { id: pro.id, name: pro.name }"
                        class="grid grid-cols-8 items-center gap-4 cursor-pointer text-left text-slate-500 p-2 border-b border-slate-200 hover:bg-cyan-50/50 hover:text-cyan-600"
                    >
                        <div class="text-xs">{{ pro.id }}</div>
                        <div class="col-span-5">{{ pro.name }}</div>
                        <div class="text-xs text-right col-span-2">{{ pro.count }}</div>
                    </div>
                </template>

                <div v-if="checkedProject?.id > 0" class="flex flex-col justify-center items-center p-8">
                    <div class="text-center text-teal-600">
                        Вы хотите добавить <span class="count">{{ checkedIds?.length }}</span> клиентов в проект "{{ checkedProject.name }}"?
                    </div>

                    <div
                        class="btn btn-green text-sm py-2 my-4 w-60"
                        @click="addToProject({ project_id: checkedProject.id, ids: checkedIds }), setPopup('')"
                    >
                        ДОБАВИТЬ
                    </div>
                </div>
            </div>
        </div>
    </template>
    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
    name: "AdsPage",

    components: { RecycleScroller },

    data() {
        return {
            moment: moment,
            findProject: "",
            projectList: [],
            checkedProject: {},
        };
    },

    computed: {
        ...mapGetters(["s", "ads"]),
    },

    methods: {
        ...mapActions(["getAds", "setPopup", "pickAd", "filterClients"]),

        find() {
            if (!this.projects || this.findProject == "" || this.findProject == undefined) {
                console.log("projectList пусто");
                return null;
            }
            this.projectList = [];

            this.projectList = this.projects.filter((project) => project.name.toLowerCase().indexOf(this.findProject.toLowerCase()) !== -1);
            // this.projects.forEach((project) => {
            //     if (project.name.toLowerCase().indexOf(this.findProject.toLowerCase()) !== -1) {
            //         this.projectList.push({ id: project.id, name: project.name });
            //     }
            // });
        },

        checkAllAds() {
            if (this.checkAll) {
                this.checkedIds = [];
                this.checkAll = false;
            } else {
                this.checkedIds = this.clients.filter((item) => [3, 6].includes(item.status)).map((item) => item.id);
                this.checkAll = true;
            }
        },
    },

    mounted() {
        this.getAds();
    },
};
</script>

<style scoped>
.scroller {
    height: 100%;
}

.user {
    height: 32%;
    padding: 0 12px;
    display: flex;
    align-items: center;
}
</style>
