<template>
    <template v-if="!s.request">
        <div class="bg-white rounded p-4 mb-4">
            <div v-if="checkedIds?.length > 0" class="flex justify-between items-center gap-4">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    ВЫБРАНО <span class="count">{{ checkedIds?.length }}</span>
                </div>

                <div class="btn btn-red" @click="unTargetologClient(checkedIds)">Отвязать таргетолога</div>
                <div class="btn btn-green" @click="setPopup('addTargetolog')">Привязать таргетолога</div>

                <div class="btn btn-red" @click="unProjectClient(checkedIds)">Убрать из проекта</div>
                <div class="btn btn-green" @click="setPopup('addToProject')">Добавить в проект</div>
            </div>

            <div v-else class="flex justify-between items-center gap-4">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    КЛИЕНТЫ <span class="count">{{ clients?.length }}</span>
                </div>

                <input type="text" class="input" placeholder="Поиск клиентов" v-model="s.findme" @input="filterClients()" />

                <input type="text" class="input w-20" placeholder="Лейбл" v-model="s.label" @input="filterClients()" />

                <select v-model="s.channel_id" class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700" @change="filterClients()">
                    <template v-for="ch in channels" :key="ch">
                        <option v-if="s.channel_id == ch.id" selected :value="ch.id">{{ ch.name }}</option>
                        <option v-else :value="ch.id">{{ ch.name }}</option>
                    </template>
                </select>

                <select v-model="s.status" class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700" @change="filterClients()">
                    <template v-for="st in statuses" :key="st">
                        <option v-if="s.status == st.id" selected :value="st.id">{{ st.name }}</option>
                        <option v-else :value="st.id">{{ st.name }}</option>
                    </template>
                </select>

                <!-- <div>
                    <div v-if="project?.id" class="w-40 flex justify-center gap-1 text-cyan-600">
                        <div class="text-sm text-cyan-600">{{ project.name }}</div>
                        <div class="cursor-pointer text-slate-400" @click="unPickProject()"><IconClose :size="4" /></div>
                    </div>
                    <input v-else type="text" class="input" placeholder="Проект" v-model="s.findme" @input="filterClients()" />
                </div> -->
                <!-- <div class="btn btn-empty">БЕЗ&nbsp;ПРОЕКТА</div> -->

                <div v-if="s.clear" class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings()">ОЧИСТИТЬ</div>

                <div class="btn btn-blue" @click="exportXLSX()">ЭКСПОРТ</div>
            </div>
        </div>

        <div class="bg-white rounded">
            <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 py-2 px-4 border-b border-slate-200 uppercase">
                <div class="flex items-center gap-4">
                    <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">
                        <input type="checkbox" id="checkAll" class="mychbox" @click="checkAllAds()" />
                        <label for="checkAll"></label>
                    </div>
                    ОШИБКИ
                </div>
                <div class="col-span-3">Название клиента</div>
                <div class="text-center">Таргетолог</div>
                <div class="text-center">Лейбл</div>
                <div class="text-center">Проект</div>
                <div class="col-span-2">Полное имя клиента</div>
                <div>ИНН</div>

                <div>Статус</div>
            </div>

            <template v-if="clients">
                <RecycleScroller class="h-[calc(100vh-200px)]" :items="clients" :item-size="48" key-field="id" v-slot="{ item }">
                    <div
                        class="h-12 overflow-hidden grid grid-cols-11 items-center gap-4 px-4 border-b border-slate-200 text-xs hover:bg-cyan-50/50 hover:!text-cyan-700"
                    >
                        <div class="flex items-center gap-4">
                            <input
                                type="checkbox"
                                :id="item.id"
                                :value="item.id"
                                class="mychbox"
                                :checked="checkedIds.includes(item.id)"
                                @click="checkboxId(item.id)"
                            />
                            <div v-if="item.wrong == 0" class="text-teal-600"><IconOk :size="6" /></div>
                            <div v-if="item.wrong > 0" class="text-pink-600">
                                <IconWarning :size="6" />
                            </div>
                        </div>

                        <div class="flex items-center gap-4 col-span-3">
                            <a
                                v-if="item.channel_id == 1"
                                class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer"
                                target="_blank"
                                :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                            >
                                <IVk />
                            </a>
                            <a
                                v-if="item.channel_id == 2"
                                class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer p-1"
                                target="_blank"
                                href="https://ads.vk.com/hq/dashboard"
                            >
                                <IVkAds :size="6" />
                            </a>

                            <div>
                                <span class="text-xs text-slate-400">{{ item.id }}</span>
                                <div class="text-[13px]">{{ item.name }}</div>
                            </div>
                        </div>

                        <div class="text-center">{{ item.tname }} {{ item.tsecondname }}</div>
                        <!-- <div v-else class="btn btn-empty">Выбрать</div> -->

                        <div class="text-center">{{ item.label }}</div>

                        <div class="text-center cursor-pointer" @click="pickProject(item.project_id)">{{ item.project }}</div>

                        <div class="col-span-2">{{ item.client_name }}</div>

                        <div>{{ item.inn }}</div>

                        <div>
                            <div class="flex items-center gap-2" v-if="item.status == 1"><span class="circle bg-teal-500"></span> Действующий</div>
                            <div class="flex items-center gap-2" v-if="item.status == 0"><span class="circle bg-pink-500"></span> Выключен</div>
                            <div class="flex items-center gap-2" v-if="item.status == 8"><span class="circle"></span> Архив</div>
                        </div>
                    </div>
                </RecycleScroller>
            </template>
        </div>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-cyan-400"><IconLoader :size="30" /></div>

    <template v-if="s.popup == 'addTargetolog' && checkedIds?.length > 0">
        <div class="w-2/3 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">ПРИВЯЗАТЬ ТАРГЕТОЛОГА</h3>

            <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    ВЫБРАНО <span class="count">{{ checkedIds?.length }}</span>
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Введите ФИО таргетолога</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" @input="findT()" v-model="findTargetolog" />
                </div>

                <template v-if="targetologList?.length > 0">
                    <div class="grid grid-cols-6 items-center text-left text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                        <!-- <div>ID</div> -->
                        <div class="col-span-5">Таргетолог</div>
                    </div>

                    <div
                        v-for="tar in targetologList.slice(0, 5)"
                        :key="tar"
                        @click="checkedTargetolog = { id: tar.id, name: tar.name + ' ' + tar.secondname }"
                        class="grid grid-cols-6 items-center gap-4 cursor-pointer text-left text-slate-500 p-2 border-b border-slate-200 hover:bg-cyan-50/50 hover:text-cyan-600"
                    >
                        <!-- <div class="text-xs">{{ tar.id }}</div> -->
                        <div class="col-span-5">{{ tar.name }} {{ tar.secondname }}</div>
                    </div>
                </template>

                <div v-if="checkedTargetolog?.id > 0" class="flex flex-col justify-center items-center p-8">
                    <div class="text-center text-teal-600">
                        Вы хотите добавить <span class="count">{{ checkedIds?.length }}</span> клиентов таргетологу "{{ checkedTargetolog.name }}"?
                    </div>

                    <div
                        class="btn btn-green text-sm py-2 my-4 w-60"
                        @click="addTargetologClient({ targetolog_id: checkedTargetolog.id, ids: checkedIds }), setPopup('')"
                    >
                        ДОБАВИТЬ
                    </div>
                </div>
            </div>
        </div>
    </template>

    <template v-if="s.popup == 'addToProject' && checkedIds?.length > 0">
        <div class="w-2/3 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">ДОБАВИТЬ В ПРОЕКТ</h3>

            <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    ВЫБРАНО <span class="count">{{ checkedIds?.length }}</span>
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Введите название проекта</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" @input="findP()" v-model="findProject" />
                </div>

                <template v-if="projectList?.length > 0">
                    <div class="grid grid-cols-8 items-center text-left text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                        <div>ID</div>
                        <div class="col-span-5">Название проекта</div>
                        <div class="text-right col-span-2">Клиентов</div>
                    </div>

                    <div
                        v-for="pro in projectList.slice(0, 5)"
                        :key="pro"
                        @click="checkedProject = { id: pro.id, name: pro.name }"
                        class="grid grid-cols-8 items-center gap-4 cursor-pointer text-left text-slate-500 p-2 border-b border-slate-200 hover:bg-cyan-50/50 hover:text-cyan-600"
                    >
                        <div class="text-xs">{{ pro.id }}</div>
                        <div class="col-span-5">{{ pro.name }}</div>
                        <div class="text-xs text-right col-span-2">{{ pro.count }}</div>
                    </div>
                </template>

                <div v-if="checkedProject?.id > 0" class="flex flex-col justify-center items-center p-8">
                    <div class="text-center text-teal-600">
                        Вы хотите добавить <span class="count">{{ checkedIds?.length }}</span> клиентов в проект "{{ checkedProject.name }}"?
                    </div>

                    <div
                        class="btn btn-green text-sm py-2 my-4 w-60"
                        @click="addToProject({ project_id: checkedProject.id, ids: checkedIds }), setPopup('')"
                    >
                        ДОБАВИТЬ
                    </div>
                </div>
            </div>
        </div>
    </template>

    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { utils, writeFileXLSX } from "xlsx";

export default {
    name: "ClientsPage",

    components: { RecycleScroller },

    data() {
        return {
            moment: moment,
            findProject: "",
            projectList: [],
            findTargetolog: "",
            targetologList: [],
            checkedProject: {},
            checkedTargetolog: {},
            channels: [
                { id: -1, name: "ВСЕ КАНАЛЫ" },
                { id: 1, name: "VK API" },
                { id: 2, name: "VK ADS" },
                { id: 3, name: "VK MARKET" },
            ],

            statuses: [
                { id: -1, name: "ВСЕ СТАТУСЫ" },
                { id: 0, name: "Выключен" },
                { id: 1, name: "Активный" },
                { id: 3, name: "Юр.заморозка" },
                { id: 8, name: "Для архивации" },
                { id: 9, name: "Архив вк" },
                // { id: 10, name: "blocked" },
            ],
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "users", "projects", "project", "clients", "checkedIds"]),
    },

    methods: {
        ...mapActions([
            "getProjects",
            "setPopup",
            "closePopup",
            "clearSettings",
            "pickProject",
            "unPickProject",
            "ProjectClient",
            "addToProject",
            "unProjectClient",
            "addTargetologClient",
            "unTargetologClient",
            "checkboxId",
            "filterClients",
        ]),

        findP() {
            if (!this.projects || this.findProject == "" || this.findProject == undefined) {
                console.log("projectList пусто");
                return null;
            }
            this.projectList = [];

            this.projectList = this.projects.filter((project) => project.name.toLowerCase().indexOf(this.findProject.toLowerCase()) !== -1);
            // this.projects.forEach((project) => {
            //     if (project.name.toLowerCase().indexOf(this.findProject.toLowerCase()) !== -1) {
            //         this.projectList.push({ id: project.id, name: project.name });
            //     }
            // });
        },

        findT() {
            if (!this.users || this.findTargetolog == "" || this.findTargetolog == undefined) {
                console.log("targetologList пусто");
                return null;
            }
            this.targetologList = [];

            this.targetologList = this.users.filter(
                (user) =>
                    user.name.toLowerCase().indexOf(this.findTargetolog.toLowerCase()) !== -1 ||
                    user.secondname.toLowerCase().indexOf(this.findTargetolog.toLowerCase()) !== -1
            );
        },

        exportXLSX() {
            let wscols = [
                { wch: 12 },
                { wch: 8 },
                { wch: 20 },
                { wch: 40 },
                { wch: 12 },
                { wch: 40 },
                { wch: 12 },
                { wch: 12 },
                { wch: 12 },
                { wch: 12 },
                { wch: 12 },
                { wch: 12 },
                { wch: 20 },
                { wch: 12 },
                { wch: 12 },
                { wch: 12 },
            ];

            let list = [];
            this.clients.forEach((c) => {
                let cli = {
                    id: c.id,
                    label: c.label,
                    project: c.project,
                    name: c.name,
                    client_type: c.client_type,
                    client_name: c.client_name,
                    inn: c.inn,
                    phone: c.phone,
                    contract_number: c.contract_number,
                    contract_date: c.contract_date,
                    contract_type: c.contract_type,
                    contract_object: c.contract_object,
                    subagent_name: c.subagent_name,
                    subagent_type: c.subagent_type,
                    subagent_phone: c.subagent_phone,
                    subagent_inn: c.subagent_inn,
                };
                if (c.client_type == "individual" || c.client_type == "ip") {
                    cli.client_type = "ИП";
                }
                if (c.client_type == "juridical" || c.client_type == "legal") {
                    cli.client_type = "ООО";
                }
                if (c.client_type == "person") {
                    cli.client_type = "Физ.лицо";
                }

                if (c.subagent_type == "individual") {
                    cli.subagent_type = "ИП";
                }
                if (c.subagent_type == "juridical" || c.subagent_type == "legal") {
                    cli.subagent_type = "ООО";
                }
                if (c.subagent_type == "person") {
                    cli.subagent_type = "Физ.лицо";
                }

                if (c.contract_type == "mediation") cli.contract_type = "посреднический договор";
                if (c.contract_type == "service") cli.contract_type = "договор оказания услуг";

                if (c.contract_object == "distribution") cli.contract_object = "договор оказания услуг";
                if (c.contract_object == "mediation") cli.contract_object = "посреднический договор";
                if (c.contract_object == "org_distribution") cli.contract_object = "договор на организацию распространения рекламы";
                if (c.contract_object == "other") cli.contract_object = "иное";
                if (c.contract_object == "representation") cli.contract_object = "представительство";

                list.push(cli);
            });

            let worksheet = utils.json_to_sheet(list);
            worksheet = utils.sheet_add_aoa(
                worksheet,
                [
                    [
                        "ID клиента",
                        "Агентский",
                        "Проект",
                        "Название",
                        "Юрлицо",
                        "Наименование",
                        "Инн",
                        "Телефон",
                        "Номер договора",
                        "Дата договора",
                        "Тип договора",
                        "Предмет договора",
                        "Название подрядчика",
                        "Тип подрядчика",
                        "Телефон подрядчика",
                        "Инн подрядчика",
                    ],
                ],
                { origin: "A1" }
            );
            worksheet["!cols"] = wscols;
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "Clients");
            writeFileXLSX(workbook, "FAQ_Clients.xlsx", { compression: true });
        },

        checkAllAds() {
            if (this.checkAll) {
                this.checkedIds = [];
                this.checkAll = false;
            } else {
                this.checkedIds = this.clients.filter((item) => [3, 6].includes(item.status)).map((item) => item.id);
                this.checkAll = true;
            }
        },
    },

    mounted() {
        this.getProjects();
    },
};
</script>

<style scoped>
.scroller {
    height: 100%;
}

.user {
    height: 32%;
    padding: 0 12px;
    display: flex;
    align-items: center;
}
</style>
