<template>
    <div class="w-1/2 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-200px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-sky-600 text-white">РЕДАКТИРОВАТЬ АКТ : {{ act.serial }}</h3>

        <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
            <div class="flex justify-between gap-4">
                <div class="w-1/2 flex flex-col mb-4 relative">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Договор акта</label>
                    <!-- <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="act.serial" /> -->

                    <div v-if="act.contract_external_id" class="flex items-center gap-4">
                        {{ act.contract_serial }}
                        <div class="text-slate-400 hover:text-red-500 cursor-pointer" @click="act.contract_external_id = null">
                            <IconClose :size="4" />
                        </div>
                    </div>
                    <template v-else>
                        <input
                            type="text"
                            class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded"
                            v-model="find"
                            @click="select_modal = 'contract'"
                        />

                        <div v-if="select_modal == 'contract'" class="absolute top-14 w-full bg-white rounded shadow-md">
                            <div
                                v-for="contract in findContract"
                                :key="contract"
                                @click="(act.contract_serial = contract.serial), (act.contract_external_id = contract.external_id)"
                                class="text-left p-2 hover:bg-slate-50"
                            >
                                <div class="text-sm">{{ contract.serial }}</div>
                                <div class="text-xs text-slate-500">{{ contract.agent_name }} - {{ contract.contractor_name }}</div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Номер акта</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="act.serial" />
                </div>
            </div>

            <div class="flex justify-between gap-4">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Роль заказчика</label>

                    <select class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="act.client_role">
                        <option value="advertiser">Рекламодатель</option>
                        <option value="agency">Агентство</option>
                        <option value="ors">Рекламная система</option>
                        <option value="publisher">Издатель</option>
                    </select>
                </div>

                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Роль исполнителя</label>

                    <select class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="act.contractor_role">
                        <option value="advertiser">Рекламодатель</option>
                        <option value="agency">Агентство</option>
                        <option value="ors">Рекламная система</option>
                        <option value="publisher">Издатель</option>
                    </select>
                </div>
            </div>

            <div class="flex justify-between gap-4">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Дата выставления</label>
                    <input type="date" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="act.date" />
                </div>

                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Сумма</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="act.amount" />
                </div>
            </div>

            <div class="flex justify-between gap-4">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Дата начала периода</label>
                    <input type="date" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="act.date_start" />
                </div>

                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Дата окончания периода</label>
                    <input type="date" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="act.date_end" />
                </div>
            </div>

            <div v-if="deallocation && deallocation.length > 0">
                <p class="text-left text-xl my-4">Разаллокация</p>
                <!-- <span class="text-xs">{{ deallocation }}</span> -->
                <div v-for="item in deallocation" :key="item" class="flex relative gap-4">
                    <div class="w-1/2 flex flex-col mb-4">
                        <label class="w-full text-xs text-slate-400 mb-1">Изначальный договор с рекламодателем</label>
                        <div v-if="item.id" class="flex items-center gap-4">
                            <template v-for="contract in contracts" :key="contract">
                                <template v-if="item.id == contract.external_id">
                                    <div class="text-sm">{{ contract.serial }}</div>
                                    <div class="text-xs text-slate-500">{{ contract.agent_name }} - {{ contract.contractor_name }}</div>
                                </template>
                            </template>

                            <div class="text-slate-400 hover:text-red-500 cursor-pointer" @click="item.id = null">
                                <IconClose :size="4" />
                            </div>
                        </div>
                        <template v-else>
                            <input
                                type="text"
                                class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded"
                                v-model="find"
                                @click="select_modal = 'contract'"
                            />

                            <div v-if="select_modal == 'contract'" class="absolute top-14 w-full bg-white rounded shadow-md">
                                <div
                                    v-for="contract in findContract"
                                    :key="contract"
                                    @click="(item.id = contract.external_id), (item.contract_serial = contract.serial)"
                                    class="text-left p-2 hover:bg-slate-50"
                                >
                                    <div class="text-sm">{{ contract.serial }}</div>
                                    <div class="text-xs text-slate-500">{{ contract.agent_name }} - {{ contract.contractor_name }}</div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="w-1/4 flex flex-col mb-4">
                        <label class="w-full text-xs text-slate-400 mb-1">Сумма (₽)</label>
                        <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="item.amount" />
                    </div>
                    <div class="w-1/4 flex flex-col mb-4">
                        <div class="btn btn-red h-8 mt-5" @click="delDeallocation(item)">Удалить</div>
                    </div>
                </div>
            </div>

            <div class="btn w-fit mb-4" @click="addDeallocation()">Добавить договор</div>

            <hr />
            <p class="text-left text-sm text-slate-400 my-2">Дополнительно</p>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Статус</label>
                <div v-if="act.status == 1" class="text-slate-400">Создан</div>
                <div v-if="act.status == 2" class="text-sky-400">Проверен</div>
                <div v-if="act.status == 3" class="text-red-400">Отклонен</div>
                <div v-if="act.status == 7" class="text-teal-400">Отправлен в ОРД</div>
            </div>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Внешний ID</label>
                <div class="text-slate-400">{{ act.external_id }}</div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="save()">Сохранить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "EditAct",

    data() {
        return {
            moment: moment,
            find: "",
            select_modal: "",
            deallocation: [],
        };
    },

    computed: {
        ...mapGetters(["s", "act", "contracts", "agents"]),

        findContract() {
            if (!this.contracts || this.contracts.length == 0) return [];

            let data = this.contracts.filter((item) => {
                // let id = null;
                let serial = null;

                // id = item.external_id.toString().indexOf(this.find.toLowerCase()) !== -1;
                if (item.serial) serial = item.serial?.toLowerCase().indexOf(this.find?.toLowerCase()) !== -1;
                if (serial) return true;

                return false;
            });
            return data.slice(0, 5);
        },
    },

    methods: {
        ...mapActions(["saveAct", "closePopup", "pickAct"]),

        addDeallocation() {
            this.deallocation.push({ id: "", amount: 0.0 });
        },

        delDeallocation(del) {
            this.deallocation = this.deallocation.filter((item) => item != del);
        },

        save() {
            this.act.deallocation = JSON.stringify(this.deallocation);
            this.saveAct(this.act);
            this.closePopup();
            this.pickAct(null);
        },
    },

    mounted() {
        if (this.act.deallocation && this.act.deallocation.length > 0) this.deallocation = JSON.parse(this.act.deallocation);
    },
};
</script>
