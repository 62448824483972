<template>
    <div class="container mx-auto px-10 pt-20">
        <h2 class="uppercase text-center text-2xl text-cyan-500 font-bold mb-4">Агентские кабинеты</h2>

        <div class="grid grid-cols-3 gap-4 text-center">
            <div v-for="item in accounts" :key="item.id" class="box cursor-pointer" @click="pickAccount(item)">
                <p class="text-slate-400">{{ item.id }}</p>
                <h2 class="font-bold">{{ item.name }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "MainPage",

    computed: {
        ...mapGetters(["accounts"]),
    },

    methods: {
        ...mapActions(["getAccounts", "pickAccount"]),
    },

    mounted() {
        this.getAccounts();
    },
};
</script>
