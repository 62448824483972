<template>
    <div class="w-1/2 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-200px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-red-500 text-white">УДАЛИТЬ АКТ</h3>

        <div class="flex flex-col p-8 pb-32 text-left h-full overflow-auto">
            <div class="flex">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Договор акта</label>
                    <div>{{ act.contract_serial }}</div>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Номер акта</label>
                    <div>{{ act.serial }}</div>
                </div>
            </div>

            <div class="flex">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Роль заказчика</label>
                    <div v-if="act.client_role == 'advertiser'">Рекламодатель</div>
                    <div v-if="act.client_role == 'agency'">Агентство</div>
                    <div v-if="act.client_role == 'ors'">Рекламная система</div>
                    <div v-if="act.client_role == 'publisher'">Издатель</div>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Роль исполнителя</label>
                    <div v-if="act.contractor_role == 'advertiser'">Рекламодатель</div>
                    <div v-if="act.contractor_role == 'agency'">Агентство</div>
                    <div v-if="act.contractor_role == 'ors'">Рекламная система</div>
                    <div v-if="act.contractor_role == 'publisher'">Издатель</div>
                </div>
            </div>

            <div class="flex">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Заказчик</label>
                    <div>{{ act.agent_name }}</div>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Исполнитель</label>
                    <div>{{ act.contractor_name }}</div>
                </div>
            </div>

            <div class="flex">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Дата выставления</label>
                    <div>{{ moment(act.date).format("DD.MM.YYYY") }}</div>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Сумма (₽)</label>
                    <div>{{ act.amount }}</div>
                </div>
            </div>

            <div class="flex">
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Дата начала периода</label>
                    <div>{{ moment(act.date_start).format("DD.MM.YYYY") }}</div>
                </div>
                <div class="w-1/2 flex flex-col mb-4">
                    <label class="w-full text-xs text-slate-400 mb-1">Дата окончания периода</label>
                    <div>{{ moment(act.date_end).format("DD.MM.YYYY") }}</div>
                </div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-red text-sm py-2 px-4" @click="deleteAct(act.id), closePopup(), pickAct(null)">Удалить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "DeleteAct",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "act"]),
    },

    methods: {
        ...mapActions(["cancelAct", "closePopup", "pickAct", "deleteAct"]),
    },
};
</script>
