import API from "../../services/api";
import moment from "moment";

export default {
    state: {
        agents: [],
        agentsF: [],
        agent: {},

        contracts: [],
        contractsF: [],
        contract: {},

        acts: [],
        actsF: [],
        act: {},

        file: [],
    },

    mutations: {
        setAgents(state, agents) {
            state.agents = agents;
            state.agentsF = agents;
        },
        setAgentsF(state, agents) {
            state.agentsF = agents;
        },
        setAgent(state, agent) {
            localStorage.setItem("agent", JSON.stringify(agent));
            state.agent = agent;
        },

        setContracts(state, contracts) {
            state.contracts = contracts;
            state.contractsF = contracts;
        },
        setContractsF(state, contracts) {
            state.contractsF = contracts;
        },
        setContract(state, contract) {
            localStorage.setItem("contract", JSON.stringify(contract));
            state.contract = contract;
        },

        setActs(state, acts) {
            state.acts = acts;
            state.actsF = acts;
        },
        setActsF(state, acts) {
            state.actsF = acts;
        },
        setAct(state, act) {
            localStorage.setItem("act", JSON.stringify(act));
            state.act = act;
        },

        setFile(state, file) {
            state.file = file;
        },
    },

    actions: {
        getAllORD(ctx, id) {
            ctx.dispatch("request", true);
            API.GET("ord/all", { id: id }).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAgents", res.data.agents);
                ctx.commit("setContracts", res.data.contracts);
                ctx.commit("setActs", res.data.acts);
                ctx.dispatch("request", false);
            });
        },

        // АГЕНТ

        pickAgent(ctx, agent) {
            ctx.commit("setAgent", agent);
        },

        getAgent(ctx, id) {
            ctx.dispatch("request", true);
            API.GET("ord/agent/get", { id: id }).then((res) => {
                console.log("getAgent", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAgents", res.data.agents);
                ctx.dispatch("request", false);
            });
        },

        filterAgents(ctx) {
            if (!ctx.state.agents) return null;

            ctx.dispatch("filterList", ctx.state.agents).then((res) => {
                ctx.commit("setAgentsF", res);
            });
        },

        createAgent(ctx) {
            API.GET("ord/agent/create").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAgents", res.data.agents);
                let agent = ctx.state.agents.filter((item) => parseInt(item.id) == parseInt(res.data.agent.id));
                ctx.commit("setAgent", agent[0]); // чтобы данные проекта менялись в списке
                ctx.dispatch("setPopup", "editAgent");
            });
        },

        saveAgent(ctx, agent) {
            let formData = new FormData();
            formData.append("id", agent.id);
            formData.append("name", agent.name);
            formData.append("type", agent.type);
            formData.append("roles", agent.roles);
            formData.append("inn", agent.inn);
            formData.append("phone", agent.phone);
            formData.append("url", agent.url);
            formData.append("status", agent.status);

            API.POST("ord/agent/save", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAgents", res.data.agents);
            });
        },

        verifyAgent(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/agent/verify", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAgents", res.data.agents);
            });
        },

        cancelAgent(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/agent/cancel", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAgents", res.data.agents);
            });
        },

        sendAgent(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/agent/send", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAgents", res.data.agents);
            });
        },

        deleteAgent(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/agent/delete", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAgents", res.data.agents);
            });
        },

        // КОНТРАКТ

        pickContract(ctx, contract) {
            ctx.commit("setContract", contract);
        },

        filterContracts(ctx) {
            if (!ctx.state.contracts) return null;

            let newList = ctx.state.contracts;

            if (ctx.rootState.s.findme != "" && ctx.rootState.s.findme != undefined) {
                newList = newList.filter((item) => {
                    let serial = null;
                    let agent_name = null;
                    let contractor_name = null;

                    if (item.serial) serial = item.serial.toString().indexOf(ctx.rootState.s.findme.toLowerCase()) !== -1;
                    if (item.agent_name) agent_name = item.agent_name?.toLowerCase().indexOf(ctx.rootState.s.findme?.toLowerCase()) !== -1;
                    if (item.contractor_name)
                        contractor_name = item.contractor_name?.toLowerCase().indexOf(ctx.rootState.s.findme?.toLowerCase()) !== -1;
                    if (serial || agent_name || contractor_name) return true;

                    return false;
                });
            }

            ctx.dispatch("setSettings", ctx.rootState.s);
            ctx.commit("setContractsF", newList);
        },

        createContract(ctx) {
            API.GET("ord/contract/create").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setContracts", res.data.contracts);
                let contract = ctx.state.contracts.filter((item) => parseInt(item.id) == parseInt(res.data.contract.id));
                ctx.commit("setContract", contract[0]); // чтобы данные проекта менялись в списке
                ctx.dispatch("setPopup", "editContract");
            });
        },

        saveContract(ctx, contract) {
            let formData = new FormData();
            formData.append("id", contract.id);
            formData.append("type", contract.type);
            formData.append("agent_id", contract.agent_id);
            formData.append("contractor_id", contract.contractor_id);
            formData.append("subject", contract.subject);
            formData.append("serial", contract.serial);
            formData.append("action", contract.action);
            formData.append("date", contract.date);
            formData.append("sum", contract.sum);
            formData.append("status", contract.status);

            API.POST("ord/contract/save", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setContracts", res.data.contracts);
            });
        },

        verifyContract(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/contract/verify", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setContracts", res.data.contracts);
            });
        },

        cancelContract(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/contract/cancel", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setContracts", res.data.contracts);
            });
        },

        sendContract(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/contract/send", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setContracts", res.data.contracts);
            });
        },

        deleteContract(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/contract/delete", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setContracts", res.data.contracts);
            });
        },

        // АКТ

        getAct(ctx, id) {
            ctx.dispatch("request", true);
            API.GET("ord/act/get", { id: id }).then((res) => {
                console.log("getAct", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setActs", res.data.acts);
                ctx.dispatch("request", false);
            });
        },

        createAct(ctx) {
            API.GET("ord/act/create").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setActs", res.data.acts);
                let act = ctx.state.acts.filter((item) => parseInt(item.id) == parseInt(res.data.act.id));
                ctx.commit("setAct", act[0]); // чтобы данные проекта менялись в списке
                ctx.dispatch("setPopup", "editAct");
            });
        },

        filterActs(ctx) {
            console.log("filterActs start", ctx.rootState.s.start);
            console.log("filterActs end", ctx.rootState.s.end);
            if (!ctx.state.acts) return null;

            let newList = ctx.state.acts;

            if (ctx.rootState.s.findme != "" && ctx.rootState.s.findme != undefined) {
                newList = newList.filter((item) => {
                    let serial = null;
                    let contract_serial = null;
                    let agent_name = null;
                    let contractor_name = null;

                    if (item.serial) serial = item.serial.toString().toLowerCase().indexOf(ctx.rootState.s.findme.toLowerCase()) !== -1;
                    if (item.contract_serial)
                        contract_serial = item.contract_serial.toString().toLowerCase().indexOf(ctx.rootState.s.findme.toLowerCase()) !== -1;
                    if (item.agent_name) agent_name = item.agent_name?.toLowerCase().indexOf(ctx.rootState.s.findme?.toLowerCase()) !== -1;
                    if (item.contractor_name)
                        contractor_name = item.contractor_name?.toLowerCase().indexOf(ctx.rootState.s.findme?.toLowerCase()) !== -1;
                    if (serial || contract_serial || agent_name || contractor_name) return true;

                    return false;
                });
            }

            if (ctx.rootState.s.start && ctx.rootState.s.end) {
                newList = newList.filter(
                    (item) => moment(item.date) >= moment(ctx.rootState.s.start) && moment(item.date) <= moment(ctx.rootState.s.end)
                );
            }

            ctx.dispatch("setSettings", ctx.rootState.s);
            ctx.commit("setActsF", newList);
        },

        saveAct(ctx, act) {
            console.log("act", act);
            let formData = new FormData();
            formData.append("id", act.id);
            formData.append("contract_external_id", act.contract_external_id);
            formData.append("serial", act.serial);
            formData.append("client_role", act.client_role);
            formData.append("contractor_role", act.contractor_role);
            formData.append("amount", act.amount);
            formData.append("deallocation", act.deallocation);
            formData.append("date", act.date);
            formData.append("date_start", act.date_start);
            formData.append("date_end", act.date_end);
            formData.append("status", act.status);

            API.POST("ord/act/save", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setActs", res.data.acts);
            });
        },

        saveActs(ctx, csv) {
            console.log("acts", csv);
            let formData = new FormData();
            formData.append("csv", JSON.stringify(csv));

            API.POST("ord/act/csv", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setActs", res.data.acts);
            });
        },

        pickAct(ctx, act) {
            ctx.commit("setAct", act);
        },

        verifyAct(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/act/verify", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setActs", res.data.acts);
            });
        },

        cancelAct(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/act/cancel", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setActs", res.data.acts);
            });
        },

        sendAct(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/act/send", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setActs", res.data.acts);
            });
        },

        deleteAct(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("ord/act/delete", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setActs", res.data.acts);
            });
        },

        uploadFile(ctx, data) {
            console.log("uploadFile", data);

            let file = data.e.target.files[0];

            let ext = "не определилось";
            let parts = file.name.split(".");
            if (parts.length > 1) ext = parts.pop();

            if (!["csv"].includes(ext)) {
                ctx.commit("setAlert", { msg: "Неправильный формат. Загрузите .csv", type: "error" });
                return;
            }

            ctx.commit("setFile", file);

            // API.POST("order/quik/upload", formData, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => {
            //     console.log(res.data);
            //     this.preview = res.data.result;
            // });
        },
    },

    getters: {
        agents(state) {
            return state.agentsF;
        },
        agent(state) {
            return state.agent;
        },

        contracts(state) {
            return state.contractsF;
        },
        contract(state) {
            return state.contract;
        },

        acts(state) {
            return state.actsF;
        },
        act(state) {
            return state.act;
        },
        file(state) {
            return state.file;
        },
    },
};
