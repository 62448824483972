<template>
    <div class="flex justify-center items-center m-auto mb-4 border-b border-slate-200 text-slate-400 text-sm rounded-t tracking-wider">
        <div
            class="flex justify-center items-center gap-4 hover:text-sky-600 px-8 py-3 -mb-px border-b border-slate-200 rounded-t cursor-pointer"
            @click="setView('agents')"
            :class="{ ' text-sky-600 !border-sky-500': s.view == 'agents' }"
        >
            <IVkUser :size="5" />
            Контрагенты
        </div>
        <div
            class="flex justify-center items-center gap-4 hover:text-sky-600 px-8 py-3 -mb-px border-b border-slate-200 rounded-t cursor-pointer"
            @click="setView('contracts')"
            :class="{ ' text-sky-600 !border-sky-500': s.view == 'contracts' }"
        >
            <IVkBook :size="5" />
            Договоры
        </div>
        <div
            class="flex justify-center items-center gap-4 hover:text-sky-600 px-8 py-3 -mb-px border-b border-slate-200 rounded-t cursor-pointer"
            @click="setView('acts')"
            :class="{ ' text-sky-600 !border-sky-500': s.view == 'acts' }"
        >
            <IVkDoc :size="5" />
            Акты
        </div>
    </div>

    <div v-if="s.view == 'agents'" class="flex justify-between items-center gap-4 mb-4">
        <span class="count">{{ agents?.length }}</span>

        <div class="relative w-1/2">
            <input
                type="text"
                class="input bg-white placeholder-slate-300 text-md"
                placeholder="Название, инн, телефон"
                v-model="s.findme"
                @input="filterAgents()"
            />
            <div class="absolute top-2 right-2 text-slate-300"><IconSearch :size="5" /></div>
        </div>

        <div class="flex justify-between items-center gap-4">
            <div class="btn btn-blue gap-2" @click="exportAgents()">
                <IconDownload :size="5" />
            </div>

            <div class="btn btn-green gap-2" @click="createAgent()">
                <IconPlus :size="5" />
            </div>
        </div>
    </div>

    <div v-if="s.view == 'contracts'" class="flex justify-between items-center gap-4 mb-4">
        <span class="count">{{ contracts?.length }}</span>

        <div class="relative w-1/2">
            <input
                type="text"
                class="input bg-white placeholder-slate-300 text-md"
                placeholder="Заказчик, исполнитель"
                v-model="s.findme"
                @input="filterContracts()"
            />
            <div class="absolute top-2 right-2 text-slate-300"><IconSearch :size="5" /></div>
        </div>

        <div class="flex justify-end items-between gap-4">
            <div class="btn btn-blue gap-2" @click="exportContracts()">
                <IconDownload :size="5" />
            </div>

            <div class="btn btn-green gap-2" @click="createContract()">
                <IconPlus :size="5" />
            </div>
        </div>
    </div>

    <div v-if="s.view == 'acts'" class="grid grid-cols-6 justify-between items-center gap-4 mb-4">
        <div>
            <span class="count">{{ acts?.length }}</span>
        </div>

        <div class="flex justify-end items-between gap-4 col-span-4">
            <div class="relative w-2/3">
                <input
                    type="text"
                    class="input bg-white placeholder-slate-300 text-md"
                    placeholder="Заказчик, исполнитель"
                    v-model="s.findme"
                    @input="filterActs()"
                />

                <div class="absolute top-2 right-2 text-slate-300"><IconSearch :size="5" /></div>
            </div>

            <input type="date" class="input text-sm w-36 bg-white" @change="filterActs()" v-model="s.start" />
            <input type="date" class="input text-sm w-36 bg-white" @change="filterActs()" v-model="s.end" />
        </div>

        <div class="flex justify-end items-between gap-4">
            <div class="relative">
                <input type="file" id="file" class="absolute invisible opacity-0" @change="importActs({ e: $event })" />

                <label for="file" class="btn btn-yellow gap-2"> <IconUpload :size="5" /> </label>
            </div>

            <div class="btn btn-blue gap-2" @click="exportActs()">
                <IconDownload :size="5" />
            </div>

            <div class="btn btn-green gap-2" @click="createAct()">
                <IconPlus :size="5" />
            </div>
        </div>
    </div>

    <template v-if="!s.request">
        <template v-if="s.view == 'agents'">
            <!-- <div class="bg-white rounded p-4 mb-4">
                <div class="flex justify-between items-center gap-4">
                    <div class="flex justify-center items-center text-slate-500 gap-2">
                        КОНТРАГЕНТЫ <span class="count">{{ agents?.length }}</span>
                    </div>
                    <div class="btn btn-blue" @click="exportAgents()">ЭКСПОРТ</div> -->

            <!-- <div v-if="['Or', 'Bs', 'Lr'].includes(profile?.role)" @click="createAgent()" class="btn btn-green gap-2"> -->
            <!-- <div @click="createAgent()" class="btn btn-green gap-2">
                        <span class="text-white my-2"><IconPlus :size="5" /></span>
                        <span class="text-white text-xs">СОЗДАТЬ</span>
                    </div>
                </div>
            </div> -->

            <div class="bg-white rounded">
                <div class="grid grid-cols-8 items-center py-2 px-4 mb-px gap-4 text-slate-400 text-xs">
                    <div class="col-span-3">НАИМЕНОВАНИЕ</div>
                    <div>ТИП</div>
                    <div>РОЛЬ</div>
                    <div>ИНН</div>
                    <div>ТЕЛЕФОН</div>
                    <div class="text-center">СТАТУС</div>
                </div>
            </div>

            <template v-for="agent in agents" :key="agent">
                <div
                    class="grid grid-cols-8 items-center h-12 px-4 mb-px gap-4 text-sky-800 text-[13px] bg-white rounded hover:bg-sky-50"
                    :class="{ '!bg-teal-50 !text-teal-800 hover:!bg-teal-50': agent.status == 7 }"
                >
                    <div class="flex flex-col col-span-3 cursor-pointer" @click="pickAgent(agent), setPopup('viewAgent')">
                        <span class="text-xs text-slate-400">{{ agent?.serial }}</span>
                        <div class="text-[13px]">{{ agent?.name }}</div>
                    </div>

                    <div>
                        <div v-if="agent?.type == 'physical'">ФЛ</div>
                        <div v-if="agent?.type == 'juridical'">ЮЛ</div>
                        <div v-if="agent?.type == 'ip'">ИП</div>
                    </div>

                    <div v-if="agent?.roles">
                        <div v-for="r in JSON.parse(agent?.roles)" :key="r">{{ roles[r] }}</div>
                    </div>
                    <div v-else></div>

                    <div>{{ agent?.inn }}</div>

                    <div>{{ agent?.phone }}</div>

                    <div>
                        <div v-if="agent?.status == 1" class="flex justify-center gap-2 relative group">
                            <span class="tip">{{ tip }}</span>
                            <div
                                class="p-2 text-slate-500 hover:text-sky-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'редактировать'"
                                @mouseleave="tip = ''"
                                @click="pickAgent(agent), setPopup('editAgent')"
                            >
                                <IconEdit :size="4" />
                            </div>
                            <div
                                class="p-2 text-slate-500 hover:text-teal-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'подтвердить'"
                                @mouseleave="tip = ''"
                                @click="pickAgent(agent), setPopup('verifyAgent')"
                            >
                                <IconOk :size="4" />
                            </div>
                            <!-- <div
                                class="p-2 text-slate-500 hover:text-red-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'отклонить'"
                                @mouseleave="tip = ''"
                                @click="pickAgent(agent), setPopup('cancelAgent')"
                            >
                                <IconClose :size="4" />
                            </div> -->
                            <div
                                class="p-2 text-slate-500 hover:text-red-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'удалить'"
                                @mouseleave="tip = ''"
                                @click="pickAgent(agent), setPopup('deleteAgent')"
                            >
                                <IconDelete :size="4" />
                            </div>
                        </div>

                        <div v-if="agent?.status == 2" class="flex justify-center gap-2 relative group">
                            <span class="tip">{{ tip }}</span>
                            <div
                                class="p-2 text-slate-500 hover:text-teal-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'отправить в ОРД'"
                                @mouseleave="tip = ''"
                                @click="pickAgent(agent), setPopup('sendAgent')"
                            >
                                <IconRocket :size="4" />
                            </div>
                            <div
                                class="p-2 text-slate-500 hover:text-red-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'отклонить'"
                                @mouseleave="tip = ''"
                                @click="pickAgent(agent), setPopup('cancelAgent')"
                            >
                                <IconClose :size="4" />
                            </div>
                        </div>

                        <div v-if="agent?.status == 3" class="flex justify-center gap-2 relative group">
                            <span class="tip">{{ tip }}</span>
                            <div
                                class="p-2 text-slate-500 hover:text-sky-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'редактировать'"
                                @mouseleave="tip = ''"
                                @click="pickAgent(agent), setPopup('editAgent')"
                            >
                                <IconEdit :size="4" />
                            </div>
                        </div>

                        <div
                            v-if="agent?.status == 7"
                            @click="getAgent(agent.id)"
                            class="flex justify-center items-center gap-4 text-teal-600 cursor-pointer"
                        >
                            <IconOk :size="4" /> Уже в ОРД
                        </div>
                    </div>
                </div>
            </template>
        </template>

        <template v-if="s.view == 'contracts'">
            <!-- <div class="bg-white rounded p-4 mb-4">
                <div class="flex justify-between items-center gap-4">
                    <div class="flex justify-center items-center text-slate-500 gap-2">
                        ДОГОВОРЫ <span class="count">{{ contracts?.length }}</span>
                    </div>
                    <div class="btn btn-blue" @click="exportContracts()">ЭКСПОРТ</div> -->

            <!-- <div v-if="['Or', 'Bs', 'Lr'].includes(profile?.role)" @click="createContract()" class="btn btn-green gap-2"> -->
            <!-- <div @click="createContract()" class="btn btn-green gap-2">
                        <span class="text-white my-2"><IconPlus :size="5" /></span>
                        <span class="text-white text-xs">СОЗДАТЬ</span>
                    </div>
                </div>
            </div> -->

            <div class="bg-white rounded">
                <div class="grid grid-cols-16 items-center py-2 px-4 mb-px gap-4 text-slate-400 text-xs">
                    <div>НОМЕР</div>
                    <div class="col-span-2">ТИП</div>
                    <div class="col-span-3">ЗАКАЗЧИК</div>
                    <div class="col-span-3">ИСПОЛНИТЕЛЬ</div>
                    <div class="col-span-2">ПРЕДМЕТ</div>
                    <div class="col-span-2">ДЕЙСТВИЕ</div>
                    <div>ДАТА</div>
                </div>
            </div>

            <template v-for="contract in contracts" :key="contract">
                <div
                    class="grid grid-cols-16 items-center h-12 px-4 mb-px gap-4 text-sky-800 text-[13px] bg-white rounded hover:bg-sky-50"
                    :class="{ '!bg-teal-50 !text-teal-800 hover:!bg-teal-50': contract.status == 7 }"
                >
                    <div class="cursor-pointer" @click="pickContract(contract), setPopup('viewContract')">{{ contract.serial }}</div>

                    <div class="col-span-2">
                        <div v-if="contract.type == 'service'">Оказание услуг</div>
                        <div v-if="contract.type == 'mediation'">Посредничество</div>
                        <div v-if="contract.type == 'representation'">Самореклама</div>
                        <div v-if="contract.type == 'additional'">Дополнительное соглашение</div>
                    </div>

                    <div class="flex flex-col col-span-3">
                        <!-- <span class="text-xs text-slate-400">{{ contract.agent_id }}</span> -->
                        <div>{{ contract.agent_name }}</div>
                    </div>

                    <div class="flex flex-col col-span-3">
                        <!-- <span class="text-xs text-slate-400">{{ contract.contractor_id }}</span> -->
                        <div>{{ contract.contractor_name }}</div>
                    </div>

                    <div class="col-span-2 leading-4">
                        <div v-if="contract.subject == 'representation'">Представительство</div>
                        <div v-if="contract.subject == 'distribution'">Распространение рекламы</div>
                        <div v-if="contract.subject == 'org_distribution'">Организация распространения</div>
                        <div v-if="contract.subject == 'mediation'">Посредничество</div>
                        <div v-if="contract.subject == 'other'">Иное</div>
                    </div>

                    <div class="col-span-2 leading-4">
                        <div v-if="contract.action == 'distribution'">Распространение рекламы</div>
                        <div v-if="contract.action == 'conclude'">Заключение договоров</div>
                        <div v-if="contract.action == 'commercial'">Коммерческое представительство</div>
                        <div v-if="contract.action == 'other'">Иное</div>
                    </div>

                    <div v-if="contract.date" class="tabular-nums">{{ moment(contract.date).format("DD.MM.YYYY") }}</div>
                    <div v-else></div>

                    <div class="col-span-2">
                        <div v-if="contract.status == 1" class="flex justify-center gap-2 relative group">
                            <span class="tip">{{ tip }}</span>
                            <div
                                class="p-2 text-slate-500 hover:text-sky-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'редактировать'"
                                @mouseleave="tip = ''"
                                @click="pickContract(contract), setPopup('editContract')"
                            >
                                <IconEdit :size="4" />
                            </div>
                            <div
                                class="p-2 text-slate-500 hover:text-teal-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'подтвердить'"
                                @mouseleave="tip = ''"
                                @click="pickContract(contract), setPopup('verifyContract')"
                            >
                                <IconOk :size="4" />
                            </div>
                            <div
                                class="p-2 text-slate-500 hover:text-red-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'отклонить'"
                                @mouseleave="tip = ''"
                                @click="pickContract(contract), setPopup('cancelContract')"
                            >
                                <IconClose :size="4" />
                            </div>
                            <div
                                class="p-2 text-slate-500 hover:text-red-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'удалить'"
                                @mouseleave="tip = ''"
                                @click="pickContract(contract), setPopup('deleteContract')"
                            >
                                <IconDelete :size="4" />
                            </div>
                        </div>

                        <div v-if="contract.status == 2" class="flex justify-center gap-4 relative group">
                            <span class="tip">{{ tip }}</span>
                            <div
                                class="p-2 text-slate-500 hover:text-teal-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'отправить в ОРД'"
                                @mouseleave="tip = ''"
                                @click="pickContract(contract), setPopup('sendContract')"
                            >
                                <IconRocket :size="4" />
                            </div>
                            <div
                                class="p-2 text-slate-500 hover:text-red-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'отклонить'"
                                @mouseleave="tip = ''"
                                @click="pickContract(contract), setPopup('cancelContract')"
                            >
                                <IconClose :size="4" />
                            </div>
                        </div>

                        <div v-if="contract.status == 3" class="flex justify-center gap-2 relative group">
                            <span class="tip">{{ tip }}</span>
                            <div
                                class="p-2 text-slate-500 hover:text-sky-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                @mouseover="tip = 'редактировать'"
                                @mouseleave="tip = ''"
                                @click="pickContract(contract), setPopup('editContract')"
                            >
                                <IconEdit :size="4" />
                            </div>
                        </div>

                        <div v-if="contract.status == 7" class="flex justify-center items-center gap-4 text-teal-600">
                            <IconOk :size="4" /> Уже в ОРД
                        </div>
                    </div>
                </div>
            </template>
        </template>

        <template v-if="s.view == 'acts'">
            <div class="bg-white rounded">
                <div class="grid grid-cols-14 items-center py-2 px-4 mb-px gap-4 text-slate-400 text-xs">
                    <div>НОМЕР</div>
                    <div>ДОГОВОР</div>
                    <div class="col-span-3">ЗАКАЗЧИК</div>
                    <div class="col-span-3">ИСПОЛНИТЕЛЬ</div>
                    <div>СУММА</div>
                    <div>ДАТА</div>
                    <div>НАЧАЛО</div>
                    <div>ОКОНЧАНИЕ</div>
                </div>
            </div>

            <template v-for="act in acts" :key="act">
                <div class="bg-white rounded">
                    <div
                        class="grid grid-cols-14 items-center h-12 px-4 mb-px gap-4 text-sky-800 text-[13px] bg-white rounded hover:bg-sky-50"
                        :class="{ '!bg-teal-50 !text-teal-800 hover:!bg-teal-50': act.status == 7 }"
                    >
                        <div class="cursor-pointer" @click="pickAct(act), setPopup('viewAct')">{{ act.serial }}</div>

                        <div>{{ act.contract_serial }}</div>

                        <div class="col-span-3">
                            <div class="text-xs">
                                <div v-if="act.client_role == 'advertiser'">Рекламодатель</div>
                                <div v-if="act.client_role == 'agency'">Агентство</div>
                                <div v-if="act.client_role == 'ors'">Рекламная система</div>
                                <div v-if="act.client_role == 'publisher'">Издатель</div>
                            </div>
                            <div class="h-5 overflow-hidden">{{ act.agent_name }}</div>
                        </div>

                        <div class="col-span-3">
                            <div class="text-xs">
                                <div v-if="act.contractor_role == 'advertiser'">Рекламодатель</div>
                                <div v-if="act.contractor_role == 'agency'">Агентство</div>
                                <div v-if="act.contractor_role == 'ors'">Рекламная система</div>
                                <div v-if="act.contractor_role == 'publisher'">Издатель</div>
                            </div>
                            <div class="h-5 overflow-hidden">{{ act.contractor_name }}</div>
                        </div>

                        <div>{{ parseFloat(act.amount) }} ₽</div>

                        <div v-if="act.date">{{ moment(act.date).format("DD.MM.YYYY") }}</div>
                        <div v-else></div>

                        <div v-if="act.date_start">{{ moment(act.date_start).format("DD.MM.YYYY") }}</div>
                        <div v-else></div>

                        <div v-if="act.date_end">{{ moment(act.date_end).format("DD.MM.YYYY") }}</div>
                        <div v-else></div>

                        <div class="col-span-2">
                            <div v-if="act.status == 1" class="flex justify-center gap-4 relative group">
                                <span class="tip">{{ tip }}</span>
                                <div
                                    class="p-2 text-slate-500 hover:text-sky-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                    @mouseover="tip = 'редактировать'"
                                    @mouseleave="tip = ''"
                                    @click="pickAct(act), setPopup('editAct')"
                                >
                                    <IconEdit :size="4" />
                                </div>
                                <div
                                    class="p-2 text-slate-500 hover:text-teal-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                    @mouseover="tip = 'подтвердить'"
                                    @mouseleave="tip = ''"
                                    @click="pickAct(act), setPopup('verifyAct')"
                                >
                                    <IconOk :size="4" />
                                </div>
                                <div
                                    class="p-2 text-slate-500 hover:text-red-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                    @mouseover="tip = 'удалить'"
                                    @mouseleave="tip = ''"
                                    @click="pickAct(act), setPopup('deleteAct')"
                                >
                                    <IconDelete :size="4" />
                                </div>
                            </div>

                            <div v-if="act.status == 2" class="flex justify-center gap-4 relative group">
                                <span class="tip">{{ tip }}</span>
                                <div
                                    class="p-2 text-slate-500 hover:text-teal-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                    @mouseover="tip = 'отправить в ОРД'"
                                    @mouseleave="tip = ''"
                                    @click="pickAct(act), setPopup('sendAct')"
                                >
                                    <IconRocket :size="4" />
                                </div>
                                <div
                                    class="p-2 text-slate-500 hover:text-red-500 cursor-pointer bg-sky-50 hover:bg-white rounded"
                                    @mouseover="tip = 'отклонить'"
                                    @mouseleave="tip = ''"
                                    @click="cancelAct(act.id)"
                                >
                                    <IconClose :size="4" />
                                </div>
                            </div>

                            <div v-if="act.status == 3" class="flex justify-center gap-4 relative group">
                                <span class="tip">{{ tip }}</span>
                                <div
                                    class="p-2 text-sky-500 cursor-pointer"
                                    @mouseover="tip = 'редактировать'"
                                    @mouseleave="tip = ''"
                                    @click="pickAct(act), setPopup('editAct')"
                                >
                                    <IconEdit :size="4" />
                                </div>
                            </div>

                            <div v-if="act.status == 7" @click="getAct(act.id)" class="flex justify-center items-center gap-4 text-teal-600">
                                <IconOk :size="4" /> Уже в ОРД
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </template>
    <div v-else class="flex justify-center items-center h-screen text-cyan-400"><IconLoader :size="30" /></div>

    <template v-if="s.popup == 'verifyAgent' && agent.id"><VerifyAgent /> </template>
    <template v-if="s.popup == 'viewAgent' && agent.id"><ViewAgent /> </template>
    <template v-if="s.popup == 'editAgent' && agent.id"><EditAgent /> </template>
    <template v-if="s.popup == 'cancelAgent' && agent.id"><CancelAgent /> </template>
    <template v-if="s.popup == 'sendAgent' && agent.id"><SendAgent /> </template>
    <template v-if="s.popup == 'deleteAgent' && agent.id"><DeleteAgent /> </template>

    <template v-if="s.popup == 'viewContract' && contract.id"><ViewContract /> </template>
    <template v-if="s.popup == 'verifyContract' && contract.id"><VerifyContract /> </template>
    <template v-if="s.popup == 'editContract' && contract.id"><EditContract /> </template>
    <template v-if="s.popup == 'cancelContract' && contract.id"><CancelContract /> </template>
    <template v-if="s.popup == 'sendContract' && contract.id"><SendContract /> </template>
    <template v-if="s.popup == 'deleteContract' && contract.id"><DeleteContract /> </template>

    <template v-if="s.popup == 'previewAct' && file"><PreviewAct /> </template>
    <template v-if="s.popup == 'verifyAct' && act.id"><VerifyAct /> </template>
    <template v-if="s.popup == 'viewAct' && act.id"><ViewAct /> </template>
    <template v-if="s.popup == 'editAct' && act.id"><EditAct /> </template>
    <template v-if="s.popup == 'sendAct' && act.id"><SendAct /> </template>
    <template v-if="s.popup == 'deleteAct' && act.id"><DeleteAct /> </template>
    <div v-if="s.popup" @click="closePopup(), pickAgent(null)" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";
import Papa from "papaparse";

import ViewAgent from "../components/Ord/ViewAgent.vue";
import DeleteAgent from "../components/Ord/DeleteAgent.vue";

import ViewContract from "../components/Ord/ViewContract.vue";
import DeleteContract from "../components/Ord/DeleteContract.vue";

import PreviewAct from "../components/Ord/PreviewAct.vue";
import VerifyAct from "../components/Ord/VerifyAct.vue";
import ViewAct from "../components/Ord/ViewAct.vue";
import EditAct from "../components/Ord/EditAct.vue";
import SendAct from "../components/Ord/SendAct.vue";
import DeleteAct from "../components/Ord/DeleteAct.vue";

export default {
    name: "OrdMain",

    components: { ViewAgent, DeleteAgent, ViewContract, DeleteContract, VerifyAct, PreviewAct, ViewAct, EditAct, SendAct, DeleteAct },

    data() {
        return {
            moment: moment,
            id: "",
            tip: "",
            roles: {
                advertiser: "Рекламодатель",
                agency: "Агентство",
                ors: "Рекламная система",
                publisher: "Издатель",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "agents", "agent", "contracts", "contract", "acts", "act", "file"]),
    },

    methods: {
        ...mapActions([
            "getAllORD",
            "setPopup",
            "closePopup",
            "setView",
            "getAgent",
            "pickAgent",
            "createAgent",
            "filterAgents",
            "pickContract",
            "createContract",
            "filterContracts",
            "getAct",
            "createAct",
            "pickAct",
            "cancelAct",
            "filterActs",
            "uploadFile",
        ]),

        exportAgents() {
            let wscols = [{ wch: 10 }, { wch: 50 }, { wch: 10 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];

            let list = [];
            this.agents.forEach((item) => {
                let object = {
                    id: item.id,
                    name: item.name,
                    type: null,
                    roles: null,
                    inn: item.inn,
                    phone: item.phone,
                };
                if (item.type == "ip") object.type = "ИП";
                if (item.type == "juridical") object.type = "ООО";
                if (item.type == "physical") object.type = "Физ.лицо";

                let temp_roles = [];
                if (item.roles) {
                    JSON.parse(item.roles).forEach((role) => {
                        temp_roles.push(this.roles[role]);
                        console.log("роль", role, this.roles[role]);
                    });
                }
                if (temp_roles) object.roles = JSON.stringify(temp_roles);

                list.push(object);
            });

            let worksheet = utils.json_to_sheet(list);
            worksheet = utils.sheet_add_aoa(worksheet, [["ID", "Наименование", "Юрлицо", "Роли", "Инн", "Телефон"]], { origin: "A1" });
            worksheet["!cols"] = wscols;
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "Clients");
            writeFileXLSX(workbook, "FAQ_ORD_Agents.xlsx", { compression: true });
        },

        exportContracts() {
            let wscols = [{ wch: 10 }, { wch: 12 }, { wch: 50 }, { wch: 50 }, { wch: 50 }, { wch: 50 }, { wch: 50 }, { wch: 12 }];

            let list = [];
            this.contracts.forEach((item) => {
                let object = {
                    id: item.id,
                    serial: item.serial,
                    type: null,
                    agent_name: item.agent_name,
                    contractor_name: item.contractor_name,
                    subject: null,
                    action: null,
                    date: item.date,
                };

                if (item.type == "mediation") object.type = "посреднический договор";
                if (item.type == "service") object.type = "договор оказания услуг";
                if (item.type == "additional") object.type = "дополнительное соглашение";

                if (item.subject == "representation") object.subject = "представительство";
                if (item.subject == "mediation") object.subject = "посредничество";
                if (item.subject == "org_distribution") object.subject = "договор на организацию распространения рекламы";
                if (item.subject == "distribution") object.subject = "договор на распространение рекламы";
                if (item.subject == "other") object.subject = "иное";

                if (item.action == "distribution") object.action = "в целях распространения рекламы";
                if (item.action == "conclude") object.action = "заключение договоров";
                if (item.action == "commercial") object.action = "коммерческое представительство";
                if (item.action == "other") object.action = "иное";

                list.push(object);
            });

            let worksheet = utils.json_to_sheet(list);
            worksheet = utils.sheet_add_aoa(worksheet, [["ID", "Номер", "Тип", "Заказчик", "Исполнитель", "Предмет", "Действия", "Дата"]], {
                origin: "A1",
            });
            worksheet["!cols"] = wscols;
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "Clients");
            writeFileXLSX(workbook, "FAQ_ORD_Contracts.xlsx", { compression: true });
        },

        exportActs() {
            let wscols = [
                { wch: 10 },
                { wch: 12 },
                { wch: 12 },
                { wch: 50 },
                { wch: 50 },
                { wch: 12 },
                { wch: 12 },
                { wch: 12 },
                { wch: 12 },
                { wch: 12 },
                { wch: 12 },
            ];

            let list = [];
            this.acts.forEach((item) => {
                let object = {
                    id: item.id,
                    serial: item.serial,
                    contract_serial: item.contract_serial,
                    agent_name: item.agent_name,
                    contractor_name: item.contractor_name,
                    client_role: null,
                    contractor_role: null,
                    date: item.date,
                    amount: item.amount,
                    date_start: item.date_start,
                    date_end: item.date_end,
                };

                if (item.client_role == "advertiser") object.client_role = "Рекламодатель";
                if (item.client_role == "agency") object.client_role = "Агентство";
                if (item.client_role == "ors") object.client_role = "Рекламная система";
                if (item.client_role == "publisher") object.client_role = "Издатель";

                if (item.contractor_role == "advertiser") object.contractor_role = "Рекламодатель";
                if (item.contractor_role == "agency") object.contractor_role = "Агентство";
                if (item.contractor_role == "ors") object.contractor_role = "Рекламная система";
                if (item.contractor_role == "publisher") object.contractor_role = "Издатель";

                list.push(object);
            });

            let worksheet = utils.json_to_sheet(list);
            worksheet = utils.sheet_add_aoa(
                worksheet,
                [["ID", "НОМЕР", "ДОГОВОР", "ЗАКАЗЧИК", "ИСПОЛНИТЕЛЬ", "РОЛЬ ЗАКАЗЧИКА", "РОЛЬ ИСПОЛНИТЕЛЯ", "ДАТА", "СУММА", "НАЧАЛО", "ОКОНЧАНИЕ"]],
                {
                    origin: "A1",
                }
            );
            worksheet["!cols"] = wscols;
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "Acts");
            writeFileXLSX(workbook, "FAQ_ORD_Acts.xlsx", { compression: true });
        },

        importActs(event) {
            const data = event.e.target.files[0];

            Papa.parse(data, {
                header: true,
                complete: (results) => {
                    results.data.forEach((row) => {
                        let one = {};

                        one.agent_name = row["Конечный рекламодатель"];
                        one.contractor_name = row["Контрагент конечного рекламодателя (Исполнитель)"];
                        one.contract_serial = row["Договор акта (выбираем из списка):"];
                        one.serial = row["Номер акта *"];
                        one.client_role = row["Роль заказчика *"];
                        one.contractor_role = row["Роль исполнителя *"];
                        one.amount = row["Сумма (₽) *"].replace(/\s/g, "").replace(/,/g, ".");
                        one.item = row["НДС включён в сумму акта"];
                        one.date = row["Дата выставления *"];
                        one.date_start = row["Дата начала периода *"];
                        one.date_end = row["Дата окончания периода *"];

                        let array_contract = this.contracts.filter((contract) => contract.serial == one.contract_serial);
                        if (array_contract[0] && array_contract[0].external_id) one.contract_external_id = array_contract[0].external_id;

                        if (one.client_role == "Рекламодатель") one.client_role = "advertiser";
                        if (one.client_role == "Агентство") one.client_role = "agency";
                        if (one.client_role == "Рекламная система") one.client_role = "ors";
                        if (one.client_role == "Издатель") one.client_role = "publisher";

                        if (one.contractor_role == "Рекламодатель") one.contractor_role = "advertiser";
                        if (one.contractor_role == "Агентство") one.contractor_role = "agency";
                        if (one.contractor_role == "Рекламная система") one.contractor_role = "ors";
                        if (one.contractor_role == "Издатель") one.contractor_role = "publisher";

                        if (one.contract_external_id) this.file.push(one);
                    });

                    this.setPopup("previewAct");
                },
                error: (error) => {
                    console.error("Ошибка:", error.message);
                },
            });
        },
    },

    mounted() {
        this.getAllORD();
        if (this.s && !this.s.view) {
            this.setView("agents");
        }
    },
};
</script>
