<template>
    <div class="h-screen min-h-screen">
        <div class="p-10 mt-4 mx-auto bg-white rounded max-w-xl w-full">
            <div class="p-4 text-center">
                <div class="flex justify-center text-lg uppercase font-latobold">
                    <div class="text-gray-400 cursor-pointer mr-4" :class="{ '!text-sky-600': form == 'login' }" @click="form = 'login'">Вход</div>
                    <!-- <div class="text-gray-400 cursor-pointer" :class="{ '!text-sky-600': form == 'reg' }" @click="form = 'reg'">Регистрация</div> -->
                </div>
            </div>
            <div class="p-4">
                <form v-if="form == 'login'">
                    <input type="text" class="text-sky-600 w-full border px-5 h-9 mb-4 text-sm rounded" v-model="username" placeholder="Логин" />

                    <input
                        type="password"
                        autocomplete="on"
                        class="text-sky-600 w-full border px-5 h-9 mb-4 text-sm rounded"
                        v-model.trim="password"
                        placeholder="Пароль"
                        @keyup.enter="login({ username: username, password: password })"
                    />

                    <div class="mx-auto block bg-sky-500 hover:bg-sky-400 rounded px-5 py-3 h-9">
                        <div
                            class="text-sm font-latobold leading-none uppercase text-white text-center cursor-pointer"
                            @click="login({ username: username, password: password })"
                        >
                            Войти
                        </div>
                    </div>
                </form>

                <!-- <a
                        v-if="form == 'reg'"
                        href="https://telegram.me/BotTeachMeBot?start=reg_author__web"
                        target="_blank"
                        class="mx-auto mt-20 block v-btn group bg-sky-500 hover:bg-sky-400 rounded duration-300 transition-colors ease-out px-5 py-3 h-9"
                    >
                        <div class="text-sm font-latobold leading-none uppercase text-white flex items-center">Регистрация TELEGRAM</div>
                    </a> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "LoginPage",

    data() {
        return {
            form: "login",
            name: null,
            email: null,
            username: null,
            password: null,
        };
    },

    methods: {
        ...mapActions(["login", "reg"]),
    },
};
</script>
