import axios from "axios";
import authHeader from "./header";
const API = "https://vk.timekraken.ru/";
// const API = "http://localhost/";
class UserService {
    GET(url, data) {
        return axios.get(API + url, { params: data, headers: authHeader() }).catch(function (error) {
            if (error.response && error.response.status === 401) {
                window.location.href = "login";
            }
        });
    }
    POST(url, formData) {
        return axios.post(API + url, formData, { headers: authHeader() });
    }
}
export default new UserService();
