<template>
    <template v-if="!s.request">
        <div class="bg-white rounded">
            <template v-for="item in archive" :key="item.id">
                <div class="grid grid-cols-16 items-center gap-4 p-2 border-b border-slate-200 text-xs hover:bg-cyan-50/50 hover:!text-cyan-700">
                    <div>
                        <div v-if="item.wrong == 0" class="text-teal-600"><IconOk size="8" /></div>
                        <div
                            @mouseover="(legend = item.wrong), (currentClient = item)"
                            @mouseleave="(legend = ''), (currentClient = {})"
                            v-if="item.wrong > 0"
                            class="text-pink-600"
                        >
                            <IconWarning size="8" />
                        </div>
                    </div>

                    <div class="flex gap-4 col-span-3">
                        <a
                            class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer"
                            target="_blank"
                            :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                        >
                            <IVk />
                        </a>
                        <div>
                            <span class="text-xs text-slate-400">{{ item.id }}</span>
                            <div class="text-sm">{{ item.name }}</div>
                        </div>
                    </div>

                    <div>{{ item.label }}</div>

                    <div :class="{ '!text-pink-700': item.wrong && item.wrong[0] == 1 }">
                        <div v-if="item.client_type == 'person'">Физ. лицо</div>
                        <div v-if="item.client_type == 'individual'">ИП</div>
                        <div v-if="item.client_type == 'legal'">Юр. лицо</div>
                    </div>

                    <div :class="{ '!text-pink-700': item.wrong && item.wrong[1] == 1 }">{{ item.client_name }}</div>
                    <div :class="{ '!text-pink-700': item.wrong && item.wrong[2] == 1 }">{{ item.contract_number }}</div>

                    <div v-if="item.contract_date" :class="{ '!text-pink-700': item.wrong && item.wrong[3] == 1 }">
                        {{ moment(item.contract_date).format("DD.MM.YYYY") }}
                    </div>
                    <div v-else></div>

                    <div :class="{ '!text-pink-700': item.wrong && item.wrong[4] == 1 }">{{ item.contract_type }}</div>
                    <div :class="{ '!text-pink-700': item.wrong && item.wrong[5] == 1 }">{{ item.contract_object }}</div>

                    <div>
                        <div v-if="item.with_vat == 2">БЕЗ НДС</div>
                        <div v-if="item.with_vat == 3">С НДС</div>
                    </div>

                    <div :class="{ '!text-pink-700': item.wrong && item.wrong[6] == 1 }">{{ item.inn }}</div>
                    <div :class="{ '!text-pink-700': item.wrong && item.wrong[7] == 1 }">{{ item.phone }}</div>

                    <div>
                        <div
                            v-if="project.subagent_type && project.subagent_name"
                            class="relative flex justify-end items-center group p-4 text-xs text-slate-400 cursor-pointer"
                            @click="setSubagent(item)"
                        >
                            <input
                                type="checkbox"
                                :checked="item.subagent"
                                class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
                            />
                            <span
                                class="w-8 h-5 flex items-center flex-shrink-0 ml-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-teal-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
                            ></span>
                        </div>
                    </div>

                    <!-- <div class="flex justify-center gap-2 relative group">
                        <span class="tip">{{ tip }}</span>

                        <div
                            class="p-2 text-slate-400 hover:text-sky-500 cursor-pointer rotate-180"
                            @mouseover="tip = 'в проект'"
                            @mouseleave="tip = ''"
                            @click="unProjectClient(item)"
                        >
                            <IconBack size="5" />
                        </div>
                    </div> -->
                </div>
            </template>
        </div>

        <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-cyan-400"><IconLoader size="30" /></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "ClientsPage",

    data() {
        return {
            moment: moment,
            tip: "",
            legend: "",
            currentClient: {},
        };
    },

    computed: {
        ...mapGetters(["s", "project", "archive"]),
    },

    methods: {
        ...mapActions(["getArchive", "closePopup", "sendClientInfo", "unProjectClient", "setSubagent"]),
    },

    mounted() {
        this.getArchive();
    },
};
</script>
