<template>
    <template v-if="!s.request && ['An', 'Or', 'Bs'].includes(profile.role)">
        <div class="flex justify-between items-center my-4 gap-4">
            <div class="shrink-0 text-sm text-slate-500 uppercase">
                ПОЛЬЗОВАТЕЛИ <span class="count">{{ users?.length }}</span>
            </div>

            <input type="text" class="input" placeholder="Поиск проекта" v-model="s.findme" @input="filterUsers()" />

            <div v-if="s.clear" class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings()">ОЧИСТИТЬ</div>

            <div v-if="['Or', 'Bs', 'Lr'].includes(profile.role)" @click="createUser()" class="btn btn-green gap-2">
                <span class="text-white my-2"><IconPlus size="5" /></span>
                <span class="text-white text-xs">СОЗДАТЬ</span>
            </div>
        </div>

        <div class="bg-white rounded">
            <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-4 border-b border-slate-200 uppercase">
                <div class="flex justify-between gap-2"><span>ПРОЕКТ</span> <span>КЛИЕНТЫ</span></div>
                <div class="col-span-2">НАЗВАНИЕ</div>
                <div class="col-span-2">Роль</div>
                <div class="col-span-2">Другие роли</div>
                <div>VK</div>
                <div>TL</div>
                <div class="text-center">Email</div>

                <div class="text-center"></div>
            </div>

            <template v-for="item in users" :key="item.id">
                <div
                    class="grid grid-cols-11 items-center gap-4 p-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50"
                    :class="{ '!text-slate-400 !bg-slate-50/50': item.status == 0 }"
                >
                    <div>
                        <img
                            v-if="item?.picture"
                            class="w-10 h-10 object-cover rounded-full"
                            :src="'https://api.timekraken.ru/uploads/users/mini/' + item?.picture"
                        />
                        <img v-else class="w-10 h-10 object-cover rounded-full" src="https://api.timekraken.ru/uploads/noimg.jpg" />
                    </div>

                    <div class="col-span-2">{{ item.name }} {{ item.secondname }}</div>

                    <div class="col-span-2">{{ roles[item.role] }}</div>

                    <div class="col-span-2 text-xs">
                        <div v-for="r in JSON.parse(item.roles)" :key="r">{{ roles[r] }}</div>
                    </div>

                    <div>{{ item.vk }}</div>
                    <div>{{ item.tl }}</div>
                    <div>{{ item.email }}</div>

                    <div class="flex justify-center gap-2 relative group">
                        <div
                            v-if="['An', 'Or', 'Bs'].includes(profile.role)"
                            class="p-2 text-slate-400 hover:text-teal-500 cursor-pointer"
                            @mouseover="tip = 'редактировать'"
                            @mouseleave="tip = ''"
                            @click="pickUser(item), setPopup('editUser')"
                        >
                            <IconEdit size="5" />
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <template v-if="s.popup == 'addUser'"><AddUser /></template>
        <template v-if="s.popup == 'editUser' && user?.id"><EditUser /></template>
        <template v-if="s.popup == 'deleteUser' && user?.id"><DeleteUser /></template>
        <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-cyan-400"><IconLoader size="30" /></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "UsersPage",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "users", "user", "roles"]),
    },

    methods: {
        ...mapActions(["getUsers", "closePopup", "setPopup", "clearSettings", "filterUsers", "editUser", "pickUser", "createUser"]),
    },

    mounted() {
        this.getUsers();
    },
};
</script>
