<template>
    <!-- <div class="w-full text-center mt-4">СРЕЗЫ</div>

    <div class="flex justify-between items-center h-20 gap-4">
        <div class="btn btn-grey w-48 cursor-pointer" @click="view = 'none'">ИТОГ</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'targetolog'">Таргетолог</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'client'">Клиент</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'channel'">Канал</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'template'">Креатив</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'group'">Группа</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'land'">Лендинг/бот</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'voronka'">Воронка/дожим</div>
        <div class="btn btn-green w-48 cursor-pointer" @click="view = 'pay'">Оплата</div>
        <div class="btn btn-yellow w-48 cursor-pointer" @click="view = 'result'">Связки</div>
    </div>

    <hr class="my-4" /> -->

    <div class="flex justify-between items-center mb-4 gap-2">
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2023-01-01', end: moment().format('YYYY-MM-DD') })"
        >
            ВСЕГО
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{ '!bg-cyan-500 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            СЕЙЧАС
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })"
        >
            ВЧЕРА
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{
                '!bg-cyan-500 !text-white':
                    s.start == moment().subtract(2, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(2, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(2, 'days').format('YYYY-MM-DD'), end: moment().subtract(2, 'days').format('YYYY-MM-DD') })"
        >
            ПОЗАВЧ
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
            :class="{
                '!bg-cyan-500 !text-white': s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            7 ДНЕЙ
        </div>
        <template v-for="(value, key) in select.monthShort" :key="key">
            <div
                class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 bg-white rounded w-10 h-10 uppercase"
                :class="{ '!bg-cyan-500 !text-white': s.month == key }"
                @click="setMonth(key)"
            >
                {{ value }}
            </div>
        </template>

        <input type="date" class="input text-xs w-32 bg-white" @change="setSettings(s)" v-model="s.start" />
        <input type="date" class="input text-xs w-32 bg-white" @change="setSettings(s)" v-model="s.end" />
    </div>

    <!-- <div class="w-full text-center mt-4">ФИЛЬТР</div>

    <div class="grid grid-cols-10 justify-between items-center h-20 gap-4">
        <select v-model="currentUser" class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <template v-for="u in users" :key="u">
                <option v-if="u.id == currentUser.id" selected :value="u">{{ u.name }}</option>
                <option v-else :value="u">{{ u.name }}</option>
            </template>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Клиент</option>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Креатив</option>
        </select>

        <select v-model="currentChannel" class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <template v-for="ch in channels" :key="ch">
                <option v-if="ch.id == currentChannel.id" selected :value="ch">{{ ch.name }}</option>
                <option v-else :value="ch">{{ ch.name }}</option>
            </template>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Группа</option>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Лендинг</option>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Воронка</option>
        </select>

        <select class="bg-white h-9 px-3 py-2 rounded text-sm text-teal-700">
            <option selected :value="1">Продукт</option>
        </select>

        <input type="date" class="input h-9 text-sm bg-white" v-model="s.start" />
        <input type="date" class="input h-9 text-sm bg-white" v-model="s.end" />
    </div> -->

    <hr class="my-4" />

    <template v-if="view == 'none'">
        <div class="w-full text-center mt-4">ИТОГОВЫЙ РЕЗУЛЬТАТ</div>

        <div v-if="today?.data" class="grid grid-cols-4 gap-4 mt-4">
            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <!-- <span class="title">ЛИДЫ</span> -->
                <!-- <span class="num text-4xl">{{ parseInt(today.data.regs).toLocaleString() }}</span>
                <div class="flex items-center gap-2">
                    <div class="text-pink-600"><IconDown :size="6" /></div>
                    <div class="flex flex-col items-center">
                        <span class="title text-pink-600">МЕНЬШЕ НА</span>
                        <span class="text-pink-600 text-sm">{{ parseInt(1320).toLocaleString() }}</span>
                    </div>
                </div> -->
            </div>

            <div class="col-span-2 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">БЮДЖЕТ ОТКРУЧЕН</span>

                <span class="num text-6xl">{{ parseInt(today.data.spent).toLocaleString() }} ₽</span>

                <!-- <div class="flex items-center gap-2">
                    <div class="text-teal-600"><IconUp :size="6" /></div>
                    <div class="flex flex-col items-center">
                        <span class="title text-teal-600">БОЛЬШЕ НА</span>
                        <span class="text-teal-600 text-sm">{{ parseInt(2160000).toLocaleString() }} ₽</span>
                    </div>
                </div> -->

                <div v-if="today.graf" class="w-full"><LineGraf :graf="today.graf" h="h-28" /></div>
            </div>

            <div class="flex justify-center items-center gap-4 bg-white rounded p-8">
                <!-- <div class="text-pink-600"><IconWarning :size="8" /></div> -->

                <div class="flex justify-center items-center flex-col">
                    <!-- <span class="title">СТОИМОСТЬ ЛИДА</span> -->
                    <!-- <span v-if="today.data.spent / today.data.regs > 0" class="num text-pink-600 text-4xl"
                        >{{ parseFloat(today.data.spent / today.data.regs).toLocaleString() }} ₽</span
                    >
                    <span v-else class="num text-pink-600 text-4xl"> --- </span> -->
                    <!-- <span class="title text-pink-600">ВЫШЕ KPI НА 40 ₽</span> -->
                </div>
            </div>
        </div>

        <div class="gap-4 mt-4">
            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <div class="flex justify-between items-center gap-4 w-full">
                    <span class="title">CPM & CPC с 2019 года</span>
                    <div class="flex justify-center gap-4">
                        <!-- <div class="flex justify-end items-center gap-4">
                            <div
                                v-for="cabinet in cabinets"
                                :key="cabinet"
                                @click="radiobtn = 'clicks'"
                                class="btn-xs btn-grey"
                                :class="{ '!bg-sky-500': radiobtn == 'clicks' }"
                            >
                                {{ cabinet.label }}
                            </div>
                        </div> -->

                        <div class="flex justify-end items-center gap-4">
                            <div @click="radiobtn = 'impressions'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'impressions' }">
                                ПРОСМОТРЫ
                            </div>
                            <div @click="radiobtn = 'clicks'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'clicks' }">КЛИКИ</div>
                            <div @click="radiobtn = 'spent'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'spent' }">ОТКРУТ</div>
                            <div @click="radiobtn = 'cpm'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'cpm' }">CPM</div>
                            <div @click="radiobtn = 'cpc'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'cpc' }">CPC</div>
                        </div>
                    </div>
                </div>

                <!-- <div class="w-full grid grid-cols-13 gap-4 mt-4 border-b border-slate-100 text-xs p-2 text-slate-400">
                    <div class="text-center">КАБИНЕТ</div>
                    <div class="text-center">ЯНВ</div>
                    <div class="text-center">ФЕВ</div>
                    <div class="text-center">МАР</div>
                    <div class="text-center">АПР</div>
                    <div class="text-center">МАЙ</div>
                    <div class="text-center">ИЮН</div>
                    <div class="text-center">ИЮЛ</div>
                    <div class="text-center">АВГ</div>
                    <div class="text-center">СЕН</div>
                    <div class="text-center">ОТК</div>
                    <div class="text-center">НОЯ</div>
                    <div class="text-center">ДЕК</div>
                </div> -->

                <div v-if="cpmcpc" class="w-full col-span-12"><MultiLineGraf :graf="cpmcpc" /></div>

                <!-- <div v-for="cabinet in cpmcpc" :key="cabinet.id" class="w-full">
                    <div v-if="cabinet.dates" class="w-full col-span-12"><LineGraf :graf="cabinet.dates" :id="cabinet.id" /></div>
                </div> -->

                <!-- <div v-for="cabinet in cpmcpc" :key="cabinet.id" class="w-full grid grid-cols-13 border-b border-slate-100">
                    <div class="p-2">{{ cabinet.label }}</div>

                    <div v-for="d in cabinet.dates" :key="d" class="p-2 border-l border-slate-200">
                        <div v-if="radiobtn == 'cpm'" class="text-center">
                            <div v-if="d.impressions > 0">{{ parseInt(d.spent / (d.impressions / 1000)).toLocaleString() }}</div>
                            <div v-else>0</div>
                        </div>
                        <div v-if="radiobtn == 'cpc'" class="text-center">
                            <div v-if="d.clicks > 0">{{ parseInt(d.spent / d.clicks).toLocaleString() }}</div>
                            <div v-else>0</div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="grid grid-cols-2 gap-4 mt-4">
            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">КАБИНЕТЫ</span>
                <div class="w-full grid grid-cols-11 gap-4 mt-4 border-b border-slate-100 text-xs text-slate-400">
                    <div>КАБИНЕТ</div>
                    <div class="text-right tabular-nums col-span-2">ПОКАЗЫ</div>
                    <div class="text-right tabular-nums col-span-2">КЛИКИ</div>
                    <div class="text-right tabular-nums col-span-2">ОТКРУТ ₽</div>
                    <div class="text-right tabular-nums col-span-2">CPM ₽</div>
                    <div class="text-right tabular-nums col-span-2">CPC ₽</div>
                </div>

                <div v-for="cabinet in cabs" :key="cabinet" class="w-full grid grid-cols-11 gap-4 mt-4 border-b border-slate-100">
                    <div>{{ cabinet.label }}</div>
                    <div class="text-right tabular-nums col-span-2">{{ parseInt(cabinet.impressions).toLocaleString() }}</div>
                    <div class="text-right tabular-nums col-span-2">{{ parseInt(cabinet.clicks).toLocaleString() }}</div>
                    <div class="text-right tabular-nums col-span-2">{{ parseInt(cabinet.spent).toLocaleString() }}</div>
                    <div class="text-right tabular-nums col-span-2">
                        <div v-if="cabinet.impressions > 0">{{ parseInt(cabinet.spent / (cabinet.impressions / 1000)).toLocaleString() }}</div>
                        <div v-else>0</div>
                    </div>
                    <div class="text-right tabular-nums col-span-2">
                        <div v-if="cabinet.clicks > 0">{{ parseInt(cabinet.spent / cabinet.clicks).toLocaleString() }}</div>
                        <div v-else>0</div>
                    </div>
                </div>
            </div>

            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">ТАРГЕТОЛОГИ</span>
                <div class="w-full grid grid-cols-9 gap-4 mt-4 border-b border-slate-100 text-xs text-slate-400">
                    <div>ТАРГЕТОЛОГ</div>
                    <div class="text-right tabular-nums col-span-2">ПОКАЗЫ</div>
                    <div class="text-right tabular-nums col-span-2">КЛИКИ</div>
                    <div class="text-right tabular-nums col-span-2">ОТКРУТ</div>
                </div>

                <div v-for="item in targetologs" :key="item" class="w-full grid grid-cols-9 gap-4 mt-4 border-b border-slate-100">
                    <div>{{ item.tid }}</div>
                    <div class="text-right tabular-nums col-span-2">{{ parseInt(item.impressions).toLocaleString() }}</div>
                    <div class="text-right tabular-nums col-span-2">{{ parseInt(item.clicks).toLocaleString() }}</div>
                    <div class="text-right tabular-nums col-span-2">{{ parseFloat(item.spent).toLocaleString() }} ₽</div>
                </div>
            </div>
        </div>

        <!-- <div class="grid grid-cols-4 gap-4 mt-4">
            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">КРЕАТИВЫ</span>
                <span class="num text-3xl">{{ 23 }}</span>
            </div>

            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">РАЗМЕЩЕНИЯ</span>
                <span class="num text-3xl">{{ 118 }}</span>
            </div>

            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">АКТИВНЫЕ КЛИЕНТЫ</span>
                <span class="num text-3xl">{{ 6 }}</span>
            </div>

            <div class="bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">АКТИВНЫЕ ТАРГЕТОЛОГИ</span>
                <span class="num text-3xl">{{ 4 }}</span>

                <div class="flex justify-between w-full">
                    <template v-for="item in users" :key="item">
                        <div class="flex flex-col items-center cursor-pointer text-xs text-slate-400">
                            <img
                                v-if="item.picture"
                                :src="'https://api.timekraken.ru/uploads/users/mini/' + item.picture"
                                alt=""
                                class="rounded-full w-8 h-8"
                            />
                            <img v-else :src="'https://api.timekraken.ru/uploads/nophoto.jpg'" alt="" class="rounded-full w-8 h-8" />
                            <span>{{ item.name }}</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="flex flex-row gap-4 mt-4">
            <div class="w-full bg-white flex justify-center items-center rounded p-8">
                <div class="w-1/5 flex flex-col justify-center items-center">
                    <span class="title">ОХВАТ</span>
                    <span class="num text-xl">{{ parseInt(593610).toLocaleString() }}</span>
                </div>
                <div class="w-1/5 flex flex-col justify-center items-center">
                    <span class="title">ЛАЙКИ</span>
                    <span class="num text-xl">{{ parseInt(571).toLocaleString() }}</span>
                </div>
                <div class="w-1/5 flex flex-col justify-center items-center">
                    <span class="title">РЕПОСТЫ</span>
                    <span class="num text-xl">{{ parseInt(130).toLocaleString() }}</span>
                </div>
                <div class="w-1/5 flex flex-col justify-center items-center">
                    <span class="title">КОММЕНТЫ</span>
                    <span class="num text-xl">{{ parseInt(51).toLocaleString() }}</span>
                </div>
                <div class="w-1/5 flex flex-col justify-center items-center">
                    <span class="title">КЛИКИ</span>
                    <span class="num text-xl">{{ parseInt(592).toLocaleString() }}</span>
                </div>
            </div>
        </div> -->
    </template>

    <!-- 
    <template v-if="view == 'targetolog'">
        <div class="w-full text-center mt-4">СРЕЗ: ТАРГЕТОЛОГИ</div>

        <div class="flex flex-row gap-4 mt-4">
            <div class="basis-3/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">
                        <img src="https://api.timekraken.ru/uploads/users/mini/u6.jpg" class="w-10 h-10 rounded object-cover" />Александр
                    </div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">80 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">2</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">100</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">10</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">380</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">
                        <img src="https://api.timekraken.ru/uploads/users/mini/u7.jpg" class="w-10 h-10 rounded object-cover" /> Юрий
                    </div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">63 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">4</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">98</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">8</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">320</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">
                        <img src="https://api.timekraken.ru/uploads/users/mini/u9.jpg" class="w-10 h-10 rounded object-cover" /> Настя
                    </div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">10 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">1</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">10</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">1</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">700</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200 bg-slate-100">
                    <div class="col-span-2">ИТОГ</div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">153 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">7</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">208</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">19</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">354</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>
            </div>

            <div class="w-1/4 bg-white flex flex-col justify-center items-center rounded p-8">
                <span class="title">ГРАФИК</span>
                <PieChart />
            </div>
        </div>
    </template>

    <template v-if="view == 'channel'">
        <div class="w-full text-center mt-4">СРЕЗ: КАНАЛЫ</div>

        <div class="flex flex-row gap-4 mt-4">
            <div class="basis-3/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">ЯНДЕКС</div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">80 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">2</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">100</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">10</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">380</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">VK API</div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">63 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">4</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">98</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">8</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">320</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200">
                    <div class="flex items-center gap-2 col-span-2">МАРКЕТ</div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">100 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">1</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">10</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">1</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">700</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>

                <div class="w-full grid grid-cols-7 items-center gap-8 p-2 text-sm border-b border-slate-200 bg-slate-100">
                    <div class="col-span-2">ИТОГ</div>

                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">243 000 ₽</div>
                        <span class="text-[8px] text-slate-400">ПОТРАЧЕНО</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">7</div>
                        <span class="text-[8px] text-slate-400">КРЕАТИВЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">208</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">19</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>
                    <div class="text-center">
                        <div class="text-lg leading-4 pt-2">354</div>
                        <span class="text-[8px] text-slate-400">₽ ЗА ЛИДА</span>
                    </div>
                </div>
            </div>

            <div class="w-1/4 bg-white flex flex-col justify-center items-center rounded p-8">
                <span class="title">ГРАФИК</span>
                <PieChannel />
            </div>
        </div>
    </template>

    <template v-if="view == 'result'">
        <div class="w-full text-center mt-4">СВЯЗКИ</div>

        <div class="grid grid-cols-4 gap-4 mt-4">
            <div class="flex flex-col justify-center items-center bg-white rounded p-8">
                <span class="title mb-8">ТАРГЕТОЛОГ: СТОИМОСТЬ ЛИДА</span>
                <div class="flex justify-center items-center gap-20">
                    <div class="flex justify-center items-center flex-col">
                        <span class="flex items-center gap-2 text-sm text-teal-600 mb-4"> <IconGood :size="4" />ЛУЧШИЙ</span>
                        <img src="https://api.timekraken.ru/uploads/users/u6.jpg" class="w-20 h-20 mb-4 rounded object-cover" />
                        <span class="num text-teal-600 text-3xl">{{ 285 }} ₽</span>
                    </div>

                    <div class="flex justify-center items-center flex-col">
                        <span class="flex items-center gap-2 text-sm text-pink-600 mb-4"><IconBad :size="4" />ХУДШИЙ</span>
                        <img src="https://api.timekraken.ru/uploads/users/u9.jpg" class="w-20 h-20 mb-4 rounded object-cover" />
                        <span class="num text-pink-600 text-3xl">{{ 690 }} ₽</span>
                    </div>
                </div>
            </div>

            <div class="col-span-2 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title mb-8">ТАРГЕТОЛОГ -> КЛИЕНТ -> КАНАЛ</span>

                <div class="grid grid-cols-5 justify-center items-center text-center gap-8 mb-4">
                    <span class="flex items-center gap-2 text-sm text-teal-600"> <IconGood :size="4" />ЛУЧШИЙ</span>
                    <img src="https://api.timekraken.ru/uploads/users/u6.jpg" class="w-20 h-20 rounded object-cover" />
                    <span class="text-center text-teal-600 text-sm font-bold">3ДКлуб Моделинг - 1</span>
                    <span class="text-center text-teal-600 text-sm font-bold">МАКРЕТ ПЛАТФОРМА</span>
                    <span class="num text-teal-600 text-3xl">{{ 250 }} ₽</span>
                </div>

                <div class="grid grid-cols-5 justify-center items-center text-center gap-8">
                    <span class="flex items-center gap-2 text-sm text-pink-600 mb-4"><IconBad :size="4" />ХУДШИЙ</span>
                    <img src="https://api.timekraken.ru/uploads/users/u6.jpg" class="w-20 h-20 rounded object-cover" />
                    <span class="text-center text-pink-600 text-sm font-bold">ХРИСТОСЕНКО СОЦСЕТИ - 1</span>
                    <span class="text-center text-pink-600 text-sm font-bold">VK API</span>
                    <span class="num text-pink-600 text-3xl">{{ 850 }} ₽</span>
                </div>
            </div>

            <div class="flex flex-col justify-center items-center bg-white rounded p-8">
                <span class="title mb-8">КЛИЕНТ: СТОИМОСТЬ ЛИДА</span>
                <div class="flex justify-center items-center gap-20">
                    <div class="flex justify-center items-center flex-col">
                        <span class="flex items-center gap-2 text-sm text-teal-600 mb-4"> <IconGood :size="4" />ЛУЧШИЙ</span>

                        <span class="flex text-center text-teal-600 text-sm font-bold h-20 items-center">3ДКлуб Моделинг - 1</span>
                        <span class="num text-teal-600 text-3xl">{{ 130 }} ₽</span>
                    </div>

                    <div class="flex justify-center items-center flex-col">
                        <span class="flex items-center gap-2 text-sm text-pink-600 mb-4"><IconBad :size="4" />ХУДШИЙ</span>
                        <span class="flex text-center text-pink-600 text-sm font-bold h-20 items-center">ХРИСТОСЕНКО СОЦСЕТИ - 1</span>
                        <span class="num text-pink-600 text-3xl">{{ 1280 }} ₽</span>
                    </div>
                </div>
            </div>
        </div>
    </template> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import MultiLineGraf from "../components/Graf/MultiLineGraf.vue";

export default {
    name: "MainStat",

    components: { MultiLineGraf },

    data() {
        return {
            month: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
            moment: moment,

            // users: [
            //     { id: 0, name: "Таргетолог" },
            //     { id: 6, name: "Александр" },
            //     { id: 7, name: "Юрий" },
            //     { id: 9, name: "Настя" },
            // ],
            channels: [
                { id: 0, name: "Канал" },
                { id: 1, name: "Vk" },
                { id: 2, name: "Яндекс" },
                { id: 3, name: "YouTube" },
            ],

            cabinets: [
                { id: 15210968, label: "ВКР", color: "rgb(244, 67, 54)" },
                { id: 1900014679, label: "ВИТ", color: "rgb(233, 30, 99)" },
                { id: 1900000062, label: "СТ2", color: "rgb(156, 39, 176)" },
                { id: 1900014266, label: "ТМ", color: "rgb(63, 81, 181)" },
                { id: 1900015316, label: "СТ4", color: "rgb(3, 169, 244)" },
                { id: 1900014874, label: "СТ3", color: "rgb(0, 150, 136)" },
            ],

            view: "none",
            currentUser: { id: 0, name: "Таргетолог" },
            currentChannel: { id: 0, name: "Канал" },
            radiobtn: "cpc",
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "select", "stats", "users"]),

        today() {
            if (!this.stats || this.stats.length < 1) return null;

            // let today = moment().format("YYYY-MM-DD");
            // let end = moment(this.stats[0].date).format("YYYY-MM-DD");

            let result = { impressions: 0, clicks: 0, regs: 0, spent: 0 };
            let graf = [];

            this.stats.forEach((stat) => {
                if (stat.type == 1) {
                    if (stat.date >= this.s.start && stat.date <= this.s.end) {
                        result.impressions += stat.impressions;
                        result.clicks += stat.clicks;
                        result.regs += stat.regs;
                        result.spent += stat.spent / 100;
                    }
                    graf.push({ time: stat.date, value: stat.spent / 100 });
                }
            });

            graf.reverse();

            // let srt = moment().subtract(30, "days").format("YYYY-MM-DD");
            // let end = moment().format("YYYY-MM-DD");

            // while (srt <= end) {
            //     this.graf.forEach((day) => {
            //         if (moment(day.date).format("YYYY-MM-DD") == srt) {
            //             graf.push({ time: day, value: day.spent / 100 });
            //         }
            //         srt = moment(srt).add(1, "days").format("YYYY-MM-DD");
            //     });
            // }

            return { data: result, graf: graf }; // .slice(0, 30)
        },

        cabs() {
            if (!this.stats || this.stats.length < 1) return null;

            let result = {
                15210968: { id: 15210968, label: "ВКР", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                1900014679: { id: 1900014679, label: "ВИТ", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                1900000062: { id: 1900000062, label: "СТ2", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                1900014266: { id: 1900014266, label: "ТМ", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                1900015316: { id: 1900015316, label: "СТ4", impressions: 0, clicks: 0, regs: 0, spent: 0 },
                1900014874: { id: 1900014874, label: "СТ3", impressions: 0, clicks: 0, regs: 0, spent: 0 },
            };

            let stats = this.stats.filter((item) => item.type == 3);

            this.cabinets.forEach((cabinet) => {
                // result[cabinet.id] = { cabinet_id: cabinet.id, impressions: 0, clicks: 0, regs: 0, spent: 0 };
                stats.forEach((stat) => {
                    if (cabinet.id == stat.object_id)
                        if (stat.date >= this.s.start && stat.date <= this.s.end) {
                            result[cabinet.id].impressions += stat.impressions;
                            result[cabinet.id].clicks += stat.clicks;
                            result[cabinet.id].regs += stat.regs;
                            result[cabinet.id].spent += stat.spent / 100;
                        }
                });
            });

            // stats.forEach((stat) => {
            //     if (!result[stat.object_id]) result[stat.object_id] = { cabinet_id: stat.object_id, impressions: 0, clicks: 0, regs: 0, spent: 0 };
            //     // result[stat.object_id] = result[stat.object_id] ?? { cabinet_id: stat.object_id, impressions: 0, clicks: 0, regs: 0, spent: 0 };

            //     // if (stat.date >= this.s.start && stat.date <= this.s.end) {
            //     if (stat.date >= "2023-01-01" && stat.date <= "2023-01-31") {
            //         result[stat.object_id].impressions += stat.impressions;
            //         result[stat.object_id].clicks += stat.clicks;
            //         result[stat.object_id].regs += stat.regs;
            //         result[stat.object_id].spent += stat.spent / 100;
            //     }
            // });

            // result = result.filter((item) => parseInt(item.spent) > 0);

            // console.log("cabinets", result);

            return result;
        },

        // CPM & CPC для каждого кабинета помесячно с 2022
        cpmcpc() {
            if (!this.stats || this.stats.length < 1) return null;

            let r = [];

            let stats = this.stats.filter((item) => item.type == 3);

            let srt = moment("2019-01-01").format("YYYY-MM");
            let end = moment().format("YYYY-MM");

            this.cabinets.forEach((cabinet) => {
                let cab = { id: cabinet.id, label: cabinet.label, dates: [] };
                while (srt <= end) {
                    let d = { time: moment(srt).format("YYYY-MM-DD"), impressions: 0, clicks: 0, regs: 0, spent: 0 };
                    stats.forEach((stat) => {
                        if (cabinet.id == stat.object_id)
                            if (stat.date.slice(0, 7) == srt) {
                                d.impressions += stat.impressions;
                                d.clicks += stat.clicks;
                                d.regs += stat.regs;
                                d.spent += stat.spent / 100;
                            }
                    });

                    d.cpm = 0;
                    d.cpc = 0;
                    if (d.impressions > 0) d.cpm = parseInt(d.spent / (d.impressions / 1000));
                    if (d.clicks > 0) d.cpc = parseInt(d.spent / d.clicks) ?? 0;

                    // для графика
                    if (this.radiobtn == "impressions") d.value = d.impressions;
                    if (this.radiobtn == "clicks") d.value = d.clicks;
                    if (this.radiobtn == "spent") d.value = d.spent;
                    if (this.radiobtn == "cpc") d.value = d.cpc;
                    if (this.radiobtn == "cpm") d.value = d.cpm;

                    cab.dates.push(d);
                    srt = moment(srt).add(1, "month").format("YYYY-MM");
                }
                r.push(cab);
                srt = moment("2019-01-01").format("YYYY-MM");
            });

            return r;
        },

        targetologs() {
            if (!this.stats || this.stats.length < 1) return null;

            let stats = this.stats.filter((item) => item.type == 8);

            let result = stats.reduce((acc, stat) => {
                if (stat.date >= this.s.start && stat.date <= this.s.end && stat.object_id > 0) {
                    if (!acc[stat.object_id]) {
                        acc[stat.object_id] = { tid: stat.object_id, impressions: 0, clicks: 0, regs: 0, spent: 0 };
                    }

                    acc[stat.object_id].impressions += stat.impressions;
                    acc[stat.object_id].clicks += stat.clicks;
                    acc[stat.object_id].regs += stat.regs;
                    acc[stat.object_id].spent += parseInt(stat.spent / 100);
                }
                return acc;
            }, {});

            result = Object.values(result);

            result?.sort((a, b) => parseFloat(b.spent) - parseFloat(a.spent));

            // console.log("targetologs", result);

            return result;
        },
    },

    methods: {
        ...mapActions(["getAllStats", "setPopup", "closePopup", "setSettings", "setPeriod", "setMonth", "setTargetolog"]),
    },

    mounted() {
        this.getAllStats();
        this.setPeriod({ start: moment().format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD") });
    },
};
</script>
