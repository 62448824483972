<template>
    <div class="w-1/2 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-200px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-sky-600 text-white">ОТПРАВИТЬ ДОГОВОР В ОРД</h3>

        <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Номер договора</label>
                <div>{{ contract.serial }}</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Тип договора</label>
                <div v-if="contract.type == 'mediation'">Посредничество</div>
                <div v-if="contract.type == 'service'">Оказание услуг</div>
                <div v-if="contract.type == 'additional'">Доп соглашение</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Заказчик</label>
                <div>{{ contract.agent_name }}</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Исполнитель</label>
                <div>{{ contract.contractor_name }}</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Предмет договора</label>
                <div v-if="contract.subject == 'representation'">представительство</div>
                <div v-if="contract.subject == 'mediation'">посредничество</div>
                <div v-if="contract.subject == 'org_distribution'">организацию распространения рекламы</div>
                <div v-if="contract.subject == 'distribution'">на распространение рекламы</div>
                <div v-if="contract.subject == 'other'">иное</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Осуществляемые действия</label>
                <div v-if="contract.action == 'distribution'">в целях распространения рекламы</div>
                <div v-if="contract.action == 'conclude'">заключение договоров</div>
                <div v-if="contract.action == 'commercial'">коммерческое представительство</div>
                <div v-if="contract.action == 'other'">иное</div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-slate-400 mb-1">Дата договора</label>
                <div>{{ contract.date }}</div>
            </div>

            <hr />
            <p class="text-left text-sm text-slate-400 my-2">Дополнительно</p>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Статус</label>
                <div v-if="contract.status == 1" class="text-slate-400">Создан</div>
                <div v-if="contract.status == 2" class="text-slate-400">Проверен</div>
                <div v-if="contract.status == 3" class="text-slate-400">Отклонен</div>
                <div v-if="contract.status == 7" class="text-slate-400">Отправлен в ОРД</div>
            </div>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Внешний ID</label>
                <div class="text-slate-400">{{ contract.external_id }}</div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="sendContract(contract.id), closePopup(), pickContract(null)">Отправить в ОРД</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "SendContract",

    data() {
        return {
            moment: moment,
            roles: {
                advertiser: "Рекламодатель",
                agency: "Агентство",
                ors: "Рекламная система",
                publisher: "Издатель",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "contract"]),
    },

    methods: {
        ...mapActions(["sendContract", "closePopup", "pickContract"]),
    },
};
</script>
