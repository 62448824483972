<template>
    <template v-if="!s.request">
        <div class="flex justify-between items-center gap-4 bg-white rounded px-4 py-2 mb-4">
            <div class="flex justify-center items-center text-slate-500 gap-2 uppercase">
                <span class="count count-amber">{{ projects?.count }}</span>

                <div class="btn btn-green h-6 p-0 aspect-square cursor-pointer" @click="checkErids(1)">
                    <IconRe :size="4" />
                </div>

                ОБЪЯВЛЕНИЯ БЕЗ ERID
                <!-- "запись в сообществе" -->
            </div>

            <div class="relative flex justify-end items-center gap-2 group p-1 text-xs text-slate-400 cursor-pointer" @click="filterArchive()">
                Архив
                <input
                    type="checkbox"
                    :checked="active"
                    class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
                />
                <span
                    class="w-8 h-5 flex items-center flex-shrink-0 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-teal-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
                ></span>
                Активные
            </div>

            <!-- <div class="flex justify-center items-center gap-4 text-xs">
                <div class="flex items-center gap-2">
                    <div class="circle bg-teal-500"></div>
                    Активные
                </div>
                <div class="flex items-center gap-2">
                    <div class="circle bg-slate-500"></div>
                    Архив
                </div>
            </div> -->

            <div class="btn btn-blue gap-2" @click="exportErids()">
                <IconDownload :size="5" />
            </div>
        </div>

        <template v-if="projects?.list">
            <template v-for="project in projects?.list" :key="project">
                <template v-if="project.id && project.id > 0">
                    <div class="bg-white rounded mt-8">
                        <div class="flex justify-between items-center h-12 px-4 gap-4 text-lg text-sky-800 border-b border-slate-200">
                            <div v-if="project.id == 1000000">БЕЗ ПРОЕКТА</div>
                            <div v-else>
                                <span class="text-xs">{{ project.id }}</span> {{ project.name }}
                            </div>
                            <div @click="closeItem(project.id)" class="cursor-pointer" :class="{ 'rotate-180': closeIds.has(project.id) }">
                                <IconAltDown :size="6" />
                            </div>
                        </div>
                        <template v-if="!closeIds.has(project.id)">
                            <template v-for="client in project.clients" :key="client">
                                <div class="flex justify-between items-center h-12 px-4 gap-4 text-md text-slate-500 border-b border-slate-200">
                                    <div class="flex justify-between items-center gap-4">
                                        <span @click="closeItem(client.id)" class="count count-amber mr-4 cursor-pointer">
                                            {{ client.items?.length }}
                                        </span>
                                        <div class="btn btn-green h-6 p-0 aspect-square cursor-pointer" @click="checkErids(client.id)">
                                            <IconRe :size="4" />
                                        </div>
                                        <span class="text-xs">{{ client.id }}</span>
                                        {{ client.name }}
                                    </div>

                                    <div @click="closeItem(client.id)" class="cursor-pointer" :class="{ 'rotate-180': closeIds.has(client.id) }">
                                        <IconAltDown :size="6" />
                                    </div>
                                </div>
                                <template v-if="!closeIds.has(client.id)">
                                    <template v-for="item in client.items" :key="item.id">
                                        <div
                                            class="h-12 overflow-hidden grid grid-cols-12 items-center gap-4 px-4 border-b border-slate-200 text-[13px] hover:bg-sky-50/50 hover:!text-sky-700"
                                        >
                                            <div>
                                                <a
                                                    v-if="item.url"
                                                    class="w-full rounded-r-full rounded-tl-full bg-amber-400 py-1 px-3 text-center text-md text-white cursor-pointer"
                                                    target="_blank"
                                                    :href="item.url"
                                                >
                                                    смотреть
                                                </a>
                                            </div>

                                            <div class="flex items-center gap-4 col-span-5">
                                                <a
                                                    class="text-2xl text-sky-600 font-bold text-right cursor-pointer"
                                                    target="_blank"
                                                    :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                                                >
                                                    <IVk />
                                                </a>
                                                <div>
                                                    <span class="text-xs text-slate-400">{{ item.id }}</span>
                                                    <div>{{ item.name }}</div>
                                                </div>
                                            </div>

                                            <div class="col-span-2">{{ item.tname }} {{ item.tsecondname }}</div>

                                            <!-- <div>
                                                <div v-if="item.created">{{ moment.unix(item.created).format("DD.MM.YYYY") }}</div>
                                            </div> -->

                                            <div class="flex justify-end items-center gap-2">
                                                {{ parseInt(item.impressions) }}
                                                <span class="text-slate-400"> <IconEye :size="3" /></span>
                                            </div>
                                            <div class="flex justify-end items-center gap-2">
                                                {{ parseInt(item.clicks) }}
                                                <span class="text-slate-400"><IconClick :size="3" /></span>
                                            </div>
                                            <div class="flex justify-end items-center gap-2">
                                                {{ parseFloat(item.spent / 100) }}
                                                <span class="text-slate-400">₽</span>
                                            </div>

                                            <div class="flex justify-end items-center gap-4 mr-1 text-right text-xs text-slate-400">
                                                <div v-if="moment(item.updated_at).format('DD.MM') == moment().format('DD.MM')">
                                                    сегодня <br />{{ moment(item.updated_at).format("HH:mm") }}
                                                </div>
                                                <div v-else>
                                                    {{ moment(item.updated_at).format("DD.MM HH:mm") }}
                                                </div>

                                                <div v-if="item.status == 1" class="circle bg-teal-500"></div>
                                                <div v-if="item.status == 0" class="circle bg-pink-500"></div>
                                                <div v-if="item.status == 2" class="circle bg-slate-500"></div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </div>
                </template>
            </template>
        </template>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-sky-400"><IconLoader :size="30" /></div>

    <template v-if="s.popup == 'addClient'"><AddClient /></template>
    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";

export default {
    name: "MyAds",

    data() {
        return {
            active: false,
            moment: moment,
            closeIds: new Set(),
            filtredErids: [],

            ad_formats: {
                1: "изображение и текст",
                2: "большое изображение",
                3: "эксклюзивный формат",
                4: "продвижение сообществ",
                5: "приложение в новостной ленте",
                6: "мобильное приложение",
                9: "запись в сообществе",
                11: "адаптивный формат",
                12: "истории",
            },
        };
    },

    watch: {
        erids() {
            this.filterArchive();
        },
    },

    computed: {
        ...mapGetters(["s", "select", "profile", "erids"]),

        projects() {
            if (!this.filtredErids || this.filtredErids.length < 1) return null;

            const grouped = this.filtredErids.reduce((result, item) => {
                if (!item.project_id) item.project_id = 1000000; // без проекта

                const outerKey = item.project_id;
                const innerKey = item.client_id;

                if (!result[outerKey]) {
                    result[outerKey] = { id: item.project_id, name: item.project, clients: {}, count: 0 };
                }

                if (!result[outerKey].clients[innerKey]) {
                    result[outerKey].clients[innerKey] = {
                        id: item.client_id,
                        name: item.client,
                        items: [],
                    };
                }

                result[outerKey].clients[innerKey].items.push(item);
                result[outerKey].count += 1;

                this.closeIds.add(innerKey);

                return result;
            }, {});

            return { list: grouped, count: this.filtredErids.length };
        },
    },

    methods: {
        ...mapActions(["getErids", "setPopup", "closePopup", "clearSettings", "checkErids"]),

        filterArchive() {
            this.active = !this.active;
            if (this.active) {
                this.filtredErids = this.erids.filter((item) => item.status != 2);
            } else {
                this.filtredErids = this.erids.filter((item) => item.status == 2);
            }
        },

        closeItem(id) {
            if (this.closeIds.has(id)) {
                this.closeIds.delete(id);
            } else {
                this.closeIds.add(id);
            }
        },

        exportErids() {
            let wscols = [{ wch: 10 }, { wch: 10 }, { wch: 30 }, { wch: 50 }, { wch: 50 }, { wch: 20 }, { wch: 20 }];

            let list = [];
            this.filtredErids.forEach((item) => {
                let object = {
                    id: item.id,
                    label: item.label,
                    project: item.project,
                    client: item.client,
                    name: item.name,
                    url: item.url,
                    targetolog: item.tsecondname + " " + item.tname,
                };

                list.push(object);
            });

            let worksheet = utils.json_to_sheet(list);
            worksheet = utils.sheet_add_aoa(worksheet, [["ID", "КАБИНЕТ", "ПРОЕКТ", "КЛИЕНТ", "НАЗВАНИЕ", "ССЫЛКА", "ТАРГЕТОЛОГ"]], {
                origin: "A1",
            });
            worksheet["!cols"] = wscols;
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "Erid");
            writeFileXLSX(workbook, "FAQ_Erid.xlsx", { compression: true });
        },
    },

    mounted() {
        this.getErids();
        if (this.erids) this.filtredErids = this.erids;
    },
};
</script>
