<template>
    <div class="gap-4 mt-4">
        <div class="bg-white flex justify-center items-center flex-col rounded p-8">
            <div class="flex justify-between items-center gap-4 w-full">
                <span class="title">CPM & CPC с 2019 года</span>
                <div class="flex justify-center gap-4">
                    <div class="flex justify-end items-center gap-4">
                        <div @click="radiobtn = 'impressions'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'impressions' }">
                            ПРОСМОТРЫ
                        </div>
                        <div @click="radiobtn = 'clicks'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'clicks' }">КЛИКИ</div>
                        <div @click="radiobtn = 'spent'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'spent' }">ОТКРУТ</div>
                        <div @click="radiobtn = 'cpm'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'cpm' }">CPM</div>
                        <div @click="radiobtn = 'cpc'" class="btn-xs btn-grey" :class="{ '!bg-sky-500': radiobtn == 'cpc' }">CPC</div>
                    </div>
                </div>
            </div>

            <div v-if="cpmcpc" class="w-full"><LineGraf :graf="cpmcpc" h="h-[calc(100vh-200px)]" /></div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "MainStat",

    data() {
        return {
            moment: moment,
            radiobtn: "cpc",
        };
    },

    computed: {
        ...mapGetters(["s", "stats"]),

        // CPM & CPC для каждого кабинета помесячно с 2022
        cpmcpc() {
            if (!this.stats || this.stats.length < 1) return null;

            let r = [];

            let stats = this.stats.filter((item) => item.type == 3);

            let srt = moment("2019-01-01").format("YYYY-MM");
            let end = moment().format("YYYY-MM");

            while (srt <= end) {
                let d = { time: moment(srt).format("YYYY-MM-DD"), impressions: 0, clicks: 0, regs: 0, spent: 0 };

                stats.forEach((stat) => {
                    if (stat.date.slice(0, 7) == srt) {
                        d.impressions += stat.impressions;
                        d.clicks += stat.clicks;
                        d.regs += stat.regs;
                        d.spent += stat.spent / 100;
                    }
                });

                d.cpm = 0;
                d.cpc = 0;
                if (d.impressions > 0) d.cpm = parseInt(d.spent / (d.impressions / 1000));
                if (d.clicks > 0) d.cpc = parseInt(d.spent / d.clicks) ?? 0;

                // для графика
                if (this.radiobtn == "impressions") d.value = d.impressions;
                if (this.radiobtn == "clicks") d.value = d.clicks;
                if (this.radiobtn == "spent") d.value = d.spent;
                if (this.radiobtn == "cpc") d.value = d.cpc;
                if (this.radiobtn == "cpm") d.value = d.cpm;

                r.push(d);
                srt = moment(srt).add(1, "month").format("YYYY-MM");
            }

            // console.log(r);

            let MA = [];
            let time = "2018-12-01";
            let v1 = 0;
            let v2 = 0;
            let v3 = 0;
            r.forEach((item) => {
                v1 = v2;
                v2 = v3;
                v3 = item.value;
                MA.push({ time: time, value: (v1 + v2 + v3) / 3 });
                time = item.time;
            });

            // console.log("MA", MA);

            return MA;
        },
    },

    methods: {
        ...mapActions(["getAllStats"]),
    },

    mounted() {
        this.getAllStats();
    },
};
</script>
