<template>
    <template v-if="!s.request">
        <div v-if="legend" class="w-96 h-fit fixed z-50 left-1/2 -translate-x-1/2 top-10 p-4 bg-pink-600/80 text-white text-sm text-center rounded">
            <div>ПОДСКАЗКА ОБ ОШИБКЕ</div>
            <div class="grid grid-cols-2 gap-4 text-center mb-4">
                <div>В ПРОЕКТЕ</div>
                <div>В КЛИЕНТЕ</div>
            </div>

            <div v-if="legend[0] == 1" class="grid grid-cols-2 gap-4">
                <div>
                    <div v-if="project.client_type == 'person'">Физическое лицо</div>
                    <div v-if="project.client_type == 'individual'">Индивидуальный предприниматель</div>
                    <div v-if="project.client_type == 'legal'">Юридическое лицо</div>
                </div>
                <div v-if="currentClient.client_type">
                    <div v-if="currentClient.client_type == 'person'">Физическое лицо</div>
                    <div v-if="currentClient.client_type == 'individual'">Индивидуальный предприниматель</div>
                    <div v-if="currentClient.client_type == 'legal'">Юридическое лицо</div>
                </div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[1] == 1" class="grid grid-cols-2 gap-4">
                <div>{{ project.client_short }}</div>
                <div v-if="currentClient.client_name">{{ currentClient.client_name }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[2] == 1" class="grid grid-cols-2 gap-4">
                <div>{{ project.contract_number }}</div>
                <div v-if="currentClient.contract_number">{{ currentClient.contract_number }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[3] == 1" class="grid grid-cols-2 gap-4">
                <div>{{ project.contract_date }}</div>
                <div v-if="currentClient.contract_date">{{ currentClient.contract_date }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[4] == 1" class="grid grid-cols-2 gap-4">
                <div>{{ project.contract_type }}</div>
                <div v-if="currentClient.contract_type">{{ currentClient.contract_type }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[5] == 1" class="grid grid-cols-2 gap-4">
                <div>{{ project.contract_object }}</div>
                <div v-if="currentClient.contract_object">{{ currentClient.contract_object }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[6] == 1" class="grid grid-cols-2 gap-4">
                <div>{{ project.inn }}</div>
                <div v-if="currentClient.inn">{{ currentClient.inn }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[7] == 1" class="grid grid-cols-2 gap-4">
                <div>{{ project.phone }}</div>
                <div v-if="currentClient.phone">{{ currentClient.phone }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[8] == 1" class="grid grid-cols-2 gap-4">
                <div>
                    <div v-if="project.subagent_type == 'person'">Физическое лицо</div>
                    <div v-if="project.subagent_type == 'individual'">Индивидуальный предприниматель</div>
                    <div v-if="project.subagent_type == 'legal'">Юридическое лицо</div>
                </div>
                <div v-if="currentClient.subagent_type">
                    <div v-if="currentClient.subagent_type == 'person'">Физическое лицо</div>
                    <div v-if="currentClient.subagent_type == 'individual'">Индивидуальный предприниматель</div>
                    <div v-if="currentClient.subagent_type == 'legal'">Юридическое лицо</div>
                </div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[9] == 1" class="grid grid-cols-2 gap-4">
                <div>{{ project.subagent_name }}</div>
                <div v-if="currentClient.subagent_name">{{ currentClient.subagent_name }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[10] == 1" class="grid grid-cols-2 gap-4">
                <div>{{ project.subagent_inn }}</div>
                <div v-if="currentClient.subagent_inn">{{ currentClient.subagent_inn }}</div>
                <div v-else>Нет</div>
            </div>

            <div v-if="legend[11] == 1" class="grid grid-cols-2 gap-4">
                <div>{{ project.subagent_phone }}</div>
                <div v-if="currentClient.subagent_phone">{{ currentClient.subagent_phone }}</div>
                <div v-else>Нет</div>
            </div>
        </div>

        <div class="flex flex-col items-center text-slate-500 uppercase mb-4">
            <span class="text-sm">КЛИЕНТЫ ПРОЕКТА</span>
            <div class="flex justify-center items-center">
                <span class="text-2xl font-bold">"{{ project.name }}"</span>
            </div>
        </div>

        <div class="bg-white rounded relative p-4 grid grid-cols-3 text-sm gap-8 mb-4">
            <div
                v-if="['Or', 'Bs', 'Lr'].includes(profile.role)"
                class="p-2 text-slate-400 hover:text-teal-500 cursor-pointer absolute top-2 right-2"
                @click="editProject(project)"
            >
                <IconEdit :size="5" />
            </div>

            <div>
                <div class="flex flex-col items-start text-left mb-2">
                    <label class="w-full text-xs text-slate-400">Организационно-правовая форма</label>
                    <div v-if="project.client_type == 'person'">Физическое лицо</div>
                    <div v-if="project.client_type == 'individual'">Индивидуальный предприниматель</div>
                    <div v-if="project.client_type == 'legal'">Юридическое лицо</div>
                </div>

                <div class="flex flex-col items-start text-left mb-2">
                    <label class="w-full text-xs text-slate-400">Сокращенное наименование</label>
                    {{ project.client_short }}
                </div>

                <div class="flex flex-col items-start text-left mb-2">
                    <label class="w-full text-xs text-slate-400">ИНН</label>
                    {{ project.inn }}
                </div>

                <div class="flex flex-col items-start text-left mb-2">
                    <label class="w-full text-xs text-slate-400">Телефон</label>
                    {{ project.phone }}
                </div>
            </div>

            <div>
                <div class="flex flex-col items-start text-left mb-2">
                    <label class="w-full text-xs text-slate-400">Номер контракта</label>
                    {{ project.contract_number }}
                </div>

                <div class="flex flex-col items-start text-left mb-2">
                    <label class="w-full text-xs text-slate-400">Дата контракта</label>
                    {{ moment(project.contract_date).format("DD.MM.YYYY") }}
                </div>

                <div class="flex flex-col items-start text-left mb-2">
                    <label class="w-full text-xs text-slate-400">Тип контракта</label>
                    {{ project.contract_type }}
                </div>

                <div class="flex flex-col items-start text-left mb-2">
                    <label class="w-full text-xs text-slate-400">Предмет договора</label>
                    {{ project.contract_object }}
                </div>
            </div>

            <div>
                <div class="text-sm text-slate-400">Субагент</div>

                <div v-if="project.subagent_type">
                    <div class="flex flex-col items-start text-left mb-2">
                        <label class="w-full text-xs text-slate-400">Тип субагента</label>
                        <div v-if="project.subagent_type == 'person'">Физическое лицо</div>
                        <div v-if="project.subagent_type == 'individual'">Индивидуальный предприниматель</div>
                        <div v-if="project.subagent_type == 'legal'">Юридическое лицо</div>
                    </div>

                    <div class="flex flex-col items-start text-left mb-2">
                        <label class="w-full text-xs text-slate-400">Название субагента</label>
                        {{ project.subagent_name }}
                    </div>

                    <div class="flex flex-col items-start text-left mb-2">
                        <label class="w-full text-xs text-slate-400">Инн субагента</label>
                        {{ project.subagent_inn }}
                    </div>

                    <div class="flex flex-col items-start text-left mb-2">
                        <label class="w-full text-xs text-slate-400">Телефон субагента</label>
                        {{ project.subagent_phone }}
                    </div>
                </div>
                <div v-else class="text-sm text-slate-400">НЕТ</div>
            </div>
        </div>

        <div class="bg-white rounded">
            <div class="grid grid-cols-14 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                <div class="col-span-4 text-center border-r border-slate-200">Данные VK</div>
                <div class="col-span-3 text-center border-r border-slate-200">Юр инфа</div>
                <div class="col-span-3 text-center border-r border-slate-200">Контракт</div>
                <div class="col-span-3 text-center border-r border-slate-200">Субагент</div>
                <div></div>
            </div>

            <div class="grid grid-cols-14 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                <div class="col-span-4 text-center border-r border-slate-200">Название и Лейбл</div>

                <div class="grid grid-cols-4 col-span-3 text-center border-r border-slate-200">
                    <div>Тип</div>
                    <div>НАЗВАНИЕ</div>
                    <div>ИНН</div>
                    <div>Телефон</div>
                </div>

                <div class="grid grid-cols-4 col-span-3 text-center border-r border-slate-200">
                    <div>Номер</div>
                    <div>Дата</div>
                    <div>Тип</div>
                    <div>Предмет</div>
                </div>

                <div class="grid grid-cols-5 col-span-3 text-center border-r border-slate-200">
                    <div>Субагент</div>
                    <div>Тип</div>
                    <div>НАЗВАНИЕ</div>
                    <div>ИНН</div>
                    <div>Телефон</div>
                </div>

                <div></div>
            </div>

            <template v-for="item in clients" :key="item.id">
                <!-- <template v-if="item.status == 1"> -->
                <div class="grid grid-cols-14 items-center gap-4 p-2 border-b border-slate-200 text-xs hover:bg-cyan-50/50 hover:!text-cyan-700">
                    <div class="flex items-center gap-4 col-span-4">
                        <div v-if="item.wrong == 0" class="flex justify-center text-teal-600"><IconOk :size="6" /></div>
                        <div
                            @mouseover="(legend = item.wrong), (currentClient = item)"
                            @mouseleave="(legend = ''), (currentClient = {})"
                            v-if="item.wrong > 0"
                            class="text-pink-600"
                        >
                            <IconWarning :size="6" />
                        </div>

                        <a
                            v-if="item.channel_id == 1"
                            class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer"
                            target="_blank"
                            :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                        >
                            <IVk />
                        </a>
                        <a
                            v-if="item.channel_id == 2"
                            class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer"
                            target="_blank"
                            href="https://ads.vk.com/hq/dashboard"
                        >
                            <IVkAds :size="6" />
                        </a>

                        <div>
                            <span class="text-xs text-slate-400">{{ item.id }}</span>
                            <div class="text-sm">{{ item.name }}</div>
                        </div>

                        <div class="text-center border-r border-slate-200">{{ item.label }}</div>
                    </div>

                    <div class="grid grid-cols-4 col-span-3">
                        <div v-if="item.client_type == project.client_type" class="flex justify-center text-teal-600"><IconOk :size="5" /></div>
                        <div v-else class="!text-pink-700">{{ item.client_type }}</div>

                        <div v-if="item.client_name == project.client_short" class="flex justify-center text-teal-600"><IconOk :size="5" /></div>
                        <div v-else class="!text-pink-700">{{ item.client_name }}</div>

                        <div v-if="item.inn == project.inn" class="flex justify-center text-teal-600"><IconOk :size="5" /></div>
                        <div v-else class="!text-pink-700">{{ item.inn }}</div>

                        <div v-if="item.phone == project.phone" class="flex justify-center text-teal-600"><IconOk :size="5" /></div>
                        <div v-else class="!text-pink-700">{{ item.phone }}</div>
                    </div>

                    <div class="grid grid-cols-4 col-span-3">
                        <div v-if="item.contract_number == project.contract_number" class="flex justify-center text-teal-600">
                            <IconOk :size="5" />
                        </div>
                        <div v-else class="!text-pink-700">{{ item.contract_number }}</div>

                        <div v-if="item.contract_date == project.contract_date" class="flex justify-center text-teal-600">
                            <IconOk :size="5" />
                        </div>
                        <div v-else class="!text-pink-700">{{ moment(item.contract_date).format("DD.MM.YYYY") }}</div>

                        <div v-if="item.contract_type == project.contract_type" class="flex justify-center text-teal-600">
                            <IconOk :size="5" />
                        </div>
                        <div v-else class="!text-pink-700">{{ item.contract_type }}</div>

                        <div
                            v-if="item.contract_object == project.contract_object"
                            class="flex justify-center text-teal-600 border-r border-slate-200"
                        >
                            <IconOk :size="5" />
                        </div>
                        <div v-else class="!text-pink-700 border-r border-slate-200">{{ item.contract_object }}</div>
                    </div>

                    <div class="grid grid-cols-5 items-center col-span-3">
                        <div
                            v-if="project.subagent_type && project.subagent_name"
                            class="relative flex justify-end items-center group p-4 text-xs text-slate-400 cursor-pointer"
                            @click="setSubagent(item)"
                        >
                            <input
                                type="checkbox"
                                :checked="item.subagent"
                                class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
                            />
                            <span
                                class="w-8 h-5 flex items-center flex-shrink-0 ml-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-teal-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
                            ></span>
                        </div>

                        <div v-if="item.subagent_type == project.subagent_type" class="flex justify-center text-teal-600">
                            <IconOk :size="5" />
                        </div>
                        <div v-else class="!text-pink-700">{{ item.subagent_type }}</div>

                        <div v-if="item.subagent_name == project.subagent_name" class="flex justify-center text-teal-600">
                            <IconOk :size="5" />
                        </div>
                        <div v-else class="!text-pink-700">{{ item.subagent_name }}</div>

                        <div v-if="item.subagent_inn == project.subagent_inn" class="flex justify-center text-teal-600"><IconOk :size="5" /></div>
                        <div v-else class="!text-pink-700">{{ item.subagent_inn }}</div>

                        <div v-if="item.subagent_phone == project.subagent_phone" class="flex justify-center text-teal-600">
                            <IconOk :size="5" />
                        </div>
                        <div v-else class="!text-pink-700">{{ item.subagent_phone }}</div>
                    </div>

                    <div class="flex justify-center gap-2 relative group">
                        <span class="tip">{{ tip }}</span>

                        <div
                            class="p-2 text-slate-400 hover:text-sky-500 cursor-pointer"
                            @mouseover="tip = 'обновить'"
                            @mouseleave="tip = ''"
                            @click="sendClientInfo(item)"
                        >
                            <IconRe :size="5" />
                        </div>
                    </div>
                </div>
                <!-- </template> -->
            </template>
        </div>

        <template v-if="isHidden && isHidden > 0">
            <div class="text-slate-400 text-xl mt-8 mb-4">СКРЫТЫ</div>

            <div class="bg-white rounded">
                <div class="grid grid-cols-14 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                    <div class="col-span-4 text-center border-r border-slate-200">Данные VK</div>
                    <div class="col-span-3 text-center border-r border-slate-200">Юр инфа</div>
                    <div class="col-span-3 text-center border-r border-slate-200">Контракт</div>
                    <div class="col-span-3 text-center border-r border-slate-200">Субагент</div>
                    <div></div>
                </div>

                <div class="grid grid-cols-14 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                    <div class="col-span-4 text-center border-r border-slate-200">Название и Лейбл</div>

                    <div class="grid grid-cols-4 col-span-3 text-center border-r border-slate-200">
                        <div>Тип</div>
                        <div>НАЗВАНИЕ</div>
                        <div>ИНН</div>
                        <div>Телефон</div>
                    </div>

                    <div class="grid grid-cols-4 col-span-3 text-center border-r border-slate-200">
                        <div>Номер</div>
                        <div>Дата</div>
                        <div>Тип</div>
                        <div>Предмет</div>
                    </div>

                    <div class="grid grid-cols-5 col-span-3 text-center border-r border-slate-200">
                        <div>Субагент</div>
                        <div>Тип</div>
                        <div>НАЗВАНИЕ</div>
                        <div>ИНН</div>
                        <div>Телефон</div>
                    </div>

                    <div></div>
                </div>

                <template v-for="item in clients" :key="item.id">
                    <template v-if="item.status == 8">
                        <div
                            class="grid grid-cols-14 items-center gap-4 p-2 border-b border-slate-200 text-xs hover:bg-cyan-50/50 hover:!text-cyan-700"
                        >
                            <div class="flex items-center gap-4 col-span-4">
                                <div v-if="item.wrong == 0" class="flex justify-center text-teal-600"><IconOk :size="6" /></div>
                                <div
                                    @mouseover="(legend = item.wrong), (currentClient = item)"
                                    @mouseleave="(legend = ''), (currentClient = {})"
                                    v-if="item.wrong > 0"
                                    class="text-pink-600"
                                >
                                    <IconWarning :size="6" />
                                </div>

                                <a
                                    class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer"
                                    target="_blank"
                                    :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                                >
                                    <IVk />
                                </a>
                                <div>
                                    <span class="text-xs text-slate-400">{{ item.id }}</span>
                                    <div class="text-sm">{{ item.name }}</div>
                                </div>

                                <div class="text-center border-r border-slate-200">{{ item.label }}</div>
                            </div>

                            <div class="grid grid-cols-4 col-span-3">
                                <div v-if="item.client_type == project.client_type" class="flex justify-center text-teal-600">
                                    <IconOk :size="5" />
                                </div>
                                <div v-else class="!text-pink-700">{{ item.client_type }}</div>

                                <div v-if="item.client_name == project.client_short" class="flex justify-center text-teal-600">
                                    <IconOk :size="5" />
                                </div>
                                <div v-else class="!text-pink-700">{{ item.client_name }}</div>

                                <div v-if="item.inn == project.inn" class="flex justify-center text-teal-600"><IconOk :size="5" /></div>
                                <div v-else class="!text-pink-700">{{ item.inn }}</div>

                                <div v-if="item.phone == project.phone" class="flex justify-center text-teal-600"><IconOk :size="5" /></div>
                                <div v-else class="!text-pink-700">{{ item.phone }}</div>
                            </div>

                            <div class="grid grid-cols-4 col-span-3">
                                <div v-if="item.contract_number == project.contract_number" class="flex justify-center text-teal-600">
                                    <IconOk :size="5" />
                                </div>
                                <div v-else class="!text-pink-700">{{ item.contract_number }}</div>

                                <div v-if="item.contract_date == project.contract_date" class="flex justify-center text-teal-600">
                                    <IconOk :size="5" />
                                </div>
                                <div v-else class="!text-pink-700">{{ moment(item.contract_date).format("DD.MM.YYYY") }}</div>

                                <div v-if="item.contract_type == project.contract_type" class="flex justify-center text-teal-600">
                                    <IconOk :size="5" />
                                </div>
                                <div v-else class="!text-pink-700">{{ item.contract_type }}</div>

                                <div
                                    v-if="item.contract_object == project.contract_object"
                                    class="flex justify-center text-teal-600 border-r border-slate-200"
                                >
                                    <IconOk :size="5" />
                                </div>
                                <div v-else class="!text-pink-700 border-r border-slate-200">{{ item.contract_object }}</div>
                            </div>

                            <div class="grid grid-cols-5 items-center col-span-3">
                                <div
                                    v-if="project.subagent_type && project.subagent_name"
                                    class="relative flex justify-end items-center group p-4 text-xs text-slate-400 cursor-pointer"
                                    @click="setSubagent(item)"
                                >
                                    <input
                                        type="checkbox"
                                        :checked="item.subagent"
                                        class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
                                    />
                                    <span
                                        class="w-8 h-5 flex items-center flex-shrink-0 ml-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-teal-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
                                    ></span>
                                </div>

                                <div v-if="item.subagent_type == project.subagent_type" class="flex justify-center text-teal-600">
                                    <IconOk :size="5" />
                                </div>
                                <div v-else class="!text-pink-700">{{ item.subagent_type }}</div>

                                <div v-if="item.subagent_name == project.subagent_name" class="flex justify-center text-teal-600">
                                    <IconOk :size="5" />
                                </div>
                                <div v-else class="!text-pink-700">{{ item.subagent_name }}</div>

                                <div v-if="item.subagent_inn == project.subagent_inn" class="flex justify-center text-teal-600">
                                    <IconOk :size="5" />
                                </div>
                                <div v-else class="!text-pink-700">{{ item.subagent_inn }}</div>

                                <div v-if="item.subagent_phone == project.subagent_phone" class="flex justify-center text-teal-600">
                                    <IconOk :size="5" />
                                </div>
                                <div v-else class="!text-pink-700">{{ item.subagent_phone }}</div>
                            </div>

                            <div class="flex justify-center gap-2 relative group">
                                <span class="tip">{{ tip }}</span>

                                <div
                                    class="p-2 text-slate-400 hover:text-sky-500 cursor-pointer"
                                    @mouseover="tip = 'обновить'"
                                    @mouseleave="tip = ''"
                                    @click="sendClientInfo(item)"
                                >
                                    <IconRe :size="5" />
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </template>
    </template>
    <div v-else class="flex justify-center items-center h-screen text-cyan-400"><IconLoader :size="30" /></div>

    <template v-if="s.popup == 'editProject' && project?.id"><EditProject /></template>
    <template v-if="s.popup == 'viewProject' && project?.id"><ViewProject /></template>
    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "ProjectPage",

    data() {
        return {
            moment: moment,
            tip: "",
            legend: "",
            currentClient: {},
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "project", "clients"]),

        isHidden() {
            let count = 0;

            if (!this.clients) {
                return 0;
            }

            this.clients.forEach((client) => {
                if (client.status == 8) count++;
            });

            return count;
        },
    },

    methods: {
        ...mapActions(["getProjects", "isProject", "closePopup", "editProject", "viewProject", "sendClientInfo", "unProjectClient", "setSubagent"]),
    },

    mounted() {
        this.getProjects().then(() => {
            this.isProject();
        });
    },
};
</script>
