<template>
    <template v-if="!s.request">
        <div v-if="legend" class="w-96 h-fit fixed z-50 left-1/2 -translate-x-1/2 top-10 p-4 bg-pink-600/80 text-white text-sm text-center rounded">
            <div>ПОДСКАЗКА, ЧТО ИСПРАВИТЬ</div>
            <div v-if="legend[0] == 1">Организационно-правовую форму</div>
            <div v-if="legend[1] == 1">Сокращенное наименование</div>
            <div v-if="legend[2] == 1">Номер контракта</div>
            <div v-if="legend[3] == 1">Дату контракта</div>
            <div v-if="legend[4] == 1">Тип контракта</div>
            <div v-if="legend[5] == 1">Предмет контракта</div>
            <div v-if="legend[6] == 1">ИНН</div>
            <div v-if="legend[7] == 1">Телефон</div>
            <div v-if="legend[8] == 1">Название проекта</div>
            <div v-if="legend[9] == 1">НДС</div>
            <div v-if="legend[10] == 1">Название субагента</div>
        </div>

        <div class="flex justify-center items-center gap-20">
            <div class="w-48 h-24 flex flex-col justify-center items-center bg-teal-400 rounded-lg">
                <span class="text-white text-xs">ПРОЕКТЫ</span>
                <span class="text-white text-2xl">{{ total.projects.correct }}</span>
                <span class="text-white text-xs">заполнены</span>
            </div>
            <div class="w-48 h-24 flex flex-col justify-center items-center bg-pink-400 rounded-lg">
                <span class="text-white text-xs">ПРОЕКТЫ</span>
                <span class="text-white text-2xl">{{ total.projects.wrong }}</span>
                <span class="text-white text-xs">с ошибками</span>
            </div>

            <div class="w-48 h-24 flex flex-col justify-center items-center bg-teal-400 rounded-lg">
                <span class="text-white text-xs">КЛИЕНТЫ</span>
                <span class="text-white text-2xl">{{ total.clients.correct }}</span>
                <span class="text-white text-xs">заполнены</span>
            </div>
            <div class="w-48 h-24 flex flex-col justify-center items-center bg-pink-400 rounded-lg">
                <span class="text-white text-xs">КЛИЕНТЫ</span>
                <span class="text-white text-2xl">{{ total.clients.wrong }}</span>
                <span class="text-white text-xs">с ошибками</span>
            </div>
        </div>

        <div class="flex justify-between items-center my-4 gap-4">
            <div class="shrink-0 text-sm text-slate-500 uppercase">
                ПРОЕКТЫ <span class="count">{{ projects?.length }}</span>
            </div>

            <input type="text" class="input" placeholder="Поиск проекта" v-model="s.findme" @input="filterProjects()" />

            <!-- <select v-model="s.status" class="bg-white h-9 px-3 py-2 rounded text-sm text-sky-800" @change="filterProjects()">
                <option :selected="s.status == null" :value="null">ВСЕ</option>
                <option :selected="s.status == 7" :value="7">АКТИВНЫ</option>
                <option :selected="s.status == 0" :value="0">ВЫКЛЮЧЕНЫ</option>
            </select> -->

            <div v-if="s.clear" class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings()">ОЧИСТИТЬ</div>

            <div v-if="['Or', 'Bs', 'Lr'].includes(profile?.role)" @click="createProject()" class="btn btn-green gap-2">
                <span class="text-white my-2"><IconPlus :size="5" /></span>
                <span class="text-white text-xs">СОЗДАТЬ</span>
            </div>

            <div
                v-if="['Or', 'Bs', 'Bo'].includes(profile?.role)"
                @click="checkAll()"
                class="h-8 px-3 flex justify-center items-center gap-2 bg-orange-400 rounded cursor-pointer"
            >
                <span class="text-white my-2"><IconRe :size="4" /></span>
                <span class="text-white text-xs">ОБНОВИТЬ</span>
            </div>
        </div>

        <div class="bg-white rounded">
            <div class="grid grid-cols-12 items-center text-xs text-slate-400 gap-4 p-4 border-b border-slate-200 uppercase">
                <div class="flex justify-between gap-2"><span>ПРОЕКТ</span> <span>КЛИЕНТЫ</span></div>
                <div class="col-span-2">НАЗВАНИЕ</div>
                <div>FAQid</div>
                <div class="col-span-2">Сокращенное наименование</div>
                <div class="col-span-2">Телефон</div>
                <div>Номер</div>
                <div>Дата</div>
                <div class="text-center">Клиенты</div>

                <div class="text-center"></div>
            </div>

            <template v-for="item in projects" :key="item.id">
                <div
                    class="grid grid-cols-12 items-center gap-4 p-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50"
                    :class="{ '!text-teal-700': item.status == 7, '!text-pink-700 !bg-pink-50/50': item.status == 1 }"
                >
                    <div class="flex gap-8">
                        <div v-if="item.status == 7" class="text-teal-600"><IconOk :size="6" /></div>
                        <div
                            v-if="item.status == 1"
                            @mouseover="(legend = item.wrong), (currentProject = item)"
                            @mouseleave="(legend = ''), (currentProject = {})"
                            class="text-pink-600"
                        >
                            <IconWarning :size="6" />
                        </div>
                        <div v-if="item.client_wrong > 0" class="text-pink-600"><IconWarning :size="6" /></div>
                        <div v-if="item.client_wrong == 0" class="text-teal-600"><IconOk :size="6" /></div>
                    </div>

                    <div
                        v-if="['Or', 'Bs', 'Bo'].includes(profile?.role)"
                        @click="pickProject(item.id), $router.push('/project')"
                        class="col-span-2 cursor-pointer"
                    >
                        <sup>{{ item.id }}</sup> {{ item.name }}
                    </div>

                    <div v-else class="col-span-2">
                        <sup>{{ item.id }}</sup> {{ item.name }}
                    </div>

                    <div>{{ item.faqid }}</div>

                    <div class="col-span-2">{{ item.client_short }}</div>
                    <div class="col-span-2">{{ item.phone }}</div>

                    <div>{{ item.contract_number }}</div>

                    <div>
                        <div v-if="item.contract_date">{{ moment(item.contract_date).format("DD.MM.YYYY") }}</div>
                        <div v-else>-</div>
                    </div>

                    <div class="text-center">{{ item.count }}</div>

                    <div class="flex justify-center gap-2 relative group">
                        <span class="tip">{{ tip }}</span>
                        <div
                            v-if="['Or', 'Bs', 'Lr'].includes(profile?.role)"
                            class="p-2 text-slate-400 hover:text-teal-500 cursor-pointer"
                            @mouseover="tip = 'редактировать'"
                            @mouseleave="tip = ''"
                            @click="editProject(item)"
                        >
                            <IconEdit :size="5" />
                        </div>
                        <div
                            class="p-2 text-slate-400 hover:text-teal-500 cursor-pointer"
                            @mouseover="tip = 'смотреть'"
                            @mouseleave="tip = ''"
                            @click="viewProject(item)"
                        >
                            <IconEye :size="5" />
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <!-- <template v-if="s.popup == 'addProject'"><AddProject /></template> -->
        <template v-if="s.popup == 'editProject' && project?.id"><EditProject /></template>
        <template v-if="s.popup == 'viewProject' && project?.id"><ViewProject /></template>
        <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-cyan-400"><IconLoader :size="30" /></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "ProjectsPage",

    data() {
        return {
            moment: moment,
            tip: "",
            legend: "",
            currentProject: {},
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "projects", "project"]),

        total() {
            let data = { projects: { correct: 0, wrong: 0 }, clients: { correct: 0, wrong: 0 } };

            this.projects.forEach((project) => {
                if (project.status == 7) {
                    data.projects.correct++;
                } else {
                    data.projects.wrong++;
                }

                data.clients.correct += project.client_correct;
                data.clients.wrong += project.client_wrong;
            });

            return data;
        },
    },

    methods: {
        ...mapActions([
            "getProjects",
            "closePopup",
            "setSettings",
            "clearSettings",
            "filterProjects",
            "editProject",
            "viewProject",
            "pickProject",
            "createProject",
            "verifiedProject",
            "checkAll",
        ]),
    },

    mounted() {
        // console.log("ProjectsPage", Math.floor(Date.now() / 1000) - 1694800000);
        this.getProjects();
        this.s.status = null;
        this.setSettings(this.s);
    },
};
</script>
